import {useReactiveVar} from "@apollo/client";
import {DrawerState, OrganizationPermissionType, OrganizationPlan} from "@generated/data";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import {isReverseTrialActive, reverseTrialDaysLeft} from "@sessions/common/subscription-limitations/utils";
import BrandingLogo from "@ui/cdk/BrandingLogo";
import Link from "@ui/cdk/Link";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {useOrganizationAccess} from "@workhorse/api/access/hooks";
import {memo, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {useHistory, useLocation} from "@workhorse/api/routing";
import {HostType} from "@workhorse/declarations/dataTypes";
import {brandingLogo, brandingNavbarTheme} from "@workhorse/pages/user/profile/Theming/utils";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {getPlanTitle} from "@workhorse/pages/user/profile/UsageEvaluation/components/SubscriptionInfo";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useDrawerLeftToggler} from "@workhorse/providers/state";
import {useUserInfo} from "@workhorse/providers/User";
import {useTranslation} from "react-i18next";
import {ReactComponent as AgendasIcon} from "../../assets/media/navbar-left-icons/agendas.svg";
import {ReactComponent as BookingsIcon} from "../../assets/media/navbar-left-icons/bookings.svg";
import {ReactComponent as CalendarsIcon} from "../../assets/media/navbar-left-icons/calendars.svg";
import {ReactComponent as EventsIcon} from "../../assets/media/navbar-left-icons/events.svg";
import {ReactComponent as FilesIcon} from "../../assets/media/navbar-left-icons/files.svg";
import {ReactComponent as OverviewIcon} from "../../assets/media/navbar-left-icons/overview.svg";
import {ReactComponent as RocketIcon} from "../../assets/media/rocket.svg";

import {ReactComponent as PassedSessionsIcon} from "../../assets/media/navbar-left-icons/passed-sessions.svg";
import {ReactComponent as RoomsIcon} from "../../assets/media/navbar-left-icons/rooms.svg";
import {ReactComponent as ToolsIcon} from "../../assets/media/navbar-left-icons/tools.svg";
import {enableEventLeaveModal} from "../header/headerUtils";
import SearchBar from "../searchbar/SearchBar";
import {isAppSumoPlan} from "../subscriptions/utils";
import NavigationCreateMenu from "./components/NavigationCreateMenu";
import NavigationItem from "./components/NavigationItem";
import NavigationSelectWorkspaceMenu from "./components/NavigationSelectWorkspaceMenu";
import NavigationUserDetails from "./components/NavigationUserDetails";
import classes from "./styles/Navigation.module.scss";

import Tooltip from "@ui/cdk/Tooltip";
import toast from "@workhorse/api/toast";
import {useSeenSection} from "@workhorse/api/seen";
import {isBefore} from "date-fns";
import {GET_STARTED_WIDGET_RELEASE_DATE} from "@workhorse/pages/home/components/utils";

type NavigationItemProps<T> = {
    hostType: T;
    icon: JSX.Element;
    name: string;
    href: string;
    notification?: boolean;
    type: "default" | "resource" | "module" | "extras" | "footer";
};

type AcceptedHostType =
    | HostType.Home
    | HostType.Base
    | HostType.Templates
    | HostType.Resources
    | HostType.Files
    | HostType.Booking
    | HostType.NewBooking
    | HostType.Contacts
    | HostType.Memory
    | HostType.User
    | HostType.Events
    | HostType.Event
    | HostType.User
    | HostType.Rooms;

export type NavigationItems<T = Array<AcceptedHostType>> = Array<NavigationItemProps<T>>;

const Navigation = () => {
    const {t} = useTranslation();
    const user = useUserInfo();

    const sessionId = useSessionIdFromRoute();

    const activeOrganization = user.activeOrganizationPermission.organization;
    const activePlan = activeOrganization.plan;
    const isProUser = activePlan === OrganizationPlan.ProLtd;
    const isAppSumoUser = isAppSumoPlan(activePlan);
    const isSaasZillaUser = activePlan === OrganizationPlan.ProLtdSaaszilla;
    const organizationAccess = useOrganizationAccess();
    const isOrganizationOwner = organizationAccess.hasRole(OrganizationPermissionType.Owner);

    const beautifiedPlanName = isProUser
        ? "Pro LTD"
        : isAppSumoUser && activePlan
        ? `App Sumo Tier ${activePlan.replace("APPSUMO_TIER_", "")}`
        : isSaasZillaUser
        ? "SaasZilla Pro LTD"
        : activePlan !== OrganizationPlan.Free
        ? getPlanTitle(activePlan)
        : "Free";

    const NAVIGATION_TABS: NavigationItems = [
        {
            hostType: [HostType.Home],
            icon: <OverviewIcon />,
            href: "/",
            name: "Home",
            type: "default",
        },
        {
            hostType: [HostType.Memory],
            icon: <PassedSessionsIcon />,
            href: "/memory",
            name: "Memory",
            type: "default",
        },
        {
            hostType: [HostType.Base],
            icon: <CalendarsIcon />,
            href: "/base",
            name: "Calendar",
            type: "default",
        },
        {
            hostType: [HostType.Templates],
            icon: <AgendasIcon />,
            href: "/templates/my-agendas",
            name: "Agendas",
            type: "resource",
        },
        {
            hostType: [HostType.Files],
            icon: <FilesIcon />,
            href: "/files/doc",
            name: "Files",
            type: "resource",
        },
        {
            hostType: [HostType.Resources],
            icon: <ToolsIcon />,
            href: "/resources/blackboard",
            name: "Tools",
            type: "resource",
        },
        {
            hostType: [HostType.Booking, HostType.NewBooking],
            icon: <BookingsIcon />,
            href: "/booking",
            name: "Book me",
            type: "module",
        },
        {
            hostType: [HostType.Events, HostType.Event],
            icon: <EventsIcon />,
            href: "/events",
            name: "Events",
            type: "module",
        },
        {
            hostType: [HostType.Rooms],
            icon: <RoomsIcon />,
            href: "/rooms",
            name: "Rooms",
            type: "module",
        },
        // {
        //     hostType: [HostType.User],
        //     icon: <UsageEvaluationIcon />,
        //     href: "/user/organization/subscriptions/plans",
        //     name: beautifiedPlanName,
        //     type: "extras",
        // },
    ];

    const hostType = useHostType();
    const history = useHistory();
    const location = useLocation();

    const {isTablet, isMobileOrTablet} = useMobile();
    const [drawerState, toggleDrawer] = useDrawerLeftToggler();

    const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);

    const isEvent = hostType === HostType.Event;
    const isRoom = hostType === HostType.Rooms && location.pathname !== "/rooms";
    const navbarTheme = useReactiveVar(brandingNavbarTheme);
    const isBooking = hostType === HostType.NewBooking;
    const isFeedbackForm = location.pathname.includes("feedback-forms") && location.pathname !== "/user/feedback-forms";

    const ref = useRef<HTMLDivElement>(null);

    const userSeenGetStartedWidget = useSeenSection("GLOBALS.getStartedWidget");
    const isOlderThanGetStartedWidget = isBefore(new Date(user.createdAt), new Date(GET_STARTED_WIDGET_RELEASE_DATE));

    const showGetStartedOnboarding = !isOlderThanGetStartedWidget && !isMobileOrTablet && isOrganizationOwner && !userSeenGetStartedWidget;

    const handleOnProfileToggle = () => setProfileMenuOpen((o) => !o);

    const handleOnClick = () => {
        toggleDrawer(drawerState.isFullyOpen ? DrawerState.SemiOpen : DrawerState.FullyOpen, "Navigation:131");
    };

    const preventNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isEvent && sessionId) {
            enableEventLeaveModal(true);
            e.preventDefault();
        } else {
            history.push("/");
        }
    };

    const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [workspaceMenuAnchorEl, setWorkspaceMenuAnchorEl] = useState<HTMLElement | null>(null);

    const shouldKeepNavOpen = drawerState.isSemiOpen && createMenuAnchorEl && !isMobileOrTablet;

    const customBrandingLogo = useReactiveVar(brandingLogo);

    const isReverseTrial = isOrganizationOwner && isReverseTrialActive(activeOrganization?.reverseTrialCancellationDate);

    const [reverseTrialLeft, setReverseTrialLeft] = useState(reverseTrialDaysLeft(activeOrganization?.reverseTrialCancellationDate));

    useEffect(() => {
        const computeReverseTrial = () => {
            const newLeft = reverseTrialDaysLeft(activeOrganization?.reverseTrialCancellationDate);
            if (newLeft !== reverseTrialLeft) {
                setReverseTrialLeft(newLeft);
            }
        };

        if (isReverseTrial) {
            computeReverseTrial();
            const reverseTrialInterval = setInterval(() => {
                computeReverseTrial();
            }, 60000);

            return () => {
                clearInterval(reverseTrialInterval);
            };
        }
    }, [reverseTrialLeft, activeOrganization?.reverseTrialCancellationDate, isReverseTrial]);

    return (
        <div
            className={cls(
                "flex flex-col",
                classes.container,
                isTablet && classes.containerTablet,
                drawerState.isSemiOpen && !shouldKeepNavOpen && classes.containerMinimized,
                drawerState.isSemiOpen && !isMobileOrTablet && !shouldKeepNavOpen && classes.containerMinimizedExpandable,
                shouldKeepNavOpen && "no-margin-left",
                navbarTheme === "light" && classes.containerLight
            )}
        >
            <div
                className={cls(classes.floatingToggleButton, navbarTheme === "light" && classes.lightTheme)}
                onClick={handleOnClick}
                key={`toggle-${drawerState.state}`}
                role="presentation"
            >
                <ArrowBackIosRoundedIcon />
            </div>

            <div className={cls(classes.header, drawerState.isSemiOpen && classes.minimized)}>
                <Link
                    className={cls(classes.logo, customBrandingLogo && classes.logoCustom)}
                    to="/"
                    onClick={preventNavigation}
                    role="heading"
                    aria-label={t("header.aria.go_home") ?? "Go Home"}
                >
                    <BrandingLogo
                        variant={navbarTheme === "light" ? "light" : "dark"}
                        size={customBrandingLogo ? undefined : drawerState.isSemiOpen ? "small" : "big"}
                        className={customBrandingLogo || !drawerState.isSemiOpen ? undefined : classes.sessionsLogoSmall}
                    />
                </Link>

                <SearchBar
                    classes={{
                        toggler: classes.searchIconContainer,
                    }}
                />

                {/* <div
                        className={classes.drawerIconContainer}
                        onClick={handleOnClick}
                        key={`toggle-${drawerState.state}`}
                        role="presentation"
                    >
                        <FirstPageRoundedIcon />
                    </div> */}
            </div>

            {!showGetStartedOnboarding && (
                <NavigationCreateMenu
                    menuAnchorEl={createMenuAnchorEl}
                    setMenuAnchorEl={setCreateMenuAnchorEl}
                    className={classes.createMenu}
                />
            )}

            <div
                className={cls(
                    "flex flex-col mozilla-scrollbar",
                    classes.navigation,
                    drawerState.isSemiOpen && !createMenuAnchorEl && classes.minimized
                )}
                role="navigation"
            >
                <div className={cls("flex flex-col", classes.navigationGroup)}>
                    {NAVIGATION_TABS.filter((tab) => tab.type === "default").map((tab) => (
                        <NavigationItem
                            className={classes.navigationGroupItem}
                            key={tab.href}
                            url={tab.href}
                            icon={tab.icon}
                            isEvent={isEvent}
                            isRoom={isRoom}
                            text={tab.name}
                            current={tab.hostType.includes(hostType as AcceptedHostType)}
                            navbarTheme={navbarTheme}
                            isMinimized={drawerState.isSemiOpen && !createMenuAnchorEl}
                            isBooking={isBooking}
                            isFeedbackForm={isFeedbackForm}
                        />
                    ))}
                </div>

                <div className={cls("flex flex-col", classes.navigationGroup)}>
                    <Typography className={classes.navigationGroupTitle} variant="sm" fontWeight="bolder" color="blueGray400">
                        Modules
                    </Typography>

                    {NAVIGATION_TABS.filter((tab) => tab.type === "module").map((tab) => (
                        <NavigationItem
                            className={classes.navigationGroupItem}
                            key={tab.href}
                            url={tab.href}
                            icon={tab.icon}
                            isEvent={isEvent}
                            isRoom={isRoom}
                            text={tab.name}
                            current={tab.hostType.includes(hostType as AcceptedHostType)}
                            navbarTheme={navbarTheme}
                            isMinimized={drawerState.isSemiOpen && !createMenuAnchorEl}
                            isBooking={isBooking}
                            isFeedbackForm={isFeedbackForm}
                        />
                    ))}
                </div>

                <div className={cls("flex flex-col fullh", classes.navigationGroup)}>
                    <Typography className={classes.navigationGroupTitle} variant="sm" fontWeight="bolder" color="blueGray400">
                        Resources
                    </Typography>
                    <div className={cls("flex flex-col flex-justify-between fullh")}>
                        <div>
                            {NAVIGATION_TABS.filter((tab) => tab.type === "resource").map((tab) => (
                                <NavigationItem
                                    className={classes.navigationGroupItem}
                                    key={tab.href}
                                    url={tab.href}
                                    icon={tab.icon}
                                    isEvent={isEvent}
                                    isRoom={isRoom}
                                    text={tab.name}
                                    current={tab.hostType.includes(hostType as AcceptedHostType)}
                                    navbarTheme={navbarTheme}
                                    isMinimized={drawerState.isSemiOpen && !createMenuAnchorEl}
                                    isBooking={isBooking}
                                    isFeedbackForm={isFeedbackForm}
                                />
                            ))}
                        </div>
                        {/* {isOrganizationOwner && (
                            <div>
                                {NAVIGATION_TABS.filter((tab) => tab.type === "extras").map((tab) => (
                                    <NavigationItem
                                        className={classes.navigationGroupItem}
                                        key={tab.href}
                                        url={tab.href}
                                        icon={tab.icon}
                                        isEvent={isEvent}
                                        isRoom={isRoom}
                                        text={tab.name}
                                        current={tab.hostType.includes(hostType as AcceptedHostType)}
                                        navbarTheme={navbarTheme}
                                        isMinimized={drawerState.isSemiOpen && !createMenuAnchorEl}
                                        hideTextWhenMinimized
                                        isBooking={isBooking}
                                    />
                                ))}
                            </div>
                        )} */}
                    </div>
                </div>

                <div className={cls("flex flex-col", classes.navigationGroup, classes.navigationGroupFooter)}>
                    {drawerState.isFullyOpen && isReverseTrial && reverseTrialLeft > 0 && (
                        <Link className={classes.skipTrial} to="/user/organization/subscriptions/plans">
                            Skip trial, 20% off.
                        </Link>
                    )}
                    {isOrganizationOwner && (isReverseTrial || activePlan === OrganizationPlan.Free) ? (
                        <>
                            <Tooltip
                                title={drawerState.isSemiOpen ? (reverseTrialLeft > 0 ? "" : "Upgrade plan") : ""}
                                placement="top"
                                arrow
                            >
                                <Link
                                    className={cls(classes.trialLink, drawerState.isSemiOpen && classes.trialLinkSemiOpen)}
                                    to="/user/organization/subscriptions/plans"
                                >
                                    {drawerState.isSemiOpen ? (
                                        <>
                                            {reverseTrialLeft > 0 ? (
                                                <>
                                                    <RocketIcon /> <Typography className="mx-6">PRO</Typography>
                                                </>
                                            ) : (
                                                <RocketIcon />
                                            )}{" "}
                                        </>
                                    ) : (
                                        <>
                                            {reverseTrialLeft > 0 ? (
                                                <>
                                                    <RocketIcon />
                                                    PRO plan ({reverseTrialLeft} days left)
                                                </>
                                            ) : (
                                                <>
                                                    <RocketIcon /> Upgrade plan
                                                </>
                                            )}
                                        </>
                                    )}
                                </Link>
                            </Tooltip>
                            <div className={classes.separator}></div>
                        </>
                    ) : null}

                    {/* {NAVIGATION_TABS.filter((tab) => tab.type === "footer").map((tab) => (
                        <NavigationItem
                            className={classes.navigationGroupItem}
                            key={tab.href}
                            url={tab.href}
                            icon={tab.icon}
                            isEvent={isEvent}
                            isRoom={isRoom}
                            text={tab.name}
                            current={tab.hostType.includes(hostType as AcceptedHostType)}
                            navbarTheme={navbarTheme}
                            isMinimized={drawerState.isSemiOpen && !createMenuAnchorEl}
                            isBooking={isBooking}
                        />
                    ))} */}

                    <NavigationSelectWorkspaceMenu
                        menuAnchorEl={workspaceMenuAnchorEl}
                        setMenuAnchorEl={setWorkspaceMenuAnchorEl}
                        user={user}
                        navbarTheme={navbarTheme}
                        isDrawerOpen={drawerState.isFullyOpen}
                    />
                </div>
            </div>

            <div className={classes.footer}>
                <div className={classes.footerContent} onClick={handleOnProfileToggle} ref={ref}>
                    <ProfilePicture
                        firstName={user.firstName}
                        lastName={user.lastName}
                        avatar={user.profilePicture}
                        avatarSize="M"
                        avatarVariant="rounded"
                        disableTooltip
                    />

                    <div className={classes.nameWrapper}>
                        <div className={cls(classes.nameContainer, !activeOrganization.name && classes.nameContainerNoOrg)}>
                            <Typography component="div" className={cls("flex gap-4", classes.nameOrg)}>
                                {activeOrganization.name ? <div className={classes.nameContent}>{activeOrganization.name}</div> : null}
                                <ArrowDropDownRoundedIcon className={cls(classes.arrow, profileMenuOpen ? classes.arrowUp : null)} />
                            </Typography>

                            <Typography className={classes.namePerson}>
                                {user.firstName} {user.lastName}
                            </Typography>
                        </div>
                    </div>
                </div>
                {profileMenuOpen ? (
                    <NavigationUserDetails
                        open={profileMenuOpen}
                        anchorEl={ref.current}
                        onClose={handleOnProfileToggle}
                        disablePortal={!isMobileOrTablet}
                        planName={isOrganizationOwner ? beautifiedPlanName : undefined}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default memo(Navigation, () => true);

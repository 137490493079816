import clientEvents from "@api/events/client";
import descriptor, {ArtifactTag} from "@generated/artifacts";
import {Resources, ResourcesTypes} from "@generated/artifacts/resources";
import {HostType} from "@generated/data";
import env from "@generated/environment";
import Button from "@ui/cdk/Button";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import CircularProgress from "@ui/core/components/CircularProgress";
import Popover from "@ui/core/components/Popover";
import React, {useEffect, useRef} from "@workhorse/api/rendering";
import OnboardingTooltip from "@workhorse/components/OnboardingTooltip";
import {ProcessingResx} from "@workhorse/pages/resources/components/ResxFileProgressPreview";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {getCorrectPlural} from "@workhorse/util/strings";
import {ResourceRowExtra} from "../../utils";
import {getOnboardingDetails} from "../../utils/auxiliary";
import AdobeXDCreateForm from "./CreateForm/AdobeXDCreateForm";
import AirtableCreateForm from "./CreateForm/AirtableCreateForm";
import CanvaCreateForm from "./CreateForm/CanvaCreateForm";
import FilesCreateForm from "./CreateForm/FilesCreateForm";
import FigmaCreateForm from "./CreateForm/FigmaCreateForm";
import FormCreateForm from "./CreateForm/FormCreateForm";
import GalleryCreateForm from "./CreateForm/GalleryCreateForm";
import IframeCreateForm from "./CreateForm/IframeCreateForm";
import SmartboardCreateForm from "./CreateForm/SmartboardCreateForm";
import {modifyResourceCounter} from "./CreateForm/utils";
import VideoCreateForm from "./CreateForm/VideoCreateForm";
import classes from "./styles/ResxHeader.module.scss";
import {getNewResxButtonTitle} from "./utils";
import {FileMimeType} from "@sessions/common/various";
import {useMixpanelTracker} from "@workhorse/api/tracking";

export const CreateResourceComponentMap = (type, props): any => {
    const CompMap = {
        "flowos/video/resx/Video": <VideoCreateForm type={type} {...props} />,
        "flowos/gallery/resx/Gallery": <GalleryCreateForm type={type} {...props} />,
        "flowos/form/resx/Form": <FormCreateForm type={type} {...props} isToolsSection={true} />,
        "flowos/figma/resx/Figma": <FigmaCreateForm type={type} {...props} />,
        "flowos/canva/resx/Canva": <CanvaCreateForm type={type} {...props} />,
        "flowos/adobexd/resx/AdobeXD": <AdobeXDCreateForm type={type} {...props} />,
        "flowos/iframe/resx/Iframe": <IframeCreateForm type={type} {...props} />,
        "flowos/airtable/resx/Airtable": <AirtableCreateForm type={type} {...props} />,
        "flowos/blackboard/resx/Blackboard": <SmartboardCreateForm type={type} {...props} isToolsSection={true} />,
        "flowos/boardmix/resx/Boardmix": <AirtableCreateForm type={type} {...props} />,
        "flowos/pdf/resx/Pdf": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Pdf}
                acceptedExtensions={["PDF"]}
                description={"You can upload .pdf, directly from your computer."}
            />
        ),
        "flowos/ppt/resx/Ppt": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Presentation}
                acceptedExtensions={["PPT", "PPTX", "ODP"]}
                description={"You can upload .ppt, .pptx, .odp, directly from your computer."}
            />
        ),
        "flowos/doc/resx/Doc": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Document}
                acceptedExtensions={["DOC", "DOCX", "ODT"]}
                description={"You can upload .doc, .docx, .odt, directly from your computer."}
            />
        ),
        "flowos/excel/resx/Excel": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Spreadsheet}
                acceptedExtensions={["XLS", "XLSX", "ODS"]}
                description={"You can upload .xls, .xlsx, .ods, directly from your computer."}
            />
        ),
        "flowos/keynote/resx/Keynote": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Presentation}
                acceptedExtensions={["KEY"]}
                description={"You can upload .key, directly from your computer."}
            />
        ),
        "flowos/pages/resx/Pages": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Document}
                acceptedExtensions={["PAGES"]}
                description={"You can upload .pages, directly from your computer."}
            />
        ),
        "flowos/numbers/resx/Numbers": (
            <FilesCreateForm
                type={type}
                {...props}
                acceptedType={FileMimeType.Spreadsheet}
                acceptedExtensions={["NUMBERS"]}
                description={"You can upload .numbers, directly from your computer."}
            />
        ),
    };

    return CompMap[type];
};

const artifactsWithPalette = [
    "flowos/airtable",
    "flowos/mentimeter",
    "flowos/pitch",
    "flowos/slido",
    "flowos/vimeo",
    "flowos/youtube",
    "flowos/twitch",
    "flowos/boardmix",
];

type ResxHeaderProps = {
    type?: ResourcesTypes;
    artifactId?: string;
    onDoubleClick?: (
        resourceId: ResourceRowExtra["id"],
        setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
        calledFromCreate?: boolean
    ) => void;
    onNewResource?: (event: React.MouseEvent<HTMLButtonElement>) => void;

    isArtifactFile?: boolean;
    processingResx?: ProcessingResx;
    onChangeProcessingResx?: (values?: ProcessingResx, shouldRedirect?: boolean) => void;
    inPopover?: boolean;
    artifactTag?: ArtifactTag;
    noOfResources?: number;
    fromPalette?: (artifactTag: ArtifactTag, cb: (...arg: any) => any) => void;
    isGoogleSynced?: boolean;
};

const ResxHeader = (props: ResxHeaderProps) => {
    const {
        type,
        artifactId,
        onDoubleClick,
        onNewResource,
        inPopover,
        artifactTag,
        noOfResources,
        isArtifactFile,
        processingResx,
        onChangeProcessingResx,
        fromPalette,
        isGoogleSynced,
    } = props;

    const hostType = useHostType();
    const isResourcesPage = hostType === HostType.Resources;

    const mobileState = useMobile();
    const isMobile = mobileState.isMobile;

    const newBtnRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const artifactDisplayName = artifactTag ? descriptor[artifactTag as ArtifactTag].meta.displayName : "";
    const artifactIconSrc = artifactTag
        ? `${env.publicResourcesCdnRoot ?? ""}/icons/${descriptor[artifactTag as ArtifactTag].meta.icon}.svg`
        : "";

    const {mixpanelTrack} = useMixpanelTracker();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (artifactsWithPalette.includes(artifactTag as string)) {
            if (fromPalette && artifactTag) {
                fromPalette(artifactTag, (arg) => {
                    clientEvents.emit("click-new-resource", true);
                    if (onDoubleClick) {
                        setAnchorEl(event?.currentTarget);
                    } else if (onNewResource) {
                        onNewResource(event);
                    }

                    //for artifacts that work with the palette, increase the resx counter here
                    //regular artifacts increase the counter in the create form
                    if (type && arg.createdAt === undefined) {
                        modifyResourceCounter(type, "add", isArtifactFile);

                        clientEvents.emit("new-resource-uploaded", {
                            id: arg.id,
                            type: type,
                            artifactTag,
                        });
                    }
                });
            }
        } else {
            const node = document.getElementById("resources-upload-input-hidden");
            node?.click();

            clientEvents.emit("click-new-resource", true);
            if (onDoubleClick) {
                setAnchorEl(event?.currentTarget);
            } else if (onNewResource) {
                onNewResource(event);
            }
        }

        const resourceType = Object.keys(Resources)
            .find((name) => name.indexOf(artifactTag!) !== -1)
            ?.toLowerCase()
            ?.split("/")
            ?.pop() as ResourcesTypes;

        mixpanelTrack("frontend-add-resource", isResourcesPage ? "tools" : "files", resourceType);
    };

    const handleOpenPopoverByKeyPress = (e) => {
        if (e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA") {
            return;
        }
        if (e.key === "n" || e.key === "N") {
            setAnchorEl(newBtnRef.current);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleOpenPopoverByKeyPress);
        return () => {
            document.removeEventListener("keydown", handleOpenPopoverByKeyPress);
        };
    }, []);

    const shouldNotRenderCreate = type === "flowos/form/resx/Form" && inPopover;

    const {onboardingTitle, onboardingLink} = getOnboardingDetails(artifactTag);

    return (
        <div className={cls(classes.root, "flex", "fullw", "flex-align-center", "flex-justify-between")}>
            {onDoubleClick ? (
                // for files section, skip the popover and open upload modal directly
                isArtifactFile ? (
                    CreateResourceComponentMap(type, {
                        handleClose,
                        artifactId,
                        onDoubleClick,
                        inPopover: inPopover,
                        onChangeProcessingResx,
                        processingResx,
                    })
                ) : (
                    <Popover
                        id={"upload-popover"}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        classes={{
                            paper: cls(
                                classes.paper,
                                type && ["flowos/blackboard/resx/Blackboard", "flowos/form/resx/Form"].includes(type) && classes.hidden,
                                isResourcesPage && classes.paperResourcesPage
                            ),
                        }}
                    >
                        {anchorEl
                            ? CreateResourceComponentMap(type, {
                                  handleClose,
                                  artifactId,
                                  onDoubleClick,
                                  inPopover: inPopover,
                              })
                            : null}
                    </Popover>
                )
            ) : null}

            <div className="flex flex-align-center">
                {!isArtifactFile ? (
                    artifactIconSrc ? (
                        <img src={artifactIconSrc} alt={`icon-artifact`} className={classes.artifactIcon} />
                    ) : (
                        <div className={classes.iconLoading}>
                            <CircularProgress />
                        </div>
                    )
                ) : null}
                <Typography color="octonary" variant="xl" fontWeight="bolder">
                    {artifactDisplayName ? artifactDisplayName : "Loading..."}
                </Typography>

                {artifactDisplayName ? (
                    <OnboardingTooltip
                        title={onboardingTitle}
                        articleLink={onboardingLink}
                        placement="right"
                        className={classes.onboardingTooltip}
                        key="resx-header-onboarding-tooltip"
                    />
                ) : null}
                {noOfResources !== undefined ? (
                    <div className={classes.counter}>
                        {noOfResources} {getCorrectPlural("resource", noOfResources)}
                    </div>
                ) : null}
            </div>
            {!shouldNotRenderCreate && !isMobile ? (
                <Button
                    data-id={isResourcesPage ? "resources-button-new" : "files-button-new"}
                    id="newResxButton"
                    aria-describedby={id}
                    onClick={handleClick}
                    variant="secondary"
                    className={classes.addNewBtn}
                    ref={newBtnRef}
                    disabled={(isArtifactFile && processingResx?.isUploading) || !artifactDisplayName}
                >
                    <span>{getNewResxButtonTitle(artifactDisplayName, isArtifactFile, isGoogleSynced)}</span>
                </Button>
            ) : null}
        </div>
    );
};

export default ResxHeader;

import Button from "@ui/cdk/Button";
import MenuItem from "@ui/cdk/MenuItem";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import Popover from "@ui/core/components/Popover";
import {BackgroundFilter} from "@workhorse/api/conference2/lib/VideoReplacement";
import {Fragment, useEffect, useState} from "@workhorse/api/rendering";
import ParticipantBackgroundFilterSelect from "@workhorse/api/session-settings/sections/BackgroundEffectsSection/components/ParticipantBackgroundFilterSelect";
import {WithClassName} from "@workhorse/declarations";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {ReactComponent as AirPlayIcon} from "../../../../assets/media/air_play.svg";
import classes from "./styles/PreJoinConferenceBackgroundToggler.module.scss";
import {useTranslation} from "react-i18next";

type PreJoinConferenceBackgroundTogglerProps = {
    classes?: {
        active?: string;
        disabled?: string;
    };
    isDisabled?: boolean;
    filter: BackgroundFilter;
    onChangeFilter: (filter: BackgroundFilter) => void;
    videoCustomFilter: string | null;
    isMobile: boolean;
} & WithClassName;

const PreJoinConferenceBackgroundToggler = (props: PreJoinConferenceBackgroundTogglerProps) => {
    const {t} = useTranslation();
    const {filter, onChangeFilter, videoCustomFilter} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [activeTab, setActiveTab] = useState<number>(0);

    const {isPortrait} = useDeviceOrientation();

    useEffect(() => {
        if (!isPortrait && props.isMobile && anchorEl) {
            setAnchorEl(null);
        }
    }, [isPortrait, props.isMobile, anchorEl]);

    const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleChangeTab = (tab: number) => {
        setActiveTab(tab);
    };

    return (
        <Fragment key="pre-join-conference-background-toggler">
            <MenuItem
                data-id="prejoin-button-background"
                button
                className={cls(props.className, anchorEl && props.classes?.active, props.isDisabled && props.classes?.disabled)}
                onClick={handleToggleMenu}
                key="pre-join-conference-background-toggler-btn"
                disabled={props.isDisabled}
            >
                <AirPlayIcon className={classes.buttonIcon} key="pre-join-conference-background-icon" />
                <Typography className={classes.buttonLabel} key="pre-join-conference-background-label">
                    {t("participant.camera.background")}
                </Typography>
            </MenuItem>
            <Popover
                key="pre-join-conference-background-toggler-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleToggleMenu}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                classes={{
                    paper: classes.paper,
                }}
            >
                <div className={classes.header} key="pre-join-conference-background-header">
                    <Button
                        variant="plain"
                        onClick={handleChangeTab.bind(null, 0)}
                        className={cls(classes.headerBtn, activeTab === 0 && classes.headerBtnActive)}
                        key="pre-join-conference-background-header-effects"
                    >
                        <AirPlayIcon key="pre-join-conference-background-header-effects-icon" />
                        <Typography fontWeight="bold" key="pre-join-conference-background-header-effects-label">
                            {t("participant.camera.effects")}
                        </Typography>
                    </Button>
                </div>
                {activeTab === 0 ? (
                    <ParticipantBackgroundFilterSelect
                        filter={filter}
                        videoCustomFilter={videoCustomFilter}
                        onChange={onChangeFilter}
                        classes={{
                            root: classes.body,
                            filters: classes.filters,
                            effect: classes.effect,
                        }}
                    />
                ) : null}
                {props.isMobile ? (
                    <Button variant="ghost" className={classes.cancelButtonMobile} onClick={handleToggleMenu}>
                        {t("g.cancel")}
                    </Button>
                ) : null}
            </Popover>
        </Fragment>
    );
};

export default PreJoinConferenceBackgroundToggler;

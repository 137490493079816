import {ParticipantsMacroAccess} from "@generated/data";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import Select from "@ui/cdk/Select";
import Typography from "@ui/cdk/Typography/Typography";
import {cls} from "@ui/cdk/util";
import {useTranslation} from "react-i18next";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import genericStyles from "../../../../styles/GenericSection.module.scss";

type WidgetParticipantsSelectorProps = {
    value: ParticipantsMacroAccess;
    onChange: (value: ParticipantsMacroAccess) => void;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
};

const WidgetParticipantsSelector = (props: WidgetParticipantsSelectorProps) => {
    const {value, onChange, showIcon, disabled, className} = props;

    const {t} = useTranslation();

    const options = [
        {value: ParticipantsMacroAccess.Everyone, text: t("g.session.values_role.everyone") ?? "Everyone"},
        {value: ParticipantsMacroAccess.Moderators, text: t("g.session.values_role.speakers_hosts") ?? "Speakers & Hosts"},
        {value: ParticipantsMacroAccess.Disabled, text: t("g.disabled") ?? "Disabled"},
    ];

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as ParticipantsMacroAccess;
        onChange(value);
    };

    return (
        <div className={cls(genericStyles.togglerWrapper, genericStyles.togglerWrapperWithFooter, className)}>
            <div className="flex fullw">
                {showIcon ? (
                    <div className={genericStyles.iconWrapper}>
                        <GroupRoundedIcon />
                    </div>
                ) : null}
                <div className="flex flex-justify-between flex-align-center fullw">
                    <div className={genericStyles.textWrapper}>
                        <Typography variant="base" fontWeight="bold" color="secondary">
                            {t("macro.participants.title")}
                        </Typography>
                        <Typography color="quaternary" component="span">
                            {t("player.settings.general.widgets.participants.description")}
                        </Typography>
                    </div>

                    <Select
                        data-id="participants-macro-settings"
                        options={options}
                        value={value}
                        IconComponent={ExpandMoreRoundedIcon}
                        onChange={handleOnChange}
                        className={genericStyles.selectInput}
                        MenuProps={{
                            classes: {
                                paper: genericStyles.selectMenuPaper,
                            },
                        }}
                        wrapperClassName={genericStyles.selectFormControl}
                        disabled={disabled}
                    />
                </div>
            </div>

            {value === ParticipantsMacroAccess.Disabled && (
                <div className={genericStyles.disclaimer}>
                    <ErrorOutlineRoundedIcon />
                    <Typography variant="sm" color="secondary" fontWeight="bold">
                        {t("player.settings.general.widgets.participants.warning")}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default WidgetParticipantsSelector;

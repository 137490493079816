import {ParticipantInviteStatus, ParticipantStatus} from "@generated/data";
import {AvatarSizeTypes, AvatarVariantTypes} from "@ui/cdk/Avatar/types";
import {SessionsAvatarIconRight} from "@ui/cdk/SessionsAvatar/types";
import Typography from "@ui/cdk/Typography";
import React, {forwardRef, useEffect, useMemo, useState} from "@workhorse/api/rendering";
import {ExtendComponent} from "@workhorse/declarations";
import {cls} from "@ui/cdk/util/util";
import ProfilePicture from "./ui/ProfilePicture";
import RSVPAcceptedIcon from "@material-ui/icons/CheckCircleOutlined";
import RSVPDeclinedIcon from "@material-ui/icons/HighlightOffOutlined";
import RSVPTentativeIcon from "@material-ui/icons/HelpOutlineOutlined";
import styles from "./styles/ParticipantDisplayDetails.module.scss";
import {useUserInfo} from "@workhorse/providers/User";
import {ParticipantsCategory} from "@workhorse/pages/player/session-view-components/session-participants/utils";
import {useTranslation} from "react-i18next";
import TimelapseRoundedIcon from "@material-ui/icons/TimelapseRounded";
import IconButton from "@ui/core/components/IconButton";
import Tooltip from "@ui/cdk/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import {ReactComponent as CopyEmailIcon} from "../../player/assets/media/copy_email_memory.svg";

type ParticipantDisplayDetailsComponents =
    | "div"
    | React.ComponentType<{
          onMouseEnter?: (e: React.MouseEvent<unknown, MouseEvent>) => void;
          onMouseLeave?: (e: React.MouseEvent<unknown, MouseEvent>) => void;
      }>;

type ParticipantDisplayDetailsProps<T extends ParticipantDisplayDetailsComponents> = {
    renderComponent?: T;
    type?: ParticipantsCategory;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    classes?: {
        wrap?: string;
        displayName?: string;
        email?: string;
        avatar?: string;
        status?: string;
        customStatus?: string;
        timeInfoIcon?: string;
        emailCopied?: string;
    };
    showEmail?: boolean;
    showInfo?: boolean;
    showName?: boolean;
    customStatus?: string | JSX.Element;
    showProfilePicture?: boolean;
    hideRightBadge?: boolean;
    /**
     * Index to be displayed for guests
     */
    index?: number;
    firstName?: string;
    lastName?: string;
    email?: string | null;
    participantStatus?: ParticipantStatus;
    participantInviteStatus?: ParticipantInviteStatus;
    isUser?: boolean;
    avatar?: string | null;
    disableTooltip?: boolean;
    emailTooltip?: boolean;
    participantIsAssistant: boolean;
    isOwner?: boolean;
    showRSVP?: boolean;
    isPastSession?: boolean;
    showKickedInfo?: boolean;
    avatarSize?: AvatarSizeTypes;
    avatarVariant?: AvatarVariantTypes;
    delimiter?: React.ReactNode;
    isMemoryMode?: boolean;
    joinedAtLeastOnce?: boolean;
    renderingForAdmin?: boolean;
    renderingForAssistant?: boolean;
    withWhiteBorder?: boolean;
    smallBorder?: boolean;
    isCurrent?: boolean;
    conferenceStatus?: any;
    showRSVPBadge?: any;
    sessionStarted?: boolean;
    guestToken?: string | null;

    dontPassRestProps?: ("sessionStarted" | "guestToken")[];
    showCopyAction?: boolean;
    userTimeInfo?: {
        joinedAt?: string;
        leftAt?: string;
        totalSpent?: string;
    } | null;
    isOrganizer?: boolean;
} & React.ComponentProps<T>;

function ParticipantDisplayDetailsWithRef<T extends ParticipantDisplayDetailsComponents>(
    props: ParticipantDisplayDetailsProps<T>,
    ref: any
) {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const {
        renderComponent: Component = "div",
        className,
        classes,
        children,
        hideRightBadge = false,
        showEmail = false,
        showName = true,
        showInfo = true,
        customStatus,
        showProfilePicture = false,
        disableTooltip = false,
        emailTooltip = false,
        index,
        firstName,
        lastName,
        email,
        participantStatus,
        participantInviteStatus,
        isUser,
        avatar,
        participantIsAssistant,
        isOwner,
        showRSVP,
        isPastSession,
        showKickedInfo,
        avatarSize = "M",
        avatarVariant,
        delimiter,
        isMemoryMode,
        joinedAtLeastOnce,
        renderingForAdmin,
        renderingForAssistant,
        withWhiteBorder,
        smallBorder,
        isCurrent = false,
        type,
        conferenceStatus,
        showRSVPBadge,
        sessionStarted,
        guestToken,
        dontPassRestProps,
        showCopyAction,
        userTimeInfo,
        isOrganizer,
        ...other
    } = props;

    const currentUser = useUserInfo();
    const [emailCopied, setEmailCopied] = useState<boolean>(false);
    const isJoined = !isMemoryMode
        ? participantStatus === ParticipantStatus.JoinedSession || participantStatus === ParticipantStatus.InLobby
        : joinedAtLeastOnce || false;

    const {displayName, customFirstName, customLastName} = useMemo(() => {
        let displayName = "",
            customFirstName: string | undefined = undefined,
            customLastName: string | undefined = undefined;

        if (isUser) {
            displayName = `${firstName} ${lastName}`;
            customFirstName = firstName;
            customLastName = lastName;
        } else {
            if (!isJoined && currentUser.email !== email && !isOwner) {
                if (firstName) {
                    displayName = firstName;
                    customFirstName = displayName.split(" ")[0];
                    const i = displayName.indexOf(" ");
                    customLastName = i !== -1 ? displayName.slice(i + 1) : "";
                } else {
                    displayName = `${t("participant.g.guest")} ${index ? `#${index}` : ""}`;
                    customFirstName = `${t("participant.g.guest")}`;
                    customLastName = index ? `#${index}` : "";
                }
            } else {
                displayName = `${firstName} ${lastName}`;
                customFirstName = firstName;
                customLastName = lastName;
            }
        }

        if (customFirstName && !customLastName) {
            const fullName = displayName;
            customFirstName = fullName.split(" ")[0];
            const i = displayName.indexOf(" ");
            customLastName = i !== -1 ? displayName.slice(i + 1) : "";
        }

        if ((!!currentUser?.email?.trim?.() && !!email?.trim?.() && currentUser.email === email) || isCurrent) {
            displayName = t("participant.g.you");
        }

        return {displayName, customFirstName, customLastName};
    }, [firstName, lastName, email, currentUser, index, isCurrent, isOwner, currentLanguage]);

    let rsvpIcon;

    switch (participantInviteStatus) {
        case ParticipantInviteStatus.Accepted:
            rsvpIcon = <RSVPAcceptedIcon className={cls(styles.rsvpIcon, styles.rsvpIconAccepted)} />;
            break;
        case ParticipantInviteStatus.Declined:
            rsvpIcon = <RSVPDeclinedIcon className={cls(styles.rsvpIcon, styles.rsvpIconDeclined)} />;
            break;
        case ParticipantInviteStatus.Tentative:
            rsvpIcon = <RSVPTentativeIcon className={cls(styles.rsvpIcon, styles.rsvpIconTentative)} />;
            break;
        default:
            rsvpIcon = undefined;
    }

    const isKicked = showKickedInfo && participantStatus === ParticipantStatus.Kicked;
    const statusText = isKicked ? `(${t("participant.g.kicked")})` : "";

    const compRestProps = props.renderComponent
        ? ["guestToken", "sessionStarted"].reduce((all, k) => {
              if (!dontPassRestProps || !dontPassRestProps.includes(k as (typeof dontPassRestProps)[number])) {
                  all[k] = props[k] ?? undefined;
              }
              return all;
          }, {})
        : null;

    const handleEmailCopy = () => {
        setEmailCopied(true);
        setTimeout(() => {
            setEmailCopied(false);
        }, 1000);
    };

    return (
        <Component {...other} {...compRestProps} className={className} ref={ref} data-id="participant-list-item">
            {showProfilePicture ? (
                <ProfilePicture
                    firstName={customFirstName}
                    lastName={customLastName}
                    email={email}
                    withWhiteBorder={withWhiteBorder}
                    smallBorder={smallBorder}
                    avatar={avatar}
                    disableTooltip={disableTooltip}
                    emailTooltip={emailTooltip}
                    avatarSize={avatarSize}
                    iconRight={hideRightBadge ? undefined : isJoined ? SessionsAvatarIconRight.ONLINE : undefined}
                    avatarVariant={avatarVariant}
                    className={cls(styles.profilePicture, classes?.avatar)}
                />
            ) : null}
            {showInfo ? (
                <div className={classes?.wrap} data-id="participant-item-info">
                    <div className="flex flex-items-center">
                        {showName ? (
                            <span data-id="participant-name" className={classes?.displayName}>
                                {displayName.trim() ? displayName : t("participant.g.guest")}
                            </span>
                        ) : null}

                        {showRSVP ? rsvpIcon : null}

                        {!isPastSession || isKicked ? (
                            <Typography component="span" variant="sm" className={cls(styles.status, classes?.status)}>
                                {statusText}
                            </Typography>
                        ) : null}
                    </div>
                    <div className={isMemoryMode || showEmail ? "flex flex-row gap-2 flex-align-center" : "flex flex-col"}>
                        {customStatus ? (
                            <span data-id="participant-custom-status" className={classes?.customStatus}>
                                {customStatus}
                            </span>
                        ) : null}

                        {(showEmail && !isMemoryMode) || (isMemoryMode && isOrganizer) ? (
                            <>
                                {delimiter ? delimiter : null}
                                <Typography
                                    data-id="participant-email"
                                    className={classes?.email}
                                    component="span"
                                    variant="sm"
                                    color="quaternary"
                                >
                                    {email}
                                </Typography>
                            </>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {isMemoryMode && showCopyAction && email && isOrganizer ? (
                <Tooltip
                    open={emailCopied}
                    title={
                        emailCopied ? (
                            <div className={cls(classes?.emailCopied, "flex flex-row flex-align-center flex-justify-between")}>
                                <CheckRoundedIcon className="mr-2" />
                                <Typography variant="base" fontWeight="bold">
                                    Email copied
                                </Typography>
                            </div>
                        ) : (
                            ""
                        )
                    }
                    placement="top"
                >
                    {isOrganizer && (
                        <IconButton size="small" className={classes?.timeInfoIcon}>
                            <CopyToClipboard text={email ?? ""} onCopy={handleEmailCopy}>
                                <CopyEmailIcon />
                            </CopyToClipboard>
                        </IconButton>
                    )}
                </Tooltip>
            ) : null}
            {isMemoryMode && isOrganizer && userTimeInfo ? (
                <Tooltip
                    classes={{
                        tooltip: styles.timeInfoTooltip,
                    }}
                    title={
                        <div className={cls("flex flex-col gap-8 fullw")}>
                            <div className="flex flex-row flex-justify-between flex-items-center fullw">
                                <Typography variant="sm" color="quaternary" fontWeight="bold">
                                    Joined at:
                                </Typography>
                                <Typography variant="sm" color="primary" fontWeight="bolder">
                                    {userTimeInfo?.joinedAt}
                                </Typography>
                            </div>
                            <div className="flex flex-row flex-justify-between flex-items-center fullw">
                                <Typography variant="sm" color="quaternary" fontWeight="bold">
                                    Left at:
                                </Typography>
                                <Typography variant="sm" color="primary" fontWeight="bolder">
                                    {userTimeInfo?.leftAt}
                                </Typography>
                            </div>
                            <div className="flex flex-row flex-justify-between flex-items-center fullw">
                                <Typography variant="sm" color="quaternary" fontWeight="bold">
                                    Total spent in session:
                                </Typography>
                                <Typography variant="sm" color="primary" fontWeight="bolder">
                                    {userTimeInfo?.totalSpent}
                                </Typography>
                            </div>
                        </div>
                    }
                    placement="top-end"
                >
                    <IconButton size="small" className={classes?.timeInfoIcon}>
                        <TimelapseRoundedIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
            {children}
        </Component>
    );
}

const ParticipantDisplayDetails = forwardRef(ParticipantDisplayDetailsWithRef) as <T extends ParticipantDisplayDetailsComponents>(
    props: ParticipantDisplayDetailsProps<T>
) => ReturnType<typeof ParticipantDisplayDetailsWithRef>;

export default ParticipantDisplayDetails;

import Typography from "@ui/cdk/Typography";
import designer from "@workhorse/api/designer";
import {useEffect, useState} from "@workhorse/api/rendering";
import {showBackdrop} from "@workhorse/pages/designer/DesignerBackdrop";
import {cls} from "@ui/cdk/util/util";
import classes from "./style/AgendaBoxSpeakerNotes.module.scss";
import IconButton from "@ui/core/components/IconButton";
import {ReactComponent as ClosedSpeakerNotesIcon} from "../../../../../../frontend/assets/media/closed-speaker-notes-icon.svg";
import {ReactComponent as OpenSpeakerNotesIcon} from "../../../../../../frontend/assets/media/open-speaker-notes-icon.svg";
import clientEvents, {useClientEvent} from "@api/events/client";
import Editor, {EditorSerializedContent} from "@workhorse/components/editor";
import {isEditorEmpty, JSONContent, parseAsTipTapJSON} from "@api/editor";
import {useTranslation} from "react-i18next";
import {ReactComponent as UnfoldLessIcon} from "../../../../../../frontend/assets/media/unfold-less-icon.svg";
import UnfoldMoreRoundedIcon from "@material-ui/icons/UnfoldMoreRounded";
import UnfoldLessRoundedIcon from "@material-ui/icons/UnfoldLessRounded";
import Button from "@ui/cdk/Button";
import {useMixpanelTracker} from "@workhorse/api/tracking";

const MAX_INPUT_LENGTH = 5000;

type AgendaBoxSpeakerNotesProps = {
    agendaItemId: string;
    speakerNotesJson?: JSONContent | undefined | null;
    readOnly: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    isAgendaTemplates?: boolean;
    inEventSettings?: boolean;
    isMobile?: boolean;
    withseparator?: boolean;
    isSetupMode?: boolean;
    className?: string;
    inLobby?: boolean;
    standalone?: boolean;
};
export const AgendaBoxSpeakerNotes = (props: AgendaBoxSpeakerNotesProps) => {
    const {t} = useTranslation();
    const {
        agendaItemId,
        speakerNotesJson,
        readOnly,
        onFocus,
        onBlur,
        isAgendaTemplates,
        inEventSettings,
        isMobile,
        withseparator,
        isSetupMode,
        className,
        inLobby,
        standalone,
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [value, setValue] = useState<JSONContent>(speakerNotesJson ?? {});

    const handleChange = (value) => {
        setValue(value);
    };

    useEffect(() => {
        if (speakerNotesJson && speakerNotesJson !== value) {
            setValue(parseAsTipTapJSON(speakerNotesJson));
        }
    }, [speakerNotesJson]);

    const handleSubmit = (event?: any) => {
        showBackdrop(false);
        designer.api.agendaItem.update({
            id: agendaItemId,
            agendaItem: {
                speakerNotesJson: value,
            },
        });

        if (isAgendaTemplates) {
            const relatedTarget = event?.relatedTarget as HTMLElement;
            const isOutOfScopeEditing = relatedTarget?.classList.contains("editing");
            const isEditingTarget = relatedTarget?.classList.contains("editing_" + agendaItemId) || isOutOfScopeEditing;

            if (!isEditingTarget && !inEventSettings) {
                onBlur?.();
            }
            if (inEventSettings && !isOutOfScopeEditing) {
                designer.commit();
            }

            return;
        }

        onBlur?.();
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (isAgendaTemplates) {
            const relatedTarget = event?.relatedTarget as HTMLElement;
            const isEditingTarget = relatedTarget?.classList.contains("editing_" + agendaItemId);
            if (!isEditingTarget) {
                onFocus?.();
            }

            return;
        }

        onFocus?.();
    };

    const handleToggleStandaloneNotes = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    useClientEvent("active-speaker-notes-agenda-id", (id) => {
        if ((readOnly && !speakerNotesJson) || isMobile) {
            return;
        }

        setIsOpen(agendaItemId === id ? true : false);
    });

    if ((readOnly && !speakerNotesJson) || isMobile || (!isOpen && !standalone)) {
        return null;
    }

    const content = readOnly ? (
        <div className={classes.previewText}>
            <EditorSerializedContent content={value} className={classes.previewText} />
        </div>
    ) : (
        <Editor
            toolbarPortal={
                isAgendaTemplates ? `edit-speaker-notes-slate-toolbar-slot-${agendaItemId}` : "edit-speaker-notes-slate-toolbar-slot"
            }
            toolbarTheme="floating"
            className={cls(classes.speakerNotesEditor, isAgendaTemplates && classes.speakerNotesEditorAgendaTemplates)}
            toolbarDirection={isAgendaTemplates ? "horizontal" : "vertical"}
            placeholder={readOnly ? "" : t("agenda.items.add_speaker_notes") ?? "Add speaker notes"}
            renderToolbarOnFocus
            hideToolbarItems={["headings", "alignment", "indentation"]}
            onBlur={handleSubmit}
            readOnly={readOnly}
            value={value}
            onChange={handleChange}
            maxLength={MAX_INPUT_LENGTH}
            onFocus={handleFocus}
            toolbarClassName={cls(
                classes.speakerNotesEditorToolbar,
                isAgendaTemplates && classes.speakerNotesEditorToolbarAgendaTemplates,
                inLobby && classes.speakerNotesEditorToolbarInLobby
            )}
        />
    );

    return !standalone ? (
        <>
            {isOpen && (
                <div className={cls(classes.rootStandalone, isAgendaTemplates && classes.rootStandaloneTemplate)}>
                    <div className="flex fullw flex-align-center flex-justify-between">
                        <Typography variant="base" fontWeight="bolder">
                            {t("macro.notes.title") ?? "Notes"}
                        </Typography>
                    </div>
                    {content}
                </div>
            )}
        </>
    ) : (
        <div className="fullw flex flex-col">
            {!isAgendaTemplates ? (
                <div className={cls(classes.rootStandalone)}>
                    <div className="flex fullw flex-align-center flex-justify-between cursor-pointer" onClick={handleToggleStandaloneNotes}>
                        <Typography variant="base" fontWeight="bolder">
                            {t("macro.notes.title") ?? "Notes"}
                        </Typography>
                        <IconButton>{isOpen ? <UnfoldLessRoundedIcon /> : <UnfoldMoreRoundedIcon />}</IconButton>
                    </div>
                    {isOpen && content}
                </div>
            ) : (
                <>
                    {isOpen && (
                        <div className={cls(classes.rootStandalone, isAgendaTemplates && classes.rootStandaloneTemplate)}>
                            <div className="flex fullw flex-align-center flex-justify-between">
                                <Typography variant="base" fontWeight="bolder">
                                    {t("macro.notes.title") ?? "Notes"}
                                </Typography>
                            </div>
                            {content}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

type AgendaBoxSpeakerNotesTogglerProps = {
    agendaItemId: string;
    speakerNotesJson?: JSONContent | undefined;
    readOnly: boolean;
    className?: string;
    isMobile?: boolean;
};

export const AgendaBoxSpeakerNotesToggler = (props: AgendaBoxSpeakerNotesTogglerProps) => {
    const {t} = useTranslation();
    const {speakerNotesJson, readOnly, className, isMobile, agendaItemId} = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {mixpanelTrack} = useMixpanelTracker();
    const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isOpen) {
            setIsOpen(false);
            clientEvents.emit("active-speaker-notes-agenda-id", "");
        } else {
            setIsOpen(true);
            clientEvents.emit("active-speaker-notes-agenda-id", agendaItemId);
            mixpanelTrack("frontend-add-notes", "agendas");
        }

        event.stopPropagation();
    };

    useClientEvent("active-speaker-notes-agenda-id", (id) => {
        if ((readOnly && !speakerNotesJson) || isMobile) {
            return;
        }

        setIsOpen(agendaItemId === id ? true : false);
    });

    if ((readOnly && !speakerNotesJson) || isMobile) {
        return null;
    }

    const isSpeakerNotesEmpty = isEditorEmpty(speakerNotesJson);

    if (readOnly && isSpeakerNotesEmpty) {
        return null;
    }
    return (
        <Button
            onClick={handleToggle}
            className={cls(classes.toggler, className, Boolean(isOpen) && classes.togglerOpen)}
            data-id="speaker-notes-button"
            variant="tertiary"
            size="smallest"
        >
            {isOpen ? <OpenSpeakerNotesIcon /> : <ClosedSpeakerNotesIcon />}{" "}
            {!isOpen && isSpeakerNotesEmpty
                ? "Add notes"
                : !isOpen && !isSpeakerNotesEmpty
                ? t("macro.notes.title") ?? "Notes"
                : "Hide notes"}
        </Button>
    );
};

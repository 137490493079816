import Button from "@ui/cdk/Button";
import Checkbox from "@ui/cdk/Checkbox";
import Popover from "@ui/core/components/Popover";
import FilterListIcon from "@material-ui/icons/FilterListRounded";
import classes from "./style/UsageHistoryFilters.module.scss";
import {useState} from "@workhorse/api/rendering";

type UsageHistoryFiltersProps = {
    filter: {personal: boolean; others: boolean};
    setFilter: React.Dispatch<
        React.SetStateAction<{
            personal: boolean;
            others: boolean;
        }>
    >;
};

const UsageHistoryFiltersButton = (props: UsageHistoryFiltersProps) => {
    const {filter, setFilter} = props;
    const [filtersMenuAnchorEl, setFiltersMenuAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenFiltersMenu = (event: React.MouseEvent<HTMLElement>) => {
        setFiltersMenuAnchorEl(event.currentTarget);
    };

    const handleCloseFiltersMenu = () => {
        setFiltersMenuAnchorEl(null);
    };

    const toggleFilter = (filter: "personal" | "others") => {
        setFilter((prev) => ({...prev, [filter]: !prev[filter]}));
    };

    return (
        <>
            <div className={classes.filtersButtonContainer}>
                <Button data-id="filters" variant="tertiary" onClick={handleOpenFiltersMenu} className={classes.filtersButton}>
                    <div className="flex flex-align-center">
                        <FilterListIcon className={filtersMenuAnchorEl ? classes.selected : ""} /> Filters
                    </div>
                </Button>
                {(filter.personal && !filter.others) || (!filter.personal && filter.others) ? (
                    <div className={classes.filterActiveIndicator}></div>
                ) : null}
            </div>

            <Popover
                anchorEl={filtersMenuAnchorEl}
                open={Boolean(filtersMenuAnchorEl)}
                onClose={handleCloseFiltersMenu}
                classes={{paper: classes.filtersPopoverPaper}}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div className="flex flex-col">
                    <div className="flex flex-align-center">
                        <Checkbox data-id="personal" checked={filter.personal} onChange={toggleFilter.bind(this, "personal")} />
                        My sessions
                    </div>

                    <div className="flex flex-align-center">
                        <Checkbox data-id="others" checked={filter.others} onChange={toggleFilter.bind(this, "others")} />
                        Others&apos; sessions
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default UsageHistoryFiltersButton;

import {OrganizationPlan} from "@generated/data";

export const appSumoPlans: OrganizationPlan[] = [
    OrganizationPlan.AppsumoTier_1,
    OrganizationPlan.AppsumoTier_2,
    OrganizationPlan.AppsumoTier_3,
    OrganizationPlan.AppsumoTier_4,
    OrganizationPlan.AppsumoTier_5,
];

export type AppSumoPlan =
    | OrganizationPlan.AppsumoTier_1
    | OrganizationPlan.AppsumoTier_2
    | OrganizationPlan.AppsumoTier_3
    | OrganizationPlan.AppsumoTier_4
    | OrganizationPlan.AppsumoTier_5;

export const isAppSumoPlan = (plan: OrganizationPlan) => {
    return appSumoPlans.includes(plan);
};

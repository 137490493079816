import {useSubscription} from "@apollo/client";
import {OnReactionSendDocument} from "@generated/data";
import {Fragment, useEffect, useState} from "@workhorse/api/rendering";
import {notificationIds} from "@workhorse/api/session-settings/sections/NotificationsSection/NotificationsSection";
import {useSessionReactions} from "@workhorse/providers/SessionReactionsProvider";
import {useUserInfo} from "@workhorse/providers/User";
import {Transition, TransitionGroup} from "react-transition-group";
import classes from "../styles/ReactionsContainer.module.scss";
import ReactionEntry from "./ReactionEntry";

const TRANSITION_TIME = 300;

type ReactionsContainerProps = {
    sessionId: string;
    disabledNotifications: string[];
};

const getStyleFromChecksum = (checksum: number) => {
    const left = ["20px", "90px", "55px"];
    return {
        left: left[checksum % 3],
        transform: `translateY(${35 - 25 * checksum}px)`,
    };
};

const ReactionsContainer = (props: ReactionsContainerProps) => {
    const user = useUserInfo();
    const {sessionId, disabledNotifications} = props;

    const {reactions, addReaction} = useSessionReactions();
    const [checksum, setChecksum] = useState(0);

    const [resetTimer, setResetTimer] = useState<NodeJS.Timeout | null>(null);

    const isNotificationDisabled = disabledNotifications.some((x) => x === notificationIds.REACTIONS);

    useSubscription(OnReactionSendDocument, {
        skip: !user.id || user.isPublicMemoryGuestMockd,
        variables: {
            sessionId,
        },
        onSubscriptionData: (result) => {
            const data = result.subscriptionData.data;

            if (!data || !data.onReactionSend) {
                return;
            }

            const {participantId, reactionType} = data.onReactionSend;

            addReaction(participantId, reactionType, checksum);
            if (resetTimer) {
                clearTimeout(resetTimer);
            }
            if (checksum > 4) {
                setChecksum(0);
            } else {
                setChecksum((c) => c + 1);
            }
            setResetTimer(
                setTimeout(() => {
                    setChecksum(0);
                }, 4000)
            );
        },
    });

    useEffect(() => {
        return () => {
            if (resetTimer) {
                clearTimeout(resetTimer);
            }
        };
    }, []);

    return (
        <Fragment key="reactions-container">
            {!isNotificationDisabled ? (
                <TransitionGroup component={null} key="reactions-transition-group">
                    {reactions
                        .filter((r) => !r.isExpired && r.canShow)
                        .map((r) => (
                            <Transition mountOnEnter unmountOnExit timeout={TRANSITION_TIME} key={"reaction-transition-" + r.id}>
                                {(state) => (
                                    <ReactionEntry
                                        participantId={r.pId}
                                        reactionType={r.type}
                                        className={classes[state]}
                                        style={{
                                            transitionDuration: `${TRANSITION_TIME}ms`,
                                            animationDuration: `${TRANSITION_TIME}ms`,
                                            ...getStyleFromChecksum(r.checksum ?? 3),
                                        }}
                                        key={"reaction-entry-" + r.id}
                                    />
                                )}
                            </Transition>
                        ))}
                </TransitionGroup>
            ) : null}
        </Fragment>
    );
};

export default ReactionsContainer;

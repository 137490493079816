import {
    ControlStatus,
    GetMemorySessionDocument,
    GetSessionDocument,
    HostType,
    LocalMemoryParticipantQuery,
    LocalMemoryParticipantsDocument,
    LocalMemoryParticipantsQuery,
    LocalParticipantQuery,
    LocalParticipantsDocument,
    LocalParticipantsQuery,
    SessionLifecycle,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {rbac} from "@workhorse/api/user";
import {Participant} from "@workhorse/declarations/dataTypes";
import {useSessionIdFromRoute} from "@workhorse/providers/CurrentSessionIdProvider";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {readSession, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useEffect} from "react";
import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";
import {useRoomStore} from "../ConferenceRoomProvider";
import {buildConferenceParticipants, getParticipantsCount, searchParticipants, sortAllParticipants, splitParticipants} from "./utils";

function areEqual(a: Participant[], b: Participant[]) {
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}

type LocalParticipantsState = {
    currentSession: {
        id: string;
        isLobby: boolean;
        isMemoryMode: boolean;
    };
    moderators: Participant[];
    speakers: Participant[];
    waitingToJoin: Participant[];
    joined: Participant[];
    joinedFullList: Participant[];
    notJoined: Participant[];
    invited: Participant[];
    conferenceAudience: Participant[];
    conferenceSpeakers: Participant[];
    conferenceSpotlight: Participant[];
    conferenceInvitedSpeakers: Participant[];
    conferenceJoinedSpeakers: Participant[];
    conferencePendingSpeakers: Participant[];
    participantsMap: Record<string, Participant>;
    searchValue: string;
    totalParticipantsCount: number;
    activeParticipantsCount: number;
    amIaModerator: boolean;
    amIAssistant: boolean;
    moderatorsCount: number;
    ownerInfo?: Participant;
    currentParticipant?: Participant;
    allParticipants: Participant[];
    controllingParticipantId?: string;
    requestingParticipantId?: string;
    canEnterSession: boolean;
};

type LocalParticipantsStore = LocalParticipantsState & {
    initStore(sessionId: string, isLobby: boolean, isMemoryMode: boolean): void;
    cleanupStore(): void;
    setSearchValue(searchValue: string): void;
    isParticipantAssistant(participantId: string): boolean;
    getMyIndex(participantId?: string): number;
};

const initialState: LocalParticipantsState = {
    currentSession: {
        id: "",
        isLobby: false,
        isMemoryMode: false,
    },
    moderators: [],
    speakers: [],
    waitingToJoin: [],
    joined: [],
    joinedFullList: [],
    notJoined: [],
    invited: [],
    conferenceAudience: [],
    conferenceSpeakers: [],
    conferenceSpotlight: [],
    conferenceInvitedSpeakers: [],
    conferenceJoinedSpeakers: [],
    conferencePendingSpeakers: [],
    participantsMap: {},
    searchValue: "",
    activeParticipantsCount: 0,
    totalParticipantsCount: 0,
    moderatorsCount: 0,
    ownerInfo: undefined,
    currentParticipant: undefined,
    amIaModerator: false,
    allParticipants: [],
    controllingParticipantId: undefined,
    requestingParticipantId: undefined,
    canEnterSession: true,
    amIAssistant: false,
};

type SessionDeps = {
    isEvent?: boolean;
    isBackstage?: boolean;
    lifecycle?: SessionLifecycle;
    requestPermissionToJoin?: boolean;
    provideSessionPasscode?: boolean;
};

export const useParticipantsStore = create<LocalParticipantsStore>((set, get) => {
    let subscriber: ZenObservable.Subscription;
    let sessionSubscriber: ZenObservable.Subscription;
    let t: NodeJS.Timeout | null = null;

    let sessDeps: SessionDeps = {};

    const sessionDepsChanged = (newState: SessionDeps) => {
        return Object.keys(newState).reduce((sum, k) => sum + (sessDeps[k] === newState[k] ? 0 : 1), 0) > 0;
    };

    let ownerId: string | undefined;

    const initStore = (sessionId: string, isLobby: boolean, isMemoryMode: boolean) => {
        if (subscriber) {
            subscriber.unsubscribe();
        }

        if (sessionSubscriber) {
            sessionSubscriber.unsubscribe();
        }

        sessDeps = {};

        set({currentSession: {id: sessionId, isLobby, isMemoryMode}});

        subscriber = apollo.client
            .watchQuery({
                query: isMemoryMode ? LocalMemoryParticipantsDocument : LocalParticipantsDocument,
                variables: {id: sessionId},
                fetchPolicy: "cache-only",
                returnPartialData: true,
            })
            .subscribe((result) => {
                upsertStore(result.data);
            });

        sessionSubscriber = apollo.client
            .watchQuery({
                query: isMemoryMode ? GetMemorySessionDocument : GetSessionDocument,
                variables: {id: sessionId},
                fetchPolicy: "cache-only",
                returnPartialData: true,
            })
            .subscribe((result) => {
                const {lifecycle, requestPermissionToJoin, provideSessionPasscode, event, backstage} = result.data.session ?? {};

                if (
                    !sessionDepsChanged({
                        isBackstage: backstage,
                        isEvent: !!event?.id,
                        lifecycle,
                        provideSessionPasscode,
                        requestPermissionToJoin,
                    })
                ) {
                    return;
                }
                const participantsQuery = apollo.client.readQuery({
                    query: isMemoryMode ? LocalMemoryParticipantsDocument : LocalParticipantsDocument,
                    variables: {
                        id: sessionId,
                    },
                });
                upsertStore(participantsQuery ?? undefined);
            });

        useRoomStore.subscribe((current, previous) => {
            if (current.joinedParticipants === previous.joinedParticipants) {
                return;
            }

            const participantsQuery = apollo.client.readQuery({
                query: isMemoryMode ? LocalMemoryParticipantsDocument : LocalParticipantsDocument,
                variables: {
                    id: sessionId,
                },
            });
            upsertStore(participantsQuery ?? undefined);
        });
    };

    const upsertStore = (data?: LocalParticipantsQuery | LocalMemoryParticipantsQuery) => {
        const currentSession = get().currentSession;
        const session = readSession({sessionId: currentSession.id});
        const participantsRoster = useRoomStore.getState().joinedParticipants;

        if (!data?.participants?.length || !session?.id) {
            return set({
                ...initialState,
                currentSession,
            });
        }

        if (!session.currentParticipant) {
            return;
        }

        if (!ownerId) {
            ownerId = data.participants.find((participant) => participant.isOwner)?.id;
        }

        const count = getParticipantsCount(data.participants, participantsRoster, session, currentSession.isMemoryMode);

        const searchedSortedParticipants =
            data.participants.length > 50
                ? searchParticipants(data.participants, get().searchValue)
                : sortAllParticipants(searchParticipants(data.participants, get().searchValue));

        const sortedParticipants = data.participants.length > 50 ? data.participants : sortAllParticipants(data.participants);

        const oldState = get();
        const newState: Partial<LocalParticipantsState> = {
            ...count,
        };
        newState.allParticipants = [...sortedParticipants];

        const {
            moderatorsParticipants,
            speakersParticipants,
            waitingToJoinParticipants,
            joinedParticipants,
            notJoinedParticipants,
            invitedParticipants,
        } = splitParticipants(searchedSortedParticipants, participantsRoster, session, currentSession.isLobby, currentSession.isMemoryMode);

        const {
            conferenceInvitedSpeakers,
            conferenceJoinedSpeakers,
            conferencePendingSpeakers,
            conferenceSpeakers,
            joinedParticipants: joinedFullList,
        } = splitParticipants(sortedParticipants, participantsRoster, session, currentSession.isLobby, currentSession.isMemoryMode, true);

        const {conferenceAudience, conferenceSpotlight} = buildConferenceParticipants(
            sortedParticipants,
            participantsRoster,
            session.currentParticipant.pid,
            currentSession.isLobby
        );

        if (!areEqual(oldState.moderators, moderatorsParticipants)) {
            newState.moderators = moderatorsParticipants;
            if (newState.moderators.length !== oldState.moderators.length) {
                newState.moderatorsCount = newState.moderators.length;
            }
        }

        if (!areEqual(oldState.speakers, speakersParticipants)) {
            newState.speakers = speakersParticipants;
        }

        if (!areEqual(oldState.waitingToJoin, waitingToJoinParticipants)) {
            newState.waitingToJoin = waitingToJoinParticipants;
        }

        if (!areEqual(oldState.joined, joinedParticipants)) {
            newState.joined = joinedParticipants;
        }

        if (!areEqual(oldState.notJoined, notJoinedParticipants)) {
            newState.notJoined = notJoinedParticipants;
        }

        if (!areEqual(oldState.invited, invitedParticipants)) {
            newState.invited = invitedParticipants;
        }

        if (!areEqual(oldState.conferenceSpeakers, conferenceSpeakers)) {
            newState.conferenceSpeakers = conferenceSpeakers;
        }

        if (!areEqual(oldState.conferenceInvitedSpeakers, conferenceInvitedSpeakers)) {
            newState.conferenceInvitedSpeakers = conferenceInvitedSpeakers;
        }

        if (!areEqual(oldState.conferenceJoinedSpeakers, conferenceJoinedSpeakers)) {
            newState.conferenceJoinedSpeakers = conferenceJoinedSpeakers;
        }

        if (!areEqual(oldState.conferencePendingSpeakers, conferencePendingSpeakers)) {
            newState.conferencePendingSpeakers = conferencePendingSpeakers;
        }

        if (!areEqual(oldState.joinedFullList, joinedFullList)) {
            newState.joinedFullList = joinedFullList;
        }

        if (!areEqual(oldState.conferenceAudience, conferenceAudience)) {
            newState.conferenceAudience = conferenceAudience;
        }

        if (!areEqual(oldState.conferenceSpotlight, conferenceSpotlight)) {
            newState.conferenceSpotlight = conferenceSpotlight;
        }

        if (oldState.allParticipants.length !== newState.allParticipants.length) {
            newState.totalParticipantsCount = newState.allParticipants.length;
        }

        if (oldState.joinedFullList?.length !== newState.joinedFullList?.length) {
            newState.activeParticipantsCount = newState.joinedFullList?.length;
        }

        newState.participantsMap = newState.allParticipants.reduce((acc, participant) => {
            acc[participant.id] = participant;
            return acc;
        }, {} as Record<string, Participant>);

        if (ownerId) {
            if (oldState.ownerInfo !== newState.participantsMap?.[ownerId]) {
                newState.ownerInfo = newState.participantsMap?.[ownerId];
            }
        }

        newState.currentParticipant = newState.participantsMap[session.currentParticipant.pid];
        newState.controllingParticipantId = newState.allParticipants.find((p) => p.controlStatus === ControlStatus.Controlling)?.id;
        newState.requestingParticipantId = newState.allParticipants.find((p) => p.controlStatus === ControlStatus.Requesting)?.id;

        newState.amIaModerator = newState.currentParticipant?.isOwner || rbac(newState.currentParticipant, "session.isAssistant");

        newState.canEnterSession =
            session.event && session.backstage ? !!(newState.amIaModerator || newState.currentParticipant?.speakerDetails) : true;

        newState.amIAssistant = rbac(newState.currentParticipant, "session.isAssistant");

        set(newState);
    };

    const cleanupStore = () => {
        subscriber?.unsubscribe();
        sessionSubscriber?.unsubscribe();
        ownerId = undefined;
        sessDeps = {};
        set(initialState);
    };

    const setSearchValue = (searchValue: string) => {
        set({searchValue});
        const currentSession = get().currentSession;

        if (currentSession.id) {
            const participantsData = apollo.cache.readQuery({
                query: LocalParticipantsDocument,
                variables: {id: currentSession.id},
                returnPartialData: true,
            });

            if (participantsData) {
                upsertStore(participantsData);
            }
        }
    };

    const isParticipantAssistant = (participantId: string) => {
        return rbac(get().participantsMap[participantId], "session.isAssistant");
    };

    const getMyIndex = (participantId?: string) => {
        if (!participantId) {
            return 0;
        }
        return (
            get()
                .joinedFullList.map((p) => p.id)
                .sort()
                ?.findIndex((id) => id === participantId) ?? 0
        );
    };

    return {
        ...initialState,
        initStore,
        cleanupStore,
        setSearchValue,
        isParticipantAssistant,
        getMyIndex,
    };
});

export function LocalParticipantsProvider() {
    const sessionId = useSessionIdFromRoute();
    const {isLobby} = useIsLobby();
    const hostType = useHostType();
    const {initStore, cleanupStore} = useParticipantsStore();

    const isMemoryMode = hostType === HostType.Memory;

    useEffect(() => {
        if (!sessionId) {
            return () => cleanupStore();
        }

        initStore(sessionId, isLobby, isMemoryMode);
    }, [sessionId, isLobby, isMemoryMode, initStore, cleanupStore]);

    return null;
}

export const useTotalParticipantsCount = () => useParticipantsStore(useShallow((state) => state.totalParticipantsCount));

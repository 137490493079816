import OffersContainer from "./OffersContainer";
import {DrawerState, GetSessionOffersDocument, Offer} from "@generated/data";
import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import {TrackingEventCategory, useMixpanelTracker, useTrackingEvents} from "@workhorse/api/tracking";
import {rbac} from "@workhorse/api/user";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import Inject from "@workhorse/providers/Injector";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useCurrentParticipant, useSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import classes from "./styles/OffersWrapper.module.scss";
import OffersToggler from "./OffersToggler";
import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import OfferNotification from "./components/OfferNotification";
import {useReactiveVar} from "@apollo/client";
import {offerShow} from "@workhorse/pages/player/playerStateVars";
import {DraggableDialog} from "@workhorse/pages/player/components/DraggableDialog";
import apollo from "@workhorse/api/apollo";
import OffersSubscriptions from "./OffersSubscriptions";
import {useQuery} from "@workhorse/dataApi";
import {useUserInfo} from "@workhorse/providers/User";

type OffersWrapperProps = {
    isLobby: boolean;
    isMemoryMode: boolean;
    isMacro: boolean;
};

const OffersWrapper = (props: OffersWrapperProps) => {
    const {isLobby, isMemoryMode = false, isMacro} = props;

    const notInLobby = !isLobby;

    const {isMobile} = useMobile();
    const session = useSession();
    const currentParticipant = useCurrentParticipant();
    const user = useUserInfo();

    const isOwner = currentParticipant.isOwner;
    const isAssistant = rbac(currentParticipant, "session.isAssistant");
    const prevIsAssistant = useRef<boolean>(isAssistant);

    const isOrganizer = isOwner || isAssistant;

    const isMacroHidden = session?.hiddenMacros?.some((x) => x === "offers");

    const [trackEvent] = useTrackingEvents();
    const {mixpanelTrack} = useMixpanelTracker();
    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive, setActiveComponent} = useDrawerRightActiveComponent("offers");

    const {data: sessionOffers, loading: sessionOffersLoading} = useQuery("GetSessionOffersDocument", {
        variables: {
            sessionId: session?.id,
        },
        skip: !session?.id,
    });

    const closeDrawerTimeout = useRef<NodeJS.Timeout | null>(null);
    const showOffer = useReactiveVar(offerShow);
    useEffect(() => {
        // the component may become inactive due to some other one becoming active
        // AFTER we just clicked the toggler
        // meaning, that if some other component becomes active during the 500ms timeout
        // the timeout will execute after, emptying the drawer
        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }

        if (isActive) {
            trackEvent("offers_toggled", {event_category: TrackingEventCategory.PlayerActions});
        }
    }, [isActive, trackEvent]);

    const onToggle = () => {
        const toggleDrawerCond =
            ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) || (isActive && rightDrawerState.isFullyOpen && !isMemoryMode);

        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }

        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent("offers");
        } else if (isActive && !isMemoryMode) {
            setActiveComponent(null);
        }

        if (toggleDrawerCond) {
            toggleRightDrawer();
        }
        if (!isActive && isMemoryMode) {
            mixpanelTrack("frontend-offers", "memory");
        }
    };

    useEffect(() => {
        if (isActive && isMacroHidden) {
            toggleRightDrawer(DrawerState.Closed);
            setActiveComponent(null);
        }
    }, [isMacroHidden, isLobby]);

    useEffect(() => {
        if (prevIsAssistant.current && !isAssistant) {
            toggleRightDrawer(DrawerState.Closed);
            setActiveComponent(null);
        }

        prevIsAssistant.current = isAssistant;
    }, [isAssistant]);

    const shouldDisplayOffer = !!showOffer?.offer?.id && session?.id === showOffer?.offer?.sessionId && !user?.isRecorder;

    return (
        <Fragment key="offers-macro">
            <OffersSubscriptions isMemoryMode={isMemoryMode} sessionId={session?.id} />
            {!isMacroHidden && (session.event ? !isLobby : true) && (
                <Inject target="inPlayer" id="player-offer-show-container" key="player-offer-show-container">
                    {shouldDisplayOffer ? (
                        <DraggableDialog
                            id="draggable-spotlight"
                            width={0}
                            height={0}
                            defaultPosition={"top-center"}
                            className="min-width-max-content"
                            enableResizing={false}
                            isSpeakerWidget={false}
                        >
                            <OfferNotification offer={showOffer?.offer} isMobile={isMobile} />
                        </DraggableDialog>
                    ) : null}
                </Inject>
            )}

            <Inject target="inDrawerRight" className={isActive && isMacro ? classes.root : undefined} id="player-offers-drawer-right">
                {isActive ? (
                    <OffersContainer
                        isMacro={isMacro}
                        isOrganizer={isOrganizer}
                        isMemoryMode={isMemoryMode}
                        isMobile={isMobile}
                        userId={user?.id}
                    />
                ) : null}
            </Inject>
            {!isMacroHidden && (
                <Inject target="inFooterRight" id="macro-offers-toggler">
                    <OffersToggler
                        artifactId={"offers"}
                        notInLobby={notInLobby}
                        onToggle={onToggle}
                        isActive={isActive}
                        inDrawer={false}
                        isMemoryMode={isMemoryMode}
                        session={session}
                        isOrganizer={isOrganizer}
                        isMacroHidden={isMacroHidden}
                    />
                </Inject>
            )}
        </Fragment>
    );
};

export default OffersWrapper;

import MenuItem from "@ui/cdk/MenuItem";
import {cls} from "@ui/cdk/util";
import Menu from "@ui/core/components/Menu";
import {forwardRef, useEffect, useState} from "@workhorse/api/rendering";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import classes from "./styles/ReactionsToggler.module.scss";
import {ReactComponent as ReactionsIcon} from "../../../../../assets/media/reactions-toggler.svg";
import RaiseHandTogglerOption from "./components/RaiseHandTogglerOption";
import menuClasses from "../styles/LocalParticipantControlMenu.module.scss";
import {getReactionsList} from "../../Reactions/api";
import {useMyParticipant, useSession} from "@workhorse/providers/SessionDataProviders";
import Typography from "@ui/cdk/Typography";
import Button from "@ui/cdk/Button";
import shouldDisableFlag from "@workhorse/api/session-settings/utils/shouldDisableFlag";
import {SessionFlagsType} from "@generated/data";
import {useTranslation} from "react-i18next";

type ReactionsToggleProps = {
    className?: string;
    showTooltip?: (e?: any, message?: string) => void;
    onClick?: () => void;
    hideText?: boolean;
};

const ReactionsToggler = forwardRef((props: ReactionsToggleProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {showTooltip, className, onClick, hideText} = props;
    const currentParticipant = useMyParticipant();
    const {enableReactionsType} = useSession();
    const disabled = shouldDisableFlag(currentParticipant, enableReactionsType as SessionFlagsType);

    const [anchorEl, setAnchorEl] = useState<HTMLLIElement | HTMLButtonElement | null>(null);

    const {isMobile} = useMobile();
    const {isPortrait} = useDeviceOrientation();

    useEffect(() => {
        if (!isPortrait && isMobile && anchorEl) {
            setAnchorEl(null);
        }
    }, [isPortrait, isMobile, anchorEl]);

    const handleToggleMenu = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            onClick?.();
            setAnchorEl(event.currentTarget);
        }
    };

    const onMouseEnter = (e: any) => {
        showTooltip?.(e, t("participant.reaction.title") ?? "Reactions");
    };

    const onMouseLeave = () => {
        showTooltip?.();
    };

    return (
        <>
            <MenuItem
                button
                ref={ref}
                className={cls(className, classes.toggler, anchorEl && classes.togglerOpen)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={handleToggleMenu}
                disabled={disabled}
                role="button"
                aria-pressed={Boolean(anchorEl)}
                aria-label={t("participant.reaction.title") ?? "Reactions"}
            >
                {!hideText && (
                    <Typography color="nonary" fontWeight="bold" className="mr-4">
                        {t("participant.reaction.title")}
                    </Typography>
                )}
                <ReactionsIcon />
            </MenuItem>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleToggleMenu}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                classes={{
                    paper: cls(menuClasses.tooltip, classes.paper),
                    list: cls(menuClasses.menuList, classes.list),
                }}
                BackdropProps={{
                    className: classes.backdrop,
                }}
                aria-hidden={!anchorEl}
            >
                {getReactionsList()}
                <li className="separator" key="reactions-separator" />
                <RaiseHandTogglerOption key="reactions-raise-hand-toggler" />
                {isMobile && (
                    <Button component="li" className={classes.closeBtn} onClick={handleToggleMenu} key="reactions-close-button">
                        {t("g.close")}
                    </Button>
                )}
            </Menu>
        </>
    );
});

export default ReactionsToggler;

import {DrawerState, ParticipantsMacroAccess} from "@generated/data";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useEffect, useRef} from "@workhorse/api/rendering";
import {TrackingEventCategory, useTrackingEvents} from "@workhorse/api/tracking";
import {rbac} from "@workhorse/api/user";
import {ParticipantMacroArtifactProps} from "@workhorse/declarations";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import Inject from "@workhorse/providers/Injector";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {useShallow} from "zustand/react/shallow";
import ParticipantsToggler from "../ParticipantsToggler";
import ParticipantsContainerParent from "./ParticipantsContainer";
import classes from "./styles/ParticipantsWrapper.module.scss";

type ParticipantsWrapperProps = Omit<ParticipantMacroArtifactProps, "artifact">;

function ParticipantsWrapper(props: ParticipantsWrapperProps) {
    const {isLobby, isMemoryMode = false} = props;
    const notInLobby = !isLobby;

    const {isMobile} = useMobile();
    const session = useSession();
    const currentParticipant = useParticipantsStore(
        useShallow(({currentParticipant}) => ({
            id: currentParticipant?.id ?? "",
            isOwner: currentParticipant?.isOwner ?? false,
            isSpeaker: !!currentParticipant?.speakerDetails?.id,
        }))
    );

    const isOwner = currentParticipant.isOwner;
    const isAssistant = rbac(currentParticipant.id, "session.isAssistant");
    const prevIsAssistant = useRef<boolean>(isAssistant);

    const isOrganizer = isOwner || isAssistant;

    const isSpeaker = currentParticipant.isSpeaker;

    const isMacroHidden = session.participantsMacroAccess === ParticipantsMacroAccess.Disabled && !isSpeaker && !isOrganizer;

    const isCountHidden = session.participantsMacroAccess === ParticipantsMacroAccess.Moderators && !isSpeaker && !isOrganizer;

    const [trackEvent] = useTrackingEvents();

    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive, setActiveComponent} = useDrawerRightActiveComponent("participants");

    const closeDrawerTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // the component may become inactive due to some other one becoming active
        // AFTER we just clicked the toggler
        // meaning, that if some other component becomes active during the 500ms timeout
        // the timeout will execute after, emptying the drawer
        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }

        if (isActive) {
            trackEvent("participants_toggled", {event_category: TrackingEventCategory.PlayerActions});
        }
    }, [isActive, trackEvent]);

    const onToggle = () => {
        const toggleDrawerCond =
            ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) || (isActive && rightDrawerState.isFullyOpen && !isMemoryMode);

        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }

        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent("participants");
        } else if (isActive && !isMemoryMode) {
            setActiveComponent(null);
        }

        if (toggleDrawerCond) {
            toggleRightDrawer();
        }
    };

    useEffect(() => {
        if (isActive && isMacroHidden) {
            toggleRightDrawer(DrawerState.Closed);
            setActiveComponent(null);
        }
    }, [isMacroHidden, isLobby]);

    useEffect(() => {
        if (prevIsAssistant.current && !isAssistant) {
            toggleRightDrawer(DrawerState.Closed);
            setActiveComponent(null);
        }

        prevIsAssistant.current = isAssistant;
    }, [isAssistant]);

    return (
        <>
            <Inject target="inDrawerRight" className={isActive ? classes.root : undefined} id="player-participants-drawer-right">
                {isActive && !isMacroHidden ? <ParticipantsContainerParent {...props} /> : null}
            </Inject>

            {!isMacroHidden && (
                <Inject target="inFooterRight" id="macro-participants-toggler">
                    <ParticipantsToggler
                        artifactId={"participants"}
                        notInLobby={notInLobby}
                        onToggle={onToggle}
                        isActive={isActive}
                        inDrawer={false}
                        isMemoryMode={isMemoryMode}
                        isOrganizer={isOrganizer}
                        isRoom={!!session.room?.id}
                        isCountHidden={isCountHidden}
                    />
                </Inject>
            )}
        </>
    );
}

export default ParticipantsWrapper;

import {
    MemoryAccessType,
    MemoryListSessionFragment,
    MemorySessionRecordingFragment,
    MemorySessionRecordingFragmentDoc,
    SessionFilterEnum,
    SessionFlagsType,
    SessionOrderByInput,
    SortOrder,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";

export const FIRST_PAGE = 0;

// Persisted only in memory context
export enum TempLocalStorageParams {
    currentPage = "memory_current_page",
    orderBy = "memory_order_by",
    searchTerm = "memory_search_term",
}

// Persisted generally
export enum MemoryLocalStorageParams {
    owner = "memory_owner",
    invited = "memory_invited",
    sessions = "memory_filter_sessions",
    attended = "memory_filter_attended",
    declined = "memory_filter_declined",
    withRecording = "memory_filter_withRecording",
    withoutRecording = "memory_filter_withoutRecording",
    participantsEmails = "memory_filter_participants_emails",
    resources = "memory_filter_resources",
    interval = "memory_interval",
}

export type SessionsFilters = {
    owner: boolean;
    invited: boolean;
    sessions: SessionFilterEnum;
    attended: boolean;
    declined: boolean;
    withRecording: boolean;
    withoutRecording: boolean;
    participantsEmails?: string[];
};

export type MemorySessionsListOrderBy = {
    dbSort: SessionOrderByInput[];
    participantsCount?: SortOrder;
    participantStatus?: SortOrder;
    recording?: SortOrder;
};

export const defaultFilters: SessionsFilters = {
    owner: true,
    invited: true,
    sessions: SessionFilterEnum.All,
    attended: true,
    declined: true,
    withRecording: true,
    withoutRecording: true,
    participantsEmails: [],
};

export function getSessionRecordings(recordingIds: string[]) {
    const recordings: MemorySessionRecordingFragment[] = [];

    for (const recordingId of recordingIds) {
        const data = apollo.cache.readFragment({
            fragment: MemorySessionRecordingFragmentDoc,
            fragmentName: "MemorySessionRecording",
            id: apollo.cache.identify({__ref: recordingId}),
        });
        if (data?.id) {
            recordings.push(data);
        }
    }

    return recordings.slice().sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
        });
    });
}

export const memoryAccessTypeOptions = [
    {value: MemoryAccessType.Private, label: "Moderators"},
    {value: MemoryAccessType.JustAttendees, label: "Attendees"},
    {value: MemoryAccessType.Public, label: "Anyone with the link"},
];

export const hideAgendaOptions = [
    {value: SessionFlagsType.Hosts, label: "Moderators"},
    {value: SessionFlagsType.Speakers, label: "Moderators and speakers"},
    {value: SessionFlagsType.Everyone, label: "Everyone"},
];

export const getTypeDisplayName = (session: MemoryListSessionFragment) => {
    if (session.room) {
        return "Room";
    } else if (session.event) {
        return "Event";
    } else if (session.isBooking) {
        return "Booking";
    } else if (session.quickSession) {
        return "Instant";
    } else {
        return "Planned";
    }
};

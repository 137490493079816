import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@ui/core/components/Popover";
import {useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import {cls} from "@ui/cdk/util/util";
import {ToolbarGenericProps, useClickOutside} from "../api";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";
import type {HeadingLevel} from "../api";
import {useTranslation} from "react-i18next";

const HEADINGS = {
    h1: {level: 1, text: "Heading 1"},
    h2: {level: 2, text: "Heading 2"},
    h3: {level: 3, text: "Heading 3"},
    h4: {level: 4, text: "Heading 4"},
    h5: {level: 5, text: "Heading 5"},
    h6: {level: 6, text: "Heading 6"},
};

export const ToolbarHeadings = (props: ToolbarGenericProps) => {
    const {t} = useTranslation();
    const {editor, onActionDone} = props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const [currentHeading, setCurrentHeading] = useState(null);

    const ref = useRef<HTMLButtonElement>(null);

    const onEditorUpdate = useCallback(() => {
        const level = editor.getAttributes("heading")?.level;
        setCurrentHeading(HEADINGS[`h${level}`]?.text ?? null);
    }, [setCurrentHeading]);

    useEffect(() => {
        editor.on("update", onEditorUpdate);

        return () => {
            editor.off("update", onEditorUpdate);
        };
    }, []);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (event.target && !ref.current?.contains(event.target as Node) && anchorEl) {
                setAnchorEl(undefined);
            }
        },
        [anchorEl]
    );

    useClickOutside("click", handleClickOutside);

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleSetHeading = useCallback(
        (level: HeadingLevel) => {
            editor.chain().focus().toggleHeading({level}).run();
            onActionDone();
        },
        [editor, onActionDone]
    );

    const handleClose = useCallback(
        (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            if (reason === "backdropClick") {
                return;
            }
            setAnchorEl(undefined);
            onActionDone();
        },
        [onActionDone]
    );

    return (
        <>
            <ToolbarItem
                tooltip="Headings"
                className={classes.button}
                onClick={handleOpenMenu}
                disabled={!editor.isEditable}
                isMobile={props.isMobile}
                ref={ref}
            >
                {currentHeading ? <span>{currentHeading}</span> : <span>{t("g.text_editor.normal_text")}</span>}
                <ArrowDropDownIcon className={cls(classes.buttonIcon, anchorEl && classes.buttonIconActive)} />
            </ToolbarItem>

            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
            >
                <div className={classes.verticalList}>
                    <button
                        className={cls(classes.button, editor.isActive("paragraph") && classes.buttonActive)}
                        onClick={() => editor.chain().focus().setParagraph().run()}
                    >
                        {t("g.text_editor.normal_text")}
                    </button>

                    {Object.entries(HEADINGS).map(([heading, {level, text}]) => (
                        <button
                            key={heading}
                            className={cls(classes.button, editor.isActive("heading", {level}) && classes.buttonActive)}
                            onClick={() => handleSetHeading(level as HeadingLevel)}
                        >
                            {text}
                        </button>
                    ))}
                </div>
            </Popover>
        </>
    );
};

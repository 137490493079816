import {useLazyQuery} from "@apollo/client";
import {
    GetOrganizationMembersDocument,
    OrganizationPermissionType,
    PaddleSubscriptionDetailsDocument,
    RemoteUserFragmentDoc,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import {addCalendarEvent, evictCalendarEventsFromCache} from "@workhorse/api/calendar";
import {updateCachedRemoteUser} from "@workhorse/api/user";
import GenericSubscriber, {SubscriptionPayload} from "@workhorse/components/GenericSubscriber";
import {useUserInfo} from "./User";

export default function GlobalNotificationsProvider(): JSX.Element {
    const user = useUserInfo();

    const [getPaddleSubscriptionDetails] = useLazyQuery(PaddleSubscriptionDetailsDocument, {
        fetchPolicy: "network-only",
    });

    if (!user.id) {
        return null as unknown as JSX.Element;
    }

    const plan = user.activeOrganizationPermission.organization.plan;
    const isOrganizationOwner = user.activeOrganizationPermission.permissionType === OrganizationPermissionType.Owner;

    const onSubscriptionData = async (payload: SubscriptionPayload<"GlobalNotificationsDocument">) => {
        if (payload.globalNotifications == null) {
            return;
        }

        if (payload.globalNotifications.calendar) {
            console.log("CALENDAR update", payload.globalNotifications.calendar);

            const {deleteSessionId, upsertSession} = payload.globalNotifications.calendar;

            if (!deleteSessionId && !upsertSession) {
                return;
            }

            if (deleteSessionId) {
                evictCalendarEventsFromCache(deleteSessionId);
            } else if (upsertSession) {
                addCalendarEvent(upsertSession);
            }
        } else if (
            payload.globalNotifications.workspacePermissionsUpdate &&
            payload.globalNotifications.workspacePermissionsUpdate.length > 0
        ) {
            const newPermissions = payload.globalNotifications.workspacePermissionsUpdate;
            const removedPermissions = user.workspacePermissions.filter(
                (p) => payload.globalNotifications.workspacePermissionsUpdate?.find((x) => x.id === p.id) == null
            );

            const currentUser = apollo.client.readFragment({
                fragment: RemoteUserFragmentDoc,
                fragmentName: "RemoteUser",
                id: apollo.cache.identify({__typename: "User", ...user}),
            });

            if (
                payload.globalNotifications.organizationUpdate?.plan !== plan &&
                !user.activeOrganizationPermission.organization.reverseTrialCancellationDate &&
                isOrganizationOwner
            ) {
                getPaddleSubscriptionDetails();
            }

            if (currentUser && newPermissions.length) {
                apollo.client.writeFragment({
                    fragment: RemoteUserFragmentDoc,
                    fragmentName: "RemoteUser",
                    id: apollo.cache.identify({__typename: "User", ...user}),
                    data: {
                        ...currentUser,
                        workspacePermissions: newPermissions,
                    },
                });

                // biome-ignore lint/complexity/noForEach: <explanation>
                removedPermissions.forEach((p) => {
                    apollo.cache.evict({
                        id: apollo.cache.identify(p.workspace),
                    });
                    apollo.cache.evict({
                        id: apollo.cache.identify(p.role),
                    });
                    apollo.cache.evict({
                        id: apollo.cache.identify(p),
                    });
                });
            }
        } else if (payload.globalNotifications?.organizationUpdate) {
            if (
                user.organizationPermissions.find((p) => p.organization.id === payload.globalNotifications.organizationUpdate!.id)
                    ?.permissionType === OrganizationPermissionType.Owner
            ) {
                await apollo.client.query({
                    query: GetOrganizationMembersDocument,
                    variables: {
                        organizationId: payload?.globalNotifications?.organizationUpdate.id,
                        excludeSelf: true,
                    },
                });
            }
        } else if (payload.globalNotifications.userUpdate) {
            // console.log("debugger:userUpdate:globalNotification", payload?.globalNotifications.userUpdate);

            updateCachedRemoteUser({
                user: {
                    ...payload.globalNotifications.userUpdate,
                },
            });
        } else if (
            payload.globalNotifications.organizationPermissionsUpdate &&
            payload.globalNotifications.organizationPermissionsUpdate.length > 0
        ) {
            const newPermissions = payload.globalNotifications.organizationPermissionsUpdate;
            const removedPermissions = user.organizationPermissions.filter(
                (p) => payload.globalNotifications.organizationPermissionsUpdate?.find((x) => x.id === p.id) == null
            );

            if (removedPermissions.find((p) => p.activeOrganization)) {
                window.open("/", "_self");
            }

            const currentUser = apollo.client.readFragment({
                fragment: RemoteUserFragmentDoc,
                fragmentName: "RemoteUser",
                id: apollo.cache.identify({__typename: "User", ...user}),
            });

            if (currentUser && newPermissions.length) {
                apollo.client.writeFragment({
                    fragment: RemoteUserFragmentDoc,
                    fragmentName: "RemoteUser",
                    id: apollo.cache.identify({__typename: "User", ...user}),
                    data: {
                        ...currentUser,
                        organizationPermissions: newPermissions,
                    },
                });

                for (const removedPermission of removedPermissions) {
                    apollo.cache.evict({
                        id: apollo.cache.identify(removedPermission.organization),
                    });
                    apollo.cache.evict({
                        id: apollo.cache.identify(removedPermission),
                    });
                }
            }

            // const currentUser = apollo.client.readFragment({
            //     fragment: RemoteUserFragmentDoc,
            //     fragmentName: "RemoteUser",
            //     id: apollo.cache.identify({__typename: "User", ...user}),
            // });

            // if (
            //     currentUser &&
            //     !currentUser.organizationPermissions.find((p) => p.id === payload.globalNotifications.organizationPermissionsUpdate?.id)
            // ) {
            //     // If the permission is not in the cache, we don't need to update the cache
            //     apollo.client.writeFragment({
            //         fragment: RemoteUserFragmentDoc,
            //         fragmentName: "RemoteUser",
            //         id: apollo.cache.identify({__typename: "User", ...user}),
            //         data: {
            //             ...currentUser,
            //             organizationPermissions: [
            //                 ...currentUser.organizationPermissions,
            //                 payload.globalNotifications.organizationPermissionUpdate,
            //             ],
            //         },
            //     });
            // }
        }
    };

    return (
        <GenericSubscriber
            document="GlobalNotificationsDocument"
            onData={onSubscriptionData}
            options={{
                shouldResubscribe: true,
                skip: !user.id || user.id === "mocked",
            }}
        />
    );
}

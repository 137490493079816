// Generated content - manual changes WILL be overwritten
export type AgendaItemFieldKeys =
    | "createdAt"
    | "descriptionJson"
    | "duration"
    | "endedAt"
    | "newId"
    | "isPaused"
    | "locked"
    | "order"
    | "processing"
    | "speakerNotes"
    | "speakerNotesJson"
    | "startAt"
    | "timeSpentInSeconds"
    | "title"
    | "type"
    | "updatedAt"
    | "userConfiguredDuration"
    | "isDeleted";
export type AgendaItemSpeakerFieldKeys = "agendaItemId" | "createdAt" | "newId" | "speakerId" | "updatedAt" | "isDeleted";
export type ArtifactFieldKeys =
    | "artifactId"
    | "createdAt"
    | "deniedSpeakerUserIds"
    | "description"
    | "durationInSeconds"
    | "endedAt"
    | "newId"
    | "isConfigured"
    | "isMacro"
    | "isSystemArtifact"
    | "name"
    | "order"
    | "startAt"
    | "updatedAt"
    | "userConfiguredDuration"
    | "isDeleted";
export type OfferFieldKeys =
    | "createdAt"
    | "ctaText"
    | "ctaUrl"
    | "description"
    | "newId"
    | "imageUrl"
    | "sessionId"
    | "title"
    | "updatedAt"
    | "userId"
    | "isDeleted";
export type PropertyFieldKeys = "createdAt" | "newId" | "key" | "updatedAt" | "value" | "isDeleted";
export type RecurrenceDataFieldKeys =
    | "byDay"
    | "byMonth"
    | "byMonthDay"
    | "bySetPos"
    | "count"
    | "createdAt"
    | "dtStart"
    | "exclude"
    | "frequency"
    | "newId"
    | "instancesOccurrenceIds"
    | "interval"
    | "sessionId"
    | "until"
    | "updatedAt"
    | "isDeleted";
export type ResourceResultFieldKeys =
    | "content"
    | "createdAt"
    | "newId"
    | "resourceContentSnapshot"
    | "resourceId"
    | "type"
    | "updatedAt"
    | "isDeleted";
export type RoomFieldKeys = "active" | "activeSessionId" | "createdAt" | "newId" | "sessionTemplateId" | "slug" | "updatedAt" | "isDeleted";
export type SessionFieldKeys =
    | "actualStart"
    | "allowAgendaType"
    | "allowCameraType"
    | "allowCollaborationInLobby"
    | "allowMicrophoneType"
    | "allowScreenShareType"
    | "askForGuestEmail"
    | "autoRecording"
    | "autoStartSession"
    | "autoTranscribing"
    | "backstage"
    | "bypassSessionPasscode"
    | "collaborativeSession"
    | "collectFeedback"
    | "createdAt"
    | "createdFromTemplateId"
    | "createdFromYourFirstSessionTemplate"
    | "description"
    | "disabledNotifications"
    | "enableReactionsType"
    | "endByWorker"
    | "endedAt"
    | "exitPageOfferId"
    | "feedbackFormAnswers"
    | "feedbackFormId"
    | "fullSizeVideosInRecording"
    | "groupChatAccess"
    | "hiddenMacros"
    | "hideIcsGuestList"
    | "hideNonStreamingParticipants"
    | "newId"
    | "isBooking"
    | "isPrivate"
    | "lifecycle"
    | "livestreamOutputUrls"
    | "lobbyAccess"
    | "memoryAccessType"
    | "messageReminders"
    | "name"
    | "occurrenceId"
    | "onboardingType"
    | "order"
    | "ownerHalfDayClock"
    | "ownerLanguage"
    | "participantsMacroAccess"
    | "passcodeToJoin"
    | "plannedEnd"
    | "privateChatAccess"
    | "provideSessionPasscode"
    | "quickSession"
    | "recordingConferenceView"
    | "recordingPresentationView"
    | "recordingType"
    | "recurrenceDataId"
    | "reminderInMinutes"
    | "reminders"
    | "requestPermissionToJoin"
    | "requireConsentToRecord"
    | "roomId"
    | "sendEmailsAutomatically"
    | "sendEndSessionEmail"
    | "shareScreenPid"
    | "showAgendaInLobby"
    | "showExitPageCta"
    | "source"
    | "speakerUserIds"
    | "startAt"
    | "syncToAgenda"
    | "timeDependency"
    | "timeZone"
    | "timerCurrentDuration"
    | "timerInitialDuration"
    | "timerStartAt"
    | "timerState"
    | "transcriptionActive"
    | "updatedAt"
    | "view"
    | "workspaceId"
    | "isDeleted";
export type SessionEventFieldKeys =
    | "afterRegistrationOfferId"
    | "afterRegistrationRedirectUrl"
    | "bannerUrl"
    | "colorPalette"
    | "createdAt"
    | "embedUrl"
    | "hasRegistration"
    | "newId"
    | "isPublic"
    | "language"
    | "maxSeats"
    | "photoUrl"
    | "registrationAnswers"
    | "registrationForm"
    | "requireApproval"
    | "seatsTaken"
    | "sessionId"
    | "slug"
    | "socialLinks"
    | "speakerOrderJson"
    | "state"
    | "updatedAt"
    | "utm"
    | "isDeleted"
    | "language";
export type ModelsWithFieldResolver = {
    AgendaItem: Array<AgendaItemFieldKeys>;
    AgendaItemSpeaker: Array<AgendaItemSpeakerFieldKeys>;
    Artifact: Array<ArtifactFieldKeys>;
    Offer: Array<OfferFieldKeys>;
    Property: Array<PropertyFieldKeys>;
    RecurrenceData: Array<RecurrenceDataFieldKeys>;
    ResourceResult: Array<ResourceResultFieldKeys>;
    Room: Array<RoomFieldKeys>;
    Session: Array<SessionFieldKeys>;
    SessionEvent: Array<SessionEventFieldKeys>;
};
export const modelsWithFieldResolver: ModelsWithFieldResolver = {
    AgendaItem: [
        "createdAt",
        "descriptionJson",
        "duration",
        "endedAt",
        "newId",
        "isPaused",
        "locked",
        "order",
        "processing",
        "speakerNotes",
        "speakerNotesJson",
        "startAt",
        "timeSpentInSeconds",
        "title",
        "type",
        "updatedAt",
        "userConfiguredDuration",
        "isDeleted",
    ],
    AgendaItemSpeaker: ["agendaItemId", "createdAt", "newId", "speakerId", "updatedAt", "isDeleted"],
    Artifact: [
        "artifactId",
        "createdAt",
        "deniedSpeakerUserIds",
        "description",
        "durationInSeconds",
        "endedAt",
        "newId",
        "isConfigured",
        "isMacro",
        "isSystemArtifact",
        "name",
        "order",
        "startAt",
        "updatedAt",
        "userConfiguredDuration",
        "isDeleted",
    ],
    Offer: ["createdAt", "ctaText", "ctaUrl", "description", "newId", "imageUrl", "sessionId", "title", "updatedAt", "userId", "isDeleted"],
    Property: ["createdAt", "newId", "key", "updatedAt", "value", "isDeleted"],
    RecurrenceData: [
        "byDay",
        "byMonth",
        "byMonthDay",
        "bySetPos",
        "count",
        "createdAt",
        "dtStart",
        "exclude",
        "frequency",
        "newId",
        "instancesOccurrenceIds",
        "interval",
        "sessionId",
        "until",
        "updatedAt",
        "isDeleted",
    ],
    ResourceResult: ["content", "createdAt", "newId", "resourceContentSnapshot", "resourceId", "type", "updatedAt", "isDeleted"],
    Room: ["active", "activeSessionId", "createdAt", "newId", "sessionTemplateId", "slug", "updatedAt", "isDeleted"],
    Session: [
        "actualStart",
        "allowAgendaType",
        "allowCameraType",
        "allowCollaborationInLobby",
        "allowMicrophoneType",
        "allowScreenShareType",
        "askForGuestEmail",
        "autoRecording",
        "autoStartSession",
        "autoTranscribing",
        "backstage",
        "bypassSessionPasscode",
        "collaborativeSession",
        "collectFeedback",
        "createdAt",
        "createdFromTemplateId",
        "createdFromYourFirstSessionTemplate",
        "description",
        "disabledNotifications",
        "enableReactionsType",
        "endByWorker",
        "endedAt",
        "exitPageOfferId",
        "feedbackFormAnswers",
        "feedbackFormId",
        "fullSizeVideosInRecording",
        "groupChatAccess",
        "hiddenMacros",
        "hideIcsGuestList",
        "hideNonStreamingParticipants",
        "newId",
        "isBooking",
        "isPrivate",
        "lifecycle",
        "livestreamOutputUrls",
        "lobbyAccess",
        "memoryAccessType",
        "messageReminders",
        "name",
        "occurrenceId",
        "onboardingType",
        "order",
        "ownerHalfDayClock",
        "ownerLanguage",
        "participantsMacroAccess",
        "passcodeToJoin",
        "plannedEnd",
        "privateChatAccess",
        "provideSessionPasscode",
        "quickSession",
        "recordingConferenceView",
        "recordingPresentationView",
        "recordingType",
        "recurrenceDataId",
        "reminderInMinutes",
        "reminders",
        "requestPermissionToJoin",
        "requireConsentToRecord",
        "roomId",
        "sendEmailsAutomatically",
        "sendEndSessionEmail",
        "shareScreenPid",
        "showAgendaInLobby",
        "showExitPageCta",
        "source",
        "speakerUserIds",
        "startAt",
        "syncToAgenda",
        "timeDependency",
        "timeZone",
        "timerCurrentDuration",
        "timerInitialDuration",
        "timerStartAt",
        "timerState",
        "transcriptionActive",
        "updatedAt",
        "view",
        "workspaceId",
        "isDeleted",
    ],
    SessionEvent: [
        "afterRegistrationOfferId",
        "afterRegistrationRedirectUrl",
        "bannerUrl",
        "colorPalette",
        "createdAt",
        "embedUrl",
        "hasRegistration",
        "newId",
        "isPublic",
        "language",
        "maxSeats",
        "photoUrl",
        "registrationAnswers",
        "registrationForm",
        "requireApproval",
        "seatsTaken",
        "sessionId",
        "slug",
        "socialLinks",
        "speakerOrderJson",
        "state",
        "updatedAt",
        "utm",
        "isDeleted",
        "language",
    ],
};

import {PublicBookingEventFragment} from "@generated/data";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import CreditCardIcon from "@material-ui/icons/CreditCardRounded";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import TimezoneIcon from "@material-ui/icons/VpnLock";
import {useMemo, useRef, useState} from "react";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";
import {currencies} from "@sessions/currencies";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import {getTimezoneDisplayName} from "@common/utils/timezones";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import ProfilePicture from "@workhorse/pages/user/profile/ui/ProfilePicture";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {HyperlinkExternal} from "@workhorse/util/links";
import {addSeconds, format, subSeconds} from "date-fns";
import {getTimezoneOffset} from "date-fns-tz";
import {AcceptedTimezone, getLocalTimezone} from "@common/utils/timezones";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import {BookTimeZone} from "./BookTimeZones";
import Popover from "@ui/core/components/Popover";
import {Trans, useTranslation} from "react-i18next";
import classes from "../styles/BookEventDateTime.module.scss";
import {getDateFnsLocale, localeWeekdayOfMonthDateFnsFormat} from "@sessions/common/utils/languages";

type BookDetailsProps = {
    event: PublicBookingEventFragment;
    duration?: number;
    timezone: AcceptedTimezone;
    date?: Date;
    wrap?: boolean;
    isHalfDayClock?: boolean;
    setIsHalfDayClock?: (state: boolean) => void;
    onChangeTimeZone?: (timeZone: string) => void;
};
const BookDetails = (props: BookDetailsProps) => {
    const {event, duration, timezone: acceptedTimezone, date, wrap, isHalfDayClock, setIsHalfDayClock, onChangeTimeZone} = props;
    const {isMobile} = useMobile();
    const {t, i18n} = useTranslation();
    const lang = i18n.language;
    const halfDayClock = isHalfDayClock;
    const [open, setOpen] = useState(false);
    const timeZoneRef = useRef<HTMLDivElement>(null);

    const organizationPlan = event.organizationPlan ?? undefined;

    const {featureIsAvailable} = useFeatureInfo({
        feature: LimitedFeature.WHITE_LABELING,
        plan: organizationPlan,
    });

    const offset = (getTimezoneOffset(getLocalTimezone()) - getTimezoneOffset(acceptedTimezone)) / 1000;

    const fee = useMemo(() => {
        if (!event.payments?.length) {
            return null;
        }
        const paymentConfig = event.payments[0];
        const currency = currencies.find((c) => c.value === paymentConfig.currency);

        return `${currency?.symbol ?? ""}${(paymentConfig.amount / 100).toFixed(2)} ${paymentConfig.currency}`.trim();
    }, [event.payments]);

    const {day, time, timezone} = useMemo(() => {
        const selectedDate = date ?? new Date();

        return {
            day: format(subSeconds(selectedDate, offset), localeWeekdayOfMonthDateFnsFormat[lang], {
                locale: getDateFnsLocale(lang),
            }),
            time: `${format(subSeconds(selectedDate, offset), halfDayClock ? "hh:mm" : "HH:mm")} - ${format(
                addSeconds(selectedDate, (duration ?? event.duration) - offset),
                halfDayClock ? "hh:mm a" : "HH:mm"
            )}`,
            timezone: getTimezoneDisplayName(selectedDate, acceptedTimezone),
        };
    }, [date, acceptedTimezone, offset, halfDayClock, duration, lang]);

    const handleOnToggleTimeZonePopover = () => {
        setOpen((o) => !o);
    };

    const handleOnChangeTimeZone = (newTimeZone: AcceptedTimezone) => {
        onChangeTimeZone?.(newTimeZone);
        setOpen((o) => !o);
    };

    return (
        <div className={classes.detailsWrapper}>
            {!featureIsAvailable && (
                <div className={classes.disclaimer}>
                    <VideocamRoundedIcon />
                    <Typography color="octonary" fontWeight="bolder">
                        <Trans i18nKey="booking.page.event_will_be_held_on">
                            The event will be held on
                            <HyperlinkExternal target="_blank" href="https://sessions.us/" rel="noreferrer">
                                Sessions
                            </HyperlinkExternal>
                        </Trans>
                        .
                    </Typography>
                </div>
            )}

            <div data-id="owner-details" className={cls(classes.detailsItem, classes.detailsUser)}>
                <ProfilePicture
                    firstName={event.ownerFirstName}
                    lastName={event.ownerLastName}
                    avatar={event.ownerProfilePicture}
                    avatarSize="S"
                    avatarVariant="rounded"
                    disableTooltip
                />
                <div className={classes.detailsUserInfo}>
                    <span className={classes.detailsUserName} data-id="owner-name">
                        {event.ownerFirstName} {event.ownerLastName}
                    </span>
                </div>
            </div>

            <div data-id="booking-duration" className={cls(classes.detailsItem, classes.detailsTime)}>
                <span className={classes.detailsTimeIcon}>
                    <AccessTimeIcon htmlColor="#475467" />
                </span>
                <div className={classes.detailsUserInfo}>
                    <span className={classes.detailsUserName}>
                        {(duration ?? event.duration) / 60} {t("g.time.minutes")}
                    </span>
                </div>
            </div>
            {fee ? (
                <div className={cls(classes.detailsItem, classes.detailsFee)}>
                    <span className={classes.detailsTimeIcon}>
                        <CreditCardIcon htmlColor="#475467" />
                    </span>
                    <div className={classes.detailsUserInfo}>
                        <span className={classes.detailsUserName}>{fee}</span>
                    </div>
                </div>
            ) : null}
            {date ? (
                <>
                    <div className={cls(classes.detailsItem, classes.detailsFee)}>
                        <span className={classes.detailsTimeIcon}>
                            <CalendarIcon htmlColor="#475467" />
                        </span>
                        <div className={classes.detailsUserInfo}>
                            <span className={classes.detailsUserName}>
                                {isMobile ? (
                                    <>
                                        <span>{day}</span>
                                        <span>{time}</span>
                                    </>
                                ) : (
                                    `${day}, ${time}`
                                )}
                            </span>
                        </div>
                    </div>
                    <div className={cls(classes.detailsItem, wrap ? classes.detailsItemTimezone : undefined)}>
                        <span className={classes.detailsTimeIcon}>
                            <TimezoneIcon htmlColor="#475467" />
                        </span>
                        <div className={classes.detailsUserInfo}>
                            <span className={classes.detailsUserName}>{timezone}</span>
                        </div>
                    </div>
                </>
            ) : (
                <div className={cls(classes.detailsItem, wrap ? classes.detailsItemTimezone : undefined)}>
                    <span className={classes.detailsTimeIcon}>
                        <TimezoneIcon htmlColor="#475467" />
                    </span>
                    <div ref={timeZoneRef} onClick={handleOnToggleTimeZonePopover} className={classes.detailsUserInfo}>
                        <span className={classes.detailsUserName}>
                            <span>{timezone}</span>
                            <span className="flex">{open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}</span>
                        </span>
                    </div>
                </div>
            )}
            <Popover
                anchorEl={timeZoneRef.current}
                open={open}
                onBackdropClick={handleOnToggleTimeZonePopover}
                PaperProps={{className: classes.paper}}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <BookTimeZone
                    timeZone={timezone}
                    chosenDate={date ?? new Date()}
                    onChange={handleOnChangeTimeZone}
                    isHalfDayClock={isHalfDayClock}
                    onTimeChange={(halfDay) => setIsHalfDayClock?.(halfDay)}
                />
            </Popover>
        </div>
    );
};

export default BookDetails;

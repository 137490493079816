import {getMainDefinition} from "@apollo/client/utilities";
import {ApolloLink, Observable} from "@apollo/client";
import {QueryDoc, MutationDoc} from "@workhorse/dataApi";
import {makeVar, ReactiveVar} from "@workhorse/api/data";
import {clearWorkerTimeout, setWorkerTimeout} from "@workhorse/timerWorker";
import {operationTimeout, timeoutUid} from "@workhorse/components/OnlineOfflineDetector";
import toast from "./toast";
import {ApolloOperationContext} from "./apollo";
import {readRemoteUser} from "./user";
import {RECORDING_USER_EMAIL} from "@common/recording/constants";

export type QueriesAndMutations<T = QueryDoc | MutationDoc> = T extends `${infer Name}Document` ? Name : never;
export type Mutations<T = MutationDoc> = T extends `${infer Name}Document` ? Name : never;

type OperationTimeouts = {
    [K in QueriesAndMutations]?: {
        notifyAfterMs: number;
        abortAfterMs: number;
    };
};

type WatchOperations = {
    [K in QueriesAndMutations]?: ReactiveVar<{
        longWait?: boolean;
        timedOut: boolean;
    }>;
};

const DEFAULT_TIMEOUT = 10000;
export const TIMED_OUT_ERROR_MESSAGE = "timed_out";

const timeoutByOperation: OperationTimeouts = {
    CreateOneSession: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 60000,
    },
    UpdateOneSession: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 60000,
    },
    FullSession: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 30000,
    },
    FullMemorySession: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 30000,
    },
    JoinWithInviteLink: {
        notifyAfterMs: 3500,
        abortAfterMs: 7000,
    },
    JoinWithPublicLink: {
        notifyAfterMs: 3500,
        abortAfterMs: 7000,
    },
    TryJoinSession: {
        notifyAfterMs: 3500,
        abortAfterMs: 7000,
    },
    GetContactsAndGroups: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    GetUserEventsList: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    GetWorkspaceMembers: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    GetCalendarEvents: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    UseResourceResult: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    GetSpeakersHistory: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    AgendaTemplate: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
    GetPublicBookingEvent: {
        notifyAfterMs: 120000,
        abortAfterMs: 60000,
    },
    GetPublicBookingEventAvailableSlots: {
        notifyAfterMs: 120000,
        abortAfterMs: 60000,
    },
    AllSessions: {
        notifyAfterMs: 120000,
        abortAfterMs: 45000,
    },
    // GetSessionsUsingResource: {
    //     notifyAfterMs: 120000,
    //     abortAfterMs: 120000,
    // },
    GetOrganizationMembers: {
        notifyAfterMs: DEFAULT_TIMEOUT,
        abortAfterMs: 45000,
    },
};

const defaultWatchVal = {
    longWait: false,
    timedOut: false,
};

export const watchForTimedOut: WatchOperations = {
    CreateOneSession: makeVar(defaultWatchVal),
    UpdateOneSession: makeVar(defaultWatchVal),
    TryJoinSession: makeVar(defaultWatchVal),
    JoinWithInviteLink: makeVar(defaultWatchVal),
    JoinWithPublicLink: makeVar(defaultWatchVal),
};

function showTimeoutToast() {
    if (!operationTimeout()) {
        operationTimeout(true);
    }
    toast(
        "Your network appears to be unstable and this may cause a delay in response times during this session. Please wait patiently as we work to resolve the issue. We apologize for any inconvenience this may cause and appreciate your understanding.",
        {
            type: "networkIssue",
            position: "top",
            uid: timeoutUid,
            permanent: true,
            title: "This is taking longer than usual",
        }
    );
}

type Timers = {
    [K in QueriesAndMutations]?: {
        timer?: string | null;
        notifyTimer?: string | null;
    };
};

const timers: Timers = {};

const timeoutLink = new ApolloLink((operation, forward) => {
    let controller: AbortController;
    const opName = operation.operationName as QueriesAndMutations;

    const {
        abortAfterMs,
        notifyAfterMs,
        timedOutCb,
        notifyCb,
        retryAttemptNo,
        fetchOptions: contextFetchOpts,
        cancelRef,
    } = operation.getContext() as ApolloOperationContext & {
        retryAttemptNo: number;
    };
    const definition = getMainDefinition(operation.query);
    const operationType = definition.kind === "OperationDefinition" && definition.operation;
    const chainObservable = forward(operation); // observable for remaining link chain

    // override timeout from query context
    const requestTimeout = abortAfterMs ?? timeoutByOperation[opName]?.abortAfterMs ?? (operationType === "query" ? DEFAULT_TIMEOUT : 0);
    const notifyTimeout = notifyAfterMs ?? timeoutByOperation[opName]?.notifyAfterMs;

    if (requestTimeout <= 0) {
        return chainObservable; // skip this link if timeout is zero or it's a subscription request
    }

    // add abort controller and signal object to fetchOptions if they don't already exist
    if (typeof AbortController !== "undefined") {
        let fetchOptions = contextFetchOpts || {};

        controller = fetchOptions.controller || new AbortController();

        fetchOptions = {...fetchOptions, controller, signal: controller.signal};
        operation.setContext({fetchOptions});
    }

    // create local observable with timeout functionality (unsubscibe from chain observable and
    // return an error if the timeout expires before chain observable resolves)
    return new Observable((observer) => {
        function clearTimers(closeNotification?: boolean) {
            if (timers[opName]) {
                if (timers[opName]?.timer != null) {
                    clearWorkerTimeout(timers[opName]?.timer!);
                    delete timers[opName]?.timer;
                    if (operationTimeout()) {
                        operationTimeout(false);
                    }

                    if (timedOutCb) {
                        timedOutCb(false, retryAttemptNo);
                    }
                }
                if (timers[opName]?.notifyTimer != null) {
                    clearWorkerTimeout(timers[opName]?.notifyTimer!);
                    delete timers[opName]?.notifyTimer;
                    if (operationTimeout()) {
                        operationTimeout(false);
                    }
                    if (notifyCb) {
                        notifyCb(false, retryAttemptNo);
                    }
                }
            }

            if (closeNotification) {
                toast(null, {
                    uid: timeoutUid,
                    remove: true,
                });
            }
        }

        if (cancelRef && !cancelRef.aborted && !cancelRef.abort) {
            cancelRef.abort = () => {
                cancelRef.aborted = true;
                controller.abort();
                console.log(`[ABORTING] - manually for ${operationType}=${opName}`);
                clearTimers(true);
                observer.complete();
                subscription.unsubscribe();
                delete cancelRef.abort;
            };
        }

        if (cancelRef?.aborted) {
            controller.abort();
            observer.complete();
            return;
        }

        if (!timers[opName]) {
            timers[opName] = {};
        }

        if (notifyTimeout && !timers[opName]?.notifyTimer) {
            timers[opName]!.notifyTimer = setWorkerTimeout(() => {
                const watched = watchForTimedOut[opName];
                if (watched) {
                    watched({longWait: true, timedOut: false});
                    if (!notifyCb) {
                        const user = readRemoteUser();
                        showTimeoutToast();
                        if (user?.getRemoteUser.user?.email === RECORDING_USER_EMAIL) {
                            window.location.reload();
                        }
                    }
                }
                if (notifyCb) {
                    notifyCb(true, retryAttemptNo);
                }
            }, notifyTimeout);
        }

        // if timeout expires before observable completes, abort call, unsubscribe, and return error
        if (!timers[opName]!.timer) {
            timers[opName]!.timer = setWorkerTimeout(() => {
                controller.abort(); // abort fetch operation

                // if the AbortController in the operation context is one we created,
                // it's now "used up", so we need to remove it to avoid blocking any
                // future retry of the operation.
                const context = operation.getContext();
                let fetchOptions = context.fetchOptions || {};
                if (fetchOptions.controller === controller && fetchOptions.signal === controller.signal) {
                    fetchOptions = {...fetchOptions, controller: null, signal: null};
                    operation.setContext({fetchOptions});
                }
                const watched = watchForTimedOut[opName];
                if (watched) {
                    watched({...watched(), timedOut: true});

                    if (!timedOutCb) {
                        showTimeoutToast();
                    }
                }
                if (timedOutCb) {
                    timedOutCb(true, retryAttemptNo);
                }
                clearTimers();
                console.log(`[TIMEOUT] - automatic abort of ${operationType}=${opName}`);
                observer.error(new Error(TIMED_OUT_ERROR_MESSAGE));

                subscription.unsubscribe();
            }, requestTimeout);
        }

        // listen to chainObservable for result and pass to localObservable if received before timeout
        const subscription = chainObservable.subscribe(
            (...result) => {
                clearTimers(true);
                const watched = watchForTimedOut[opName]?.();
                if (watched && (watched.longWait || watched.timedOut)) {
                    watchForTimedOut[opName]!({longWait: false, timedOut: false});
                }
                observer.next(...result);
                observer.complete();
            },
            (...error) => {
                clearTimers(true);
                const watched = watchForTimedOut[opName]?.();
                if (watched && (watched.longWait || watched.timedOut)) {
                    watchForTimedOut[opName]!({longWait: false, timedOut: false});
                }
                subscription.unsubscribe();
                observer.error(...error);
            }
        );

        let ctxRef = operation.getContext().timeoutRef;

        if (ctxRef) {
            ctxRef({
                unsubscribe: () => {
                    clearTimers(true);
                    subscription.unsubscribe();
                },
            });
        }

        // this function is called when a client unsubscribes from localObservable
        return () => {
            clearTimers(true);
            subscription.unsubscribe();
        };
    });
});

export default timeoutLink;

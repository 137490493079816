import {cls} from "@ui/cdk/util";
import loadable from "@workhorse/api/loadable";
import {RouteHOCProps, MemoryRouteMatch} from "@workhorse/declarations";
import classes from "./memoryComponents/style/Memory.module.scss";
import {Route, Switch, useLocation, RouteChildrenProps, Redirect} from "@workhorse/api/routing";
import {useEffect} from "@workhorse/api/rendering";
import {TempLocalStorageParams} from "./components/utils";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {useUserInfo} from "@workhorse/providers/User";

const MemoryEntrypoint = loadable(() => import("./MemoryEntrypoint"));
const MemoryDashboard = loadable(() => import("./components/MemoryDashboard"));

type MemoryHOCProps = RouteHOCProps<MemoryRouteMatch>;

function MemoryHOC(props: MemoryHOCProps) {
    const sessionId = props.match?.params.sessionId;
    const mobileState = useMobile();
    const location = useLocation();
    const {isGuest, isAuthenticated} = useUserInfo();

    useEffect(() => {
        return () => {
            for (const key of Object.keys(TempLocalStorageParams)) {
                localStorage.removeItem(TempLocalStorageParams[key]);
            }
        };
    }, []);
    return (
        <div className={cls("memory-hoc flex relative fullw fullh", classes.memoryHoc)}>
            <Switch location={location} key="memory-routes-switch">
                <Route path="/memory/session/:sessionId?" exact key="memory-session">
                    {(routeProps: RouteChildrenProps) => {
                        return <MemoryEntrypoint sessionId={sessionId!} mobileState={mobileState} {...routeProps} />;
                    }}
                </Route>
                <Route
                    path="/memory"
                    render={() => (isGuest || !isAuthenticated ? <Redirect to="/" /> : <MemoryDashboard />)}
                    exact
                    key="memory-dashboard"
                ></Route>
            </Switch>
        </div>
    );
}

export default MemoryHOC;

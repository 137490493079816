import Popover from "@ui/core/components/Popover";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import {useCallback, useRef, useState} from "@workhorse/api/rendering";
import {ToolbarGenericProps, useClickOutside} from "../api";
import {ToolbarItem} from "./components";
import classes from "./styles/Toolbar.module.scss";
import {cls} from "@ui/cdk/util";
import Typography from "@ui/cdk/Typography/Typography";
import InputLabel from "@ui/core/components/InputLabel";
import Input from "@ui/cdk/Input";
import * as linkify from "linkifyjs";

export const ToolbarHyperlink = (props: ToolbarGenericProps) => {
    const {editor, onActionDone} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const ref = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const handleSetLink = () => {
        if (!editor.getAttributes("link").href) {
            return editor.chain().focus().extendMarkRange("link").unsetLink().run();
        }

        const link = linkify.find(editor.getAttributes("link").href);

        if (link.length) {
            editor.chain().focus().extendMarkRange("link").setLink({href: link[0].href}).run();
        }
    };

    const handleSubmit = () => {
        setAnchorEl(undefined);
        handleSetLink();
    };

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                event.target &&
                !ref.current?.contains(event.target as Node) &&
                !modalRef.current?.contains(event.target as Node) &&
                anchorEl
            ) {
                handleSubmit();
            }
        },
        [anchorEl]
    );

    useClickOutside("click", handleClickOutside);

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(
        (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            if (reason === "backdropClick") {
                return;
            }
            setAnchorEl(undefined);
            onActionDone();
        },
        [onActionDone]
    );

    return (
        <>
            <ToolbarItem
                tooltip="Hyperlink"
                className={cls(classes.button, editor.isActive("link") && classes.buttonActive)}
                onClick={handleOpenMenu}
                disabled={!editor.isEditable}
                isMobile={props.isMobile}
                ref={ref}
            >
                <LinkRoundedIcon id="link-icon" />
            </ToolbarItem>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocus
                disableEnforceFocus
                disableScrollLock
            >
                <div className={cls("toolbar-element", classes.hyperlinkPaper)} ref={modalRef}>
                    <form
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <InputLabel>
                            <Typography variant="sm" fontWeight="bold" color="secondary">
                                Hyperlink:
                            </Typography>
                        </InputLabel>
                        <Input
                            type="url"
                            value={editor.getAttributes("link").href}
                            onChange={({target}) => editor.chain().extendMarkRange("link").setLink({href: target.value}).run()}
                            placeholder={"Enter hyperlink"}
                            className={cls(classes.hyperlinkInput, "mt-5")}
                            onMouseDown={(e) => e.stopPropagation()}
                            autoFocus
                        />
                    </form>
                </div>
            </Popover>
        </>
    );
};

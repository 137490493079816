import {isAfter, isFuture, differenceInCalendarDays, isPast} from "date-fns";

export const isReverseTrialActive = (trialCancellationDate: Date | undefined | null) => {
    if (!trialCancellationDate) {
        return false;
    }
    return isAfter(new Date(trialCancellationDate), new Date());
};

export const reverseTrialDaysLeft = (trialCancellationDate: Date | undefined | null) => {
    if (!trialCancellationDate) {
        return 0;
    }

    if (!isFuture(new Date(trialCancellationDate))) {
        return 0;
    }

    return differenceInCalendarDays(new Date(trialCancellationDate), new Date());
};

export const isReverseTrialWeirdState = (trialCancellationDate: Date | undefined | null) => {
    if (!trialCancellationDate) {
        return false;
    }

    return isPast(new Date(trialCancellationDate));
};

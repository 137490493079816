import {ApolloLink, InMemoryCache} from "@apollo/client";

import {
    CollectFeedbackType,
    DesignerSessionChangedByDocument,
    DesignerSessionLockedDocument,
    ExitPageCtaType,
    FullSessionQuery,
    GetMemorySessionDocument,
    GetSessionDocument,
    LocalAgendaItemsDocument,
    LocalCurrentParticipantDocument,
    LocalMacroArtifactsDocument,
    LocalMemoryParticipantsDocument,
    LocalParticipantsDocument,
    MemoryAccessType,
    ParticipantsMacroAccess,
    PrivateChatAccess,
    SessionFlagsType,
    SessionLifecycle,
    SessionRecordingType,
    SessionSource,
    SessionTimerState,
    SessionView,
} from "@generated/data";
import {writeQuery} from "@workhorse/dataApi";

import {QueryPayload} from "@workhorse/dataApi";
import {extractDiff, ingestDiff, resetDiff} from "@sessions/common/diff/computeDiff";
import designer from "../designer";
import {readDesignerAgendaItems} from "@workhorse/providers/DesignerSessionDataProviders";
import environment from "@generated/environment";
import {QueriesAndMutations} from "../apollo-link-timeout";

const watchOperations: QueriesAndMutations[] = [
    "FullSession",
    "CreateOneSession",
    "UpdateOneSession",
    "UpdateParticipantsInSession",
    "FullMemorySession",
    "CreateOneAgendaTemplate",
    "UpdateOneAgendaTemplate",
    "GetAgendaTemplateForEditing" as "GetAgendaTemplateForEditing",
];

type AnyObjWithUpdatedAt = {
    updatedAt?: Date | string;
};

function existingIsNewer(existing?: AnyObjWithUpdatedAt, incoming?: AnyObjWithUpdatedAt) {
    if (!existing || !existing.updatedAt) {
        return false;
    }
    const now = new Date();
    return new Date(existing.updatedAt).getTime() > new Date(incoming?.updatedAt ?? now).getTime();
}

export default new ApolloLink((operation, forward) => {
    const opName = operation.operationName as QueriesAndMutations;
    if (watchOperations.includes(opName)) {
        const cache = operation.getContext().cache as InMemoryCache;

        const isTemplate = ["GetAgendaTemplateForEditing", "UpdateOneAgendaTemplate", "CreateOneAgendaTemplate"].includes(opName);
        const isTemplateCreate = opName === "CreateOneAgendaTemplate";

        const idFromCreate = isTemplateCreate ? operation.variables.data?.id : operation.variables.sessionId;

        const {
            id: idFromQuery,
            // sessionId: idFromCreate,
            hasParticipants,
            where,
        } = operation.variables as {
            id: string;
            sessionId: string;
            hasParticipants?: boolean;
            where?: {id: string};
        };

        if (hasParticipants && opName === "UpdateOneSession") {
            return forward(operation).map((response) => {
                return response;
            });
        }

        const id = where
            ? isTemplate
                ? where.id + "_template"
                : where.id
            : isTemplate
            ? (idFromQuery || idFromCreate) + "_template"
            : idFromQuery || idFromCreate;

        if (isTemplate) {
            const designerId = designer.state.getSessionId();
            if (!designerId || designerId !== id) {
                designer.state.initializeOrResetState(id, undefined, true);
            }
        }

        return forward(operation).map((response) => {
            if (opName === "FullMemorySession" && response.data == null) {
                response.data = {};
                return response;
            }
            if (response.data === null) {
                return response;
            }

            const operationData =
                opName === "CreateOneSession"
                    ? response.data?.createSession
                    : opName === "UpdateOneSession"
                    ? response.data?.updateSession
                    : opName === "UpdateParticipantsInSession"
                    ? response.data?.updateParticipantsInSession
                    : opName === "CreateOneAgendaTemplate"
                    ? response.data?.createOneAgendaTemplate
                    : opName === "UpdateOneAgendaTemplate"
                    ? response.data?.updateOneAgendaTemplate
                    : opName === "GetAgendaTemplateForEditing"
                    ? response.data?.agendaTemplate
                    : response.data;

            if (window.location.pathname === "/events") {
                return response;
            }

            const mockedSession: QueryPayload<"FullSessionDocument">["data"]["session"] = {
                name: operationData?.name,
                description: operationData?.description,
                id: id,
                createdAt: operationData?.createdAt,
                updatedAt: operationData?.updatedAt,
                allowCollaborationInLobby: false,
                allowScreenShareType: SessionFlagsType.Everyone,
                askForGuestEmail: false,
                currentParticipant: {
                    pid: "",
                    __typename: "Participant",
                },
                allowCameraType: SessionFlagsType.Speakers,
                allowAgendaType: SessionFlagsType.Speakers,
                enableReactionsType: SessionFlagsType.Everyone,
                endByWorker: false,
                allowMicrophoneType: SessionFlagsType.Speakers,
                hideNonStreamingParticipants: false,
                reminderInMinutes: null,
                autoRecording: false,
                passcodeToJoin: null,
                provideSessionPasscode: false,
                bypassSessionPasscode: false,
                autoTranscribing: false,
                isBooking: false,
                lifecycle: SessionLifecycle.NotStarted,
                lobbyAccess: false,
                memoryAccessType: MemoryAccessType.Private,
                order: 0,
                polls: [],
                quickSession: false,
                requestPermissionToJoin: false,
                requireConsentToRecord: false,
                fullSizeVideosInRecording: false,
                sendEmailsAutomatically: false,
                showAgendaInLobby: false,
                source: SessionSource.App,
                backstage: false,
                transcriptionActive: false,
                timeDependency: true,
                syncToAgenda: false,
                view: SessionView.Default,
                actualStart: null,
                byTimezonePlannedEnd: null,
                byTimezoneStartAt: null,
                plannedEnd: null,
                childOfBreakoutRooms: {
                    id: "",
                    allowRoomNavigation: false,
                    artifactIdOfBreakoutRooms: "",
                    breakoutRooms: [],
                    parentOfBreakoutRooms: {
                        name: "",
                        sessionId: "",
                        __typename: "Session",
                    },
                    __typename: "BreakoutRoomsConfiguration",
                    participantsMap: [],
                },
                createdFromTemplateId: null,
                endedAt: null,
                event: null,
                generatedName: null,
                instanceOfRecurrence: null,
                isDeleted: false,
                occurrenceId: null,
                oldId: null,
                questionsContainer: {
                    id: "",
                    __typename: "QuestionsContainer",
                },
                recurrenceData: null,
                shareScreenPid: null,
                startAt: null,
                timeZone: null,
                update: null,
                __typename: "Session",
                isPrivate: false,
                collaborativeSession: null,
                reminders: null,
                messageReminders: null,
                hiddenMacros: [],
                disabledNotifications: [],
                autoStartSession: false,
                sendEndSessionEmail: true,
                recordingConferenceView: null,
                recordingPresentationView: null,
                groupChatAccess: true,
                privateChatAccess: PrivateChatAccess.Everyone,
                participantsMacroAccess: ParticipantsMacroAccess.Everyone,
                exitPageOffer: null,
                exitPageOfferId: "",
                workspaceId: "",
                timerState: SessionTimerState.Stopped,
                collectFeedback: CollectFeedbackType.SessionsFeedback,
                showExitPageCta: ExitPageCtaType.SessionsCta,
                hideIcsGuestList: false,
                recordingType: SessionRecordingType.Livestream,
                livestreamOutputUrls: {},
                feedbackFormAnswers: [],
                feedbackForm: null,
            };

            const data = isTemplate
                ? {
                      agendaItems: operationData?.agendaItems,
                      macroArtifacts: [],
                      //   childOfBreakoutRooms: null,
                      participants: [],
                      ...mockedSession,
                  }
                : operationData;

            const {agendaItems, macroArtifacts, currentParticipant, childOfBreakoutRooms, participants, ...other} = data;
            const isSessionGet = ["FullSession", "FullMemorySession"].indexOf(opName) !== -1;

            const isMemory = opName === "FullMemorySession";
            const session = !isSessionGet ? other : other.session;

            const isEvent =
                data?.session?.event?.id &&
                (data?.session?.lifecycle === SessionLifecycle.NotStarted || window.location.pathname.includes("/event/"));

            // if (isEvent) {
            //     if (!data.currentParticipant.isOwner && !data.currentParticipant.rbac?.session?.isAssistant) {
            //         const baseURL = environment.eventBaseURL + "/";
            //         window.location.href = baseURL + session.event?.slug;
            //     }
            // }

            const full: QueryPayload<"FullSessionDocument">["data"] = {
                __typename: "Query",
                agendaItems: agendaItems,
                currentParticipant,
                macroArtifacts,
                participants,
                session: {
                    ...session,
                    currentParticipant: {
                        __typename: "Participant",
                        pid: currentParticipant.id,
                    },
                },
            };
            const collaborativeSession = data?.session?.collaborativeSession ? structuredClone(data.session.collaborativeSession) : null;
            if (isEvent) {
                designer.state.initializeOrResetState(full.session.id);
            }

            if (isEvent && collaborativeSession && full) {
                const currentLocked = cache.readQuery({
                    query: DesignerSessionLockedDocument,
                });

                let locked: string | undefined;
                const changedBy: string[] | undefined = collaborativeSession.changesBy;
                delete collaborativeSession.changesBy;

                cache.writeQuery({
                    query: DesignerSessionChangedByDocument,
                    data: {
                        __typename: "Query",

                        designerSessionChangedBy: {
                            __typename: "DesignerSessionChangedBy",
                            changedBy,
                        },
                    },
                });

                if (collaborativeSession.locked) {
                    locked = collaborativeSession.locked;
                    delete collaborativeSession.locked;
                }

                resetDiff(full);

                const diff = extractDiff(full, collaborativeSession);
                ingestDiff(full as any, diff);
                full.session.collaborativeSession = collaborativeSession;

                if (locked !== currentLocked?.designerSessionLocked?.locked) {
                    cache.writeQuery({
                        query: DesignerSessionLockedDocument,

                        data: {
                            __typename: "Query",
                            designerSessionLocked: {
                                __typename: "DesignerSessionLocked",
                                locked,
                            },
                        },
                        broadcast: false,
                    });
                }
            } else if (isEvent && !collaborativeSession) {
                cache.writeQuery({
                    query: DesignerSessionLockedDocument,

                    data: {
                        __typename: "Query",
                        designerSessionLocked: {
                            __typename: "DesignerSessionLocked",
                            locked: undefined,
                        },
                    },
                    broadcast: false,
                });
            }

            if (!isEvent || (isEvent && !collaborativeSession)) {
                resetDiff(full);
            }

            const currentParticipants = (
                (
                    cache.readQuery({
                        query: isMemory ? LocalMemoryParticipantsDocument : LocalParticipantsDocument,
                        variables: {
                            id,
                        },
                    }) ?? {}
                ).participants ?? []
            ).reduce((agg, item) => {
                agg.set(item.id, item);
                return agg;
            }, new Map());

            const currentLocalParticipant = cache.readQuery({
                query: LocalCurrentParticipantDocument,
                variables: {
                    id,
                },
            });

            cache.writeQuery({
                query: isMemory ? LocalMemoryParticipantsDocument : LocalParticipantsDocument,
                variables: {
                    id,
                },
                data: {
                    __typename: "Query",
                    // @ts-ignore
                    participants: (full.participants! ?? []).map((p) => {
                        const existing = currentParticipants.get(p.id);
                        if (!existing) {
                            return p;
                        }
                        if (existingIsNewer(existing as AnyObjWithUpdatedAt, p)) {
                            return existing;
                        } else {
                            return p;
                        }
                    }),
                },
                broadcast: true,
            });

            if (opName === "UpdateOneAgendaTemplate") {
                const all = readDesignerAgendaItems();
                // this is needed because whenever an update of an agenda item gets commited,
                // there is a high probability of another one to be created locally before the response comes,
                // when the user commits an agenda by pressing the create button
                const newAgendaItems = all.map((agendaItem) => {
                    return full?.agendaItems?.find((a) => a.id === agendaItem.id) ?? agendaItem;
                });
                // .sort((a, b) => {
                //     return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
                // });
                cache.writeQuery({
                    query: LocalAgendaItemsDocument,
                    variables: {
                        id,
                        // @ts-ignore
                        override: true,
                    },
                    data: {
                        __typename: "Query",
                        agendaItems: newAgendaItems! ?? [],
                    },
                    broadcast: false,
                });
            } else {
                cache.writeQuery({
                    query: LocalAgendaItemsDocument,
                    variables: {
                        id,
                        // @ts-ignore
                        override: true,
                    },
                    data: {
                        __typename: "Query",
                        agendaItems: full.agendaItems! ?? [],
                    },
                    broadcast: false,
                });
            }

            cache.writeQuery({
                query: LocalMacroArtifactsDocument,
                variables: {
                    id,
                    // @ts-ignore
                    override: true,
                },
                data: {
                    __typename: "Query",
                    macroArtifacts: full.macroArtifacts! ?? [],
                },
                broadcast: false,
            });

            cache.writeQuery({
                query: LocalCurrentParticipantDocument,
                variables: {
                    id,
                },
                data: {
                    __typename: "Query",
                    currentParticipant: existingIsNewer(currentLocalParticipant?.currentParticipant, full.currentParticipant! ?? {})
                        ? currentLocalParticipant?.currentParticipant!
                        : full.currentParticipant! ?? {},
                },
                broadcast: false,
            });

            cache.writeQuery({
                query: isMemory ? GetMemorySessionDocument : GetSessionDocument,

                data: {
                    __typename: "Query",
                    session: full.session,
                },
                variables: {
                    id,
                    // @ts-ignore
                    override: true,
                },
                broadcast: true,
            });
            // console.log("returning", {
            //     ...response,
            //     data: {
            //         __typename: "Query",
            //         session: response.data?.session! ?? {},
            //     },
            // });

            return ["CreateOneSession", "UpdateOneSession", "UpdateParticipantsInSession"].indexOf(opName) !== -1
                ? response
                : isTemplate
                ? response
                : {
                      ...response,
                      data: {
                          __typename: "Query",
                          session: response.data?.session! ?? {},
                      },
                  };
        });
    }
    return forward(operation).map((response) => {
        return response;
    });
});

// override will be sent based on a precomputed role that tells us if the user is an editor and we souldn't overrride
// or if he's a participant and we should
export function writeFromFullSession(id: string, data: Partial<Omit<FullSessionQuery, "__typename">>, override?: boolean) {
    const updateKeys = Object.keys(data) as Array<keyof typeof data>;
    updateKeys.forEach((k) => {
        switch (k) {
            case "session": {
                writeQuery("GetSessionDocument", {
                    variables: {
                        id,
                        // @ts-ignore
                        override: override ?? false,
                    },
                    data: {
                        __typename: "Query",
                        session: data.session,
                    },
                });
                break;
            }
            case "agendaItems": {
                writeQuery("LocalAgendaItemsDocument", {
                    variables: {
                        id,
                        // @ts-ignore
                        override: override ?? false,
                    },
                    data: {
                        __typename: "Query",
                        agendaItems: data.agendaItems ?? [],
                    },
                });
                break;
            }
            case "macroArtifacts": {
                writeQuery("LocalMacroArtifactsDocument", {
                    variables: {
                        id,
                        // @ts-ignore
                        override: override ?? false,
                    },
                    data: {
                        __typename: "Query",
                        macroArtifacts: data.macroArtifacts ?? [],
                    },
                });
                break;
            }
            case "currentParticipant": {
                writeQuery("LocalCurrentParticipantDocument", {
                    variables: {
                        id,
                    },
                    data: {
                        __typename: "Query",
                        currentParticipant: data.currentParticipant!,
                    },
                });
                break;
            }
            case "participants": {
                writeQuery("LocalParticipantsDocument", {
                    variables: {
                        id,
                    },
                    data: {
                        __typename: "Query",
                        participants: data.participants ?? [],
                    },
                });
                break;
            }
            default: {
                break;
            }
        }
    });
}

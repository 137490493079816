import {getLocalTimezone} from "@common/utils/timezones";
import descriptor, {ArtifactTag} from "@generated/artifacts";
import {
    AgendaItemType,
    CollectFeedbackType,
    ConferenceParticipantStatus,
    ExitPageCtaType,
    MemoryAccessType,
    ParticipantInviteStatus,
    ParticipantsMacroAccess,
    ParticipantSource,
    ParticipantStatus,
    PrivateChatAccess,
    SessionFlagsType,
    SessionLifecycle,
    SessionSource,
} from "@generated/data";
import designer from "@workhorse/api/designer";
import extension from "@workhorse/api/extension";
import {readRemoteUser} from "@workhorse/api/user";
import {ArrayItem} from "@workhorse/declarations";
import {DesignerApiMacroArtifact, DesignerApiSession as Session} from "@workhorse/declarations/dataTypes";
import {DesignerAction} from "../../action";
import {makeEmpty, makeId} from "../../utils";
import {createAgendaItem} from "../agenda-item/create-agenda-item";

export type SessionWithMandatoryNullableKeys = Session;

export type CreateSessionInput = {
    [K in keyof Session]?: Session[K] extends Array<any> ? Session[K] : Partial<Session[K]>;
} & {
    hasAgenda?: boolean;
    isTemplate?: boolean;
};

function makeMacro(artifactId: ArtifactTag, name?: string, description?: string) {
    const now = null as unknown as Date;
    const databaseId = makeId();
    const artifact: DesignerApiMacroArtifact = {
        __typename: "Artifact",
        artifactId,
        data: {},
        description: description || "",
        durationInSeconds: 0,
        endedAt: null,
        id: databaseId,
        oldId: databaseId,
        isConfigured: true,
        isMacro: true,
        name: name || "",
        order: null,
        properties: [],
        startAt: null,
        createdAt: now,
        updatedAt: now,
        isDeleted: false,
        update: null,
    };

    return artifact;
}

function addDefaultMacros<T extends Array<DesignerApiMacroArtifact>>(currentMacros: T, hasAgenda?: boolean, isTemplate?: boolean): T {
    const descriptorTags = Object.keys(descriptor) as ArtifactTag[];
    const tags = hasAgenda ? descriptorTags : descriptorTags.filter((tag) => tag !== "flowos/agenda");
    const missingMacros = tags
        .filter((artifactId) => descriptor[artifactId].supportedTypes.find((t) => t.type === "macro"))
        .filter((artifactId) => !currentMacros.find((a) => a.artifactId === artifactId))
        .filter((artifactId) => (isTemplate ? artifactId === "flowos/agenda" : true)) as ArtifactTag[];

    missingMacros.forEach((artifactId) => {
        const name = descriptor[artifactId].meta.displayName;
        const description = descriptor[artifactId].meta.description;
        currentMacros.push(makeMacro(artifactId, name, description));
    });

    return currentMacros;
}

export class CreateSessionAction extends DesignerAction<CreateSessionInput> {
    commit() {
        const now = null as unknown as Date;

        const getUser = readRemoteUser();

        const user = getUser?.getRemoteUser?.user;

        const {
            session: sessionData,
            macroArtifacts: macroArtifactsData,
            agendaItems: agendaItemsData,
            currentParticipant: currentParticipantQuery,
            hasAgenda,
            participants: participantsData,
            isTemplate,
        } = this.input;

        const emptySession = makeEmpty("Session", {setCreatedAt: false, setUpdatedAt: true, id: sessionData?.id ?? makeId()});

        designer.state.initializeOrResetState(emptySession.id);

        const myParticipant: ArrayItem<typeof participantsData> = {
            __typename: "Participant",
            conferenceStatus: ConferenceParticipantStatus.Participant,
            dataWithNullableEmail: {
                __typename: "ParticipantDataWithNullableEmail",
                email: user?.email || "owner@flowos.com",

                firstName: user?.firstName || "",
                isGuest: false,
                token: "",
                lastName: user?.lastName || "",
                avatar: user?.profilePicture || "",
                userId: user?.id || "",
            },
            isApproved: true,
            isRecorder: false,
            id: makeId(),
            inviteStatus: ParticipantInviteStatus.Accepted,
            isOwner: true,
            mockedParticipant: false,
            muted: false,
            rbac: {},
            status: ParticipantStatus.NotJoined,
            changedBy: null,
            statusChangedBy: null,
            lastSeenTimestamp: null,
            hasAttendedSession: false,
            invitedByTheOwner: true, // he invited himself
            source: ParticipantSource.App,
            updatedAt: now,
            createdAt: now,
            askToStartCamera: false,
            askToUnmute: false,
            speakerDetails: null,
            controlStatus: null,
            submittedPasscode: true,
            paymentTransaction: null,
            isMainParticipant: false,
        };

        const {
            __typename = "Session",
            id = emptySession.id,
            oldId = emptySession.id,
            timeDependency = false,
            description = null,
            order = 0,
            createdFromTemplateId = null,
            endedAt = null,
            instanceOfRecurrence = null,
            lifecycle = SessionLifecycle.NotStarted,
            occurrenceId = null,
            plannedEnd = null,
            quickSession = false,
            endByWorker = true,
            recurrenceData = null,
            requestPermissionToJoin = false,
            requireConsentToRecord = false,
            sendEmailsAutomatically = true,
            startAt = quickSession ? now : null,
            lobbyAccess = true,
            allowCollaborationInLobby = true,
            showAgendaInLobby = true,
            askForGuestEmail = false,
            allowScreenShareType = SessionFlagsType.Everyone,
            allowCameraType = SessionFlagsType.Everyone,
            allowMicrophoneType = SessionFlagsType.Everyone,
            enableReactionsType = SessionFlagsType.Everyone,
            shareScreenPid = null,
            timeZone = getLocalTimezone(),
            name = "",
            source = extension.isExtensionEnabled() ? SessionSource.BrowserExtension : SessionSource.App,
            transcriptionActive = false,
            generatedName = null,
            byTimezonePlannedEnd = null,
            byTimezoneStartAt = null,
            createdAt = now,
            updatedAt = now,
            isDeleted = false,
            update = null,
            actualStart = null,
            childOfBreakoutRooms = null,
            memoryAccessType = MemoryAccessType.JustAttendees,
            allowAgendaType = SessionFlagsType.Everyone,
            hideNonStreamingParticipants = false,
            reminderInMinutes,
            autoRecording = false,
            autoTranscribing = false,
            onboardingType = null,
            questionsContainer: inputQuestionsContainer,
            questionsContainer = {
                __typename: "QuestionsContainer",
                id: makeId(),
                ...inputQuestionsContainer,
            },

            // chimeMeeting = null,
            currentParticipant = {
                __typename: "Participant",
                pid: myParticipant.id,
            },
            polls = [],
            event,
            room,
            syncToAgenda,
            reminders,
            messageReminders,
            hiddenMacros = [],
            disabledNotifications = [],
            autoStartSession = false,
            sendEndSessionEmail = true,
            fullSizeVideosInRecording = false,
            recordingConferenceView,
            recordingPresentationView,
            passcodeToJoin,
            provideSessionPasscode = false,
            bypassSessionPasscode = false,
            groupChatAccess = true,
            privateChatAccess = PrivateChatAccess.Everyone,
            participantsMacroAccess = ParticipantsMacroAccess.Everyone,
            exitPageOffer = null,
            exitPageOfferId = "",
            collectFeedback = CollectFeedbackType.SessionsFeedback,
            showExitPageCta = ExitPageCtaType.SessionsCta,
            hideIcsGuestList = false,
            livestreamOutputUrls,
            recordingType,
            isTask,
            feedbackFormId,
        } = sessionData || {};

        const session: typeof sessionData = {
            __typename,
            id,
            actualStart,
            allowScreenShareType,
            byTimezonePlannedEnd,
            byTimezoneStartAt,
            childOfBreakoutRooms,
            // chimeMeeting,
            createdAt,
            createdFromTemplateId,
            currentParticipant,
            askForGuestEmail,
            description,
            enableReactionsType,
            endByWorker,
            endedAt,
            generatedName,
            lobbyAccess,
            allowCollaborationInLobby,
            showAgendaInLobby,
            allowCameraType,
            allowMicrophoneType,
            instanceOfRecurrence,
            isDeleted,
            lifecycle,
            name,
            occurrenceId,
            order,
            plannedEnd,
            polls,
            questionsContainer,
            quickSession,
            recurrenceData,
            requestPermissionToJoin,
            requireConsentToRecord,
            sendEmailsAutomatically,
            shareScreenPid,
            source,
            transcriptionActive,
            startAt,
            timeDependency,
            timeZone,
            update,
            updatedAt,
            memoryAccessType,
            allowAgendaType,
            hideNonStreamingParticipants,
            reminderInMinutes,
            autoRecording,
            autoTranscribing,
            onboardingType,
            event,
            room,
            syncToAgenda,
            reminders,
            messageReminders,
            hiddenMacros,
            disabledNotifications,
            autoStartSession,
            sendEndSessionEmail,
            fullSizeVideosInRecording,
            recordingConferenceView,
            recordingPresentationView,
            passcodeToJoin,
            provideSessionPasscode,
            bypassSessionPasscode,
            groupChatAccess,
            privateChatAccess,
            participantsMacroAccess,
            exitPageOffer,
            exitPageOfferId,
            collectFeedback,
            showExitPageCta,
            hideIcsGuestList,
            livestreamOutputUrls,
            recordingType,
            isTask,
            feedbackFormId,
        };

        const updatePayload: CreateSessionInput = {
            session,
            macroArtifacts: addDefaultMacros(macroArtifactsData ?? [], hasAgenda, isTemplate),
            currentParticipant: {
                __typename: "Participant",
                id: myParticipant.id,
                conferenceStatus: myParticipant.conferenceStatus,
                lastSeenTimestamp: null,
                rbac: {},
                status: myParticipant.status,
                updatedAt: now,
            },
            agendaItems: [
                createAgendaItem({
                    type: AgendaItemType.Instant,
                    title: designer.constants.DUMMY_AGENDA_ITEM_TITLE,
                    // ...(this.input.session?.quickSession ? {type: AgendaItemType.Instant} : {}),
                }),
            ],
            participants: [myParticipant],
        };

        designer.updateCurrentSession(updatePayload, {strategy: "replace"});

        return emptySession.id;
    }

    canRedo() {
        return false;
    }

    canUndo() {
        return false;
    }
}

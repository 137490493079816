import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import Checkbox from "@ui/cdk/Checkbox";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util";
import TextField from "@ui/core/components/TextField";
import {useDevices} from "@workhorse/api/conference2";
import {getHelpCenterArticleLink} from "@workhorse/api/help/article";
import {useEffect, useMemo, useRef, useState} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {HyperlinkExternal} from "@workhorse/util/links";
import {ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps} from "formik";
import {useTranslation} from "react-i18next";
import {t, TFunction} from "i18next";
import * as Yup from "yup";
import PreJoinSubmitButton from "./PreJoinSubmitButton";
import classes from "./styles/PreJoinInfoForm.module.scss";
import InfoTooltip from "@workhorse/components/InfoTooltip";
import {useCurrentParticipant} from "@workhorse/providers/SessionDataProviders";
import {SessionRecordingType} from "@sessions/common/generated/data-types";

function isPasscodeValid(isValid: boolean) {
    return this.test({
        name: "isPasscodeValid",
        message: t("prejoin.error.invalid_passcode") ?? "",
        test: () => isValid,
    });
}

Yup.addMethod(Yup.string, "isPasscodeValid", isPasscodeValid);

function getValidationSchema(provideSessionPasscode: boolean, isProvidePasscodeFailed: boolean, askForGuestEmail: boolean, t: TFunction) {
    const schema: Record<string, Yup.AnySchema> = {
        name: Yup.string()
            .trim()
            .required(t("g.form.field_mandatory") ?? "")
            .max(maximumCharactersLength, t("g.form.max_length", {max: maximumCharactersLength}) ?? ""),
    };

    if (askForGuestEmail) {
        schema["email"] = Yup.string()
            .trim()
            .email(t("g.form.enter_valid_email") ?? "")
            .required(t("g.form.field_mandatory") ?? "");
    }

    if (provideSessionPasscode) {
        schema["passcode"] = Yup.string()
            .trim()
            .required(t("g.form.field_mandatory") ?? "")
            //@ts-ignore - Yup doesn't have a type for isPasscodeValid which is a custom method
            .isPasscodeValid(!isProvidePasscodeFailed);
    }

    return Yup.object().shape(schema);
}

export type FormikValuesType = {
    name: string;
    email: string;
    rememberMe: boolean;
    passcode: string;
    consentedToRecord?: boolean;
};

const maximumCharactersLength = 100;

type PreJoinInfoFormProps = {
    name: string;
    email: string;
    requestPermissionToJoin: boolean;
    onSubmit: (values: FormikValuesType) => void;
    isSubmitting: boolean;
    isGuest: boolean;
    isPermissionDenied?: boolean;
    askForGuestEmail: boolean;
    showNetworkIssueMsg?: boolean;
    isProvideSessionPasscode: boolean;
    provideSessionPasscode: boolean;
    passcodeToJoin?: string;
    requiresConsentToRecord: boolean;
    transcriptionActive: boolean;
    recordingActive: boolean;
    isOwner: boolean;
    isAssistant: boolean;
    isProvidePasscodeFailed: boolean;
    isBorChild: boolean;
    setIsProvidePasscodeFailed: (value: boolean) => void;
    isOwnerOnboarding?: boolean;
    recordingType?: SessionRecordingType;
};

const PreJoinInfoForm = (props: PreJoinInfoFormProps) => {
    const {
        onSubmit,
        requestPermissionToJoin,
        isSubmitting,
        name,
        email,
        isGuest,
        isPermissionDenied,
        askForGuestEmail,
        isProvideSessionPasscode,
        provideSessionPasscode,
        passcodeToJoin,
        isOwner,
        isAssistant,
        isProvidePasscodeFailed,
        setIsProvidePasscodeFailed,
        isOwnerOnboarding,
    } = props;

    const isOrganizer = isOwner || isAssistant;
    console.log("props recordingType", props.recordingType);
    const {t, i18n} = useTranslation();

    const validationSchema = useMemo(
        () => getValidationSchema(isProvideSessionPasscode, isProvidePasscodeFailed, askForGuestEmail, t),
        [isProvideSessionPasscode, askForGuestEmail, isProvidePasscodeFailed, t]
    );

    const {ready: devicesReady, loading: devicesLoading} = useDevices();

    const currentParticipant = useCurrentParticipant();

    const formRef = useRef<FormikProps<FormikValuesType>>(null);

    const handleToggleRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
        formRef.current?.setFieldValue("rememberMe", e.target.checked);
    };

    const handleToggleConsentToRecord = (e: React.ChangeEvent<HTMLInputElement>) => {
        formRef.current?.setFieldValue("consentedToRecord", e.target.checked);
        if (e.target.checked) {
            setHighlightConsent(false);
        }
    };

    const [highlightConsent, setHighlightConsent] = useState(false);

    const initialValues = {
        name,
        email,
        rememberMe: !!localStorage.getItem("guestUsername") || props.isBorChild,
        language: i18n.language,
        passcode: isOrganizer ? passcodeToJoin ?? "" : "",
        consentedToRecord: false,
    };

    const handleSubmit = (values: FormikValuesType, isValid: boolean | FormikHelpers<FormikValuesType>) => {
        if (!devicesReady) {
            toast(t("prejoin.devices_not_ready"), {type: "error"});
            return;
        }
        if (
            (shouldShowRecordingConsentField || shouldShowTranscriptConsentField || shouldShowLivestreamConsentField) &&
            !values.consentedToRecord
        ) {
            setHighlightConsent(true);
            return;
        }
        if (!isValid) {
            return;
        }
        onSubmit(values);
    };

    const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
        if (formRef.current?.errors[e.target.name]) {
            formRef.current.setFieldError(e.target.name, undefined);

            if (e.target.name === "passcode") {
                setIsProvidePasscodeFailed(false);
            }
        }
    };

    const shouldShowRecordingConsentField = props.recordingActive && !props.isOwner && !currentParticipant.consentedToRecord;
    const shouldShowTranscriptConsentField = props.transcriptionActive && !props.isOwner && !currentParticipant.consentedToRecord;
    const shouldShowLivestreamConsentField =
        props.recordingType === SessionRecordingType.Livestream && !props.isOwner && !currentParticipant.consentedToRecord;

    const getActionsList = (isAdjective: boolean): string => {
        const options = {
            recorded: {
                shouldShow: shouldShowRecordingConsentField,
                translationKey: isAdjective ? t("prejoin.consent_to_record.recorded") : t("prejoin.consent_to_record.record"),
            },
            transcribed: {
                shouldShow: shouldShowTranscriptConsentField,
                translationKey: isAdjective ? t("prejoin.consent_to_record.transcribed") : t("prejoin.consent_to_record.transcribe"),
            },
            livestreamed: {
                shouldShow: shouldShowLivestreamConsentField,
                translationKey: isAdjective ? t("prejoin.consent_to_record.livestreamed") : t("prejoin.consent_to_record.livestream"),
            },
        };

        const enabledOptions = Object.keys(options).filter((key) => options[key].shouldShow);

        if (enabledOptions.length === 0) {
            return "";
        }

        const label = enabledOptions.map((key) => t(options[key].translationKey)).join(", ");

        if (enabledOptions.length > 1) {
            return `${label.slice(0, label.lastIndexOf(","))} ${t("g.and")} ${label.slice(label.lastIndexOf(",") + 1)}`;
        }

        return label;
    };

    useEffect(() => {
        if (isProvidePasscodeFailed) {
            formRef.current?.validateField("passcode");
        }
    }, [isProvidePasscodeFailed]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={false}
            key="pre-join-info-form"
            enableReinitialize
        >
            {({values, isValid, errors, touched}) => {
                return (
                    <Form className={cls("flex flex-col fullw mt-18", classes.root)} onChange={handleChange}>
                        <div className="flex flex-col" key="name-wrapper">
                            <Typography
                                variant="sm"
                                component="label"
                                fontWeight="bold"
                                color="secondary"
                                htmlFor="name"
                                className={classes.labelText}
                            >
                                {isGuest ? <span className={classes.errorSymbol}>*</span> : null}
                                {t("g.form.fields.name")}
                            </Typography>
                            <Field
                                variant="outlined"
                                size="small"
                                name="name"
                                as={TextField}
                                key="name-input"
                                className={classes.formField}
                                placeholder={t("g.form.field_placeholder.name")}
                                autoFocus
                                disabled={isSubmitting || !isGuest}
                            />
                            <ErrorMessage
                                name="name"
                                render={(err) => (
                                    <Typography variant="sm" className={classes.errorText}>
                                        {err}
                                    </Typography>
                                )}
                            />
                        </div>

                        {askForGuestEmail ? (
                            <div className="flex flex-col mt-16" key="email-wrapper">
                                <Typography
                                    variant="sm"
                                    component="label"
                                    fontWeight="bold"
                                    color="secondary"
                                    htmlFor="email"
                                    className={classes.labelText}
                                >
                                    <span className={classes.errorSymbol}>*</span>
                                    {t("g.form.fields.email")}
                                </Typography>
                                <Field
                                    variant="outlined"
                                    size="small"
                                    name="email"
                                    as={TextField}
                                    key="email-input"
                                    className={classes.formField}
                                    placeholder={t("g.form.field_placeholder.email")}
                                    disabled={isSubmitting || !isGuest}
                                />
                                <ErrorMessage
                                    name="email"
                                    render={(err) => (
                                        <Typography variant="sm" className={classes.errorText}>
                                            {err}
                                        </Typography>
                                    )}
                                />
                            </div>
                        ) : null}

                        {((isOrganizer && provideSessionPasscode) || isProvideSessionPasscode) && (
                            <div className="flex flex-col mt-16" key="passcode-wrapper">
                                <Typography
                                    variant="sm"
                                    component="label"
                                    fontWeight="bold"
                                    color="secondary"
                                    htmlFor="passcode"
                                    className={classes.labelText}
                                >
                                    <span className={classes.errorSymbol}>*</span>
                                    {t("g.form.fields.passcode")}
                                </Typography>
                                <Field
                                    variant="outlined"
                                    size="small"
                                    name="passcode"
                                    as={TextField}
                                    key="passcode-input"
                                    className={classes.formField}
                                    placeholder={t("g.form.field_placeholder.passcode")}
                                    disabled={isSubmitting || !isProvideSessionPasscode}
                                    error={!!errors["passcode"] && !!touched["passcode"]}
                                />
                                <ErrorMessage
                                    name="passcode"
                                    render={(err) => (
                                        <Typography variant="sm" className={classes.errorText}>
                                            {err}
                                        </Typography>
                                    )}
                                />
                            </div>
                        )}

                        {(shouldShowRecordingConsentField || shouldShowTranscriptConsentField || shouldShowLivestreamConsentField) && (
                            <div className={cls("flex flex-row-reverse", classes.rememberMe)} key="consentedToRecord-wrapper">
                                <Typography
                                    component="label"
                                    color="primary"
                                    htmlFor="consentedToRecord"
                                    className={cls("flex flex-align-center", classes.labelText)}
                                >
                                    <span className={classes.errorSymbol}>*</span>
                                    {t("prejoin.consent_to_record")}
                                    {getActionsList(true)}{" "}
                                    <InfoTooltip
                                        title={`${t("prejoin.consent_to_record.info_tooltip.title")} ${getActionsList(false)}` ?? ""}
                                        info={
                                            `${t("prejoin.consent_to_record.info_tooltip.description_part_one")} ${getActionsList(
                                                true
                                            ).trim()} ${t("prejoin.consent_to_record.info_tooltip.description_part_two")}` ?? ""
                                        }
                                        openOnHover={true}
                                        className={classes.infoTooltipIcon}
                                    />
                                </Typography>
                                <Field
                                    variant={highlightConsent ? "red" : "blue"}
                                    size="small"
                                    name="consentedToRecord"
                                    as={Checkbox}
                                    checked={values["consentedToRecord"]}
                                    key="consentedToRecord-input"
                                    className={cls(classes.formField, highlightConsent && classes.highlightConsent)}
                                    onChange={handleToggleConsentToRecord}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage
                                    name="consentedToRecord"
                                    render={(err) => (
                                        <Typography variant="sm" className={classes.errorText}>
                                            {err}
                                        </Typography>
                                    )}
                                />
                            </div>
                        )}
                        {(shouldShowRecordingConsentField || shouldShowTranscriptConsentField || shouldShowLivestreamConsentField) &&
                            highlightConsent && (
                                <Typography variant="sm" className={classes.errorText}>
                                    This field is required. See above for more information.
                                </Typography>
                            )}
                        {isGuest && (
                            <div className={cls("flex flex-row-reverse", classes.rememberMe)} key="rememberMe-wrapper">
                                <Typography component="label" color="primary" htmlFor="rememberMe" className={classes.labelText}>
                                    {t("prejoin.remember_my_name")}
                                </Typography>
                                <Field
                                    variant="blue"
                                    size="small"
                                    name="rememberMe"
                                    as={Checkbox}
                                    checked={values["rememberMe"]}
                                    key="rememberMe-input"
                                    className={classes.formField}
                                    onChange={handleToggleRememberMe}
                                    disabled={isSubmitting || !isGuest}
                                />
                                <ErrorMessage
                                    name="rememberMe"
                                    render={(err) => (
                                        <Typography variant="sm" className={classes.errorText}>
                                            {err}
                                        </Typography>
                                    )}
                                />
                            </div>
                        )}

                        <div className="fullw flex flex-col">
                            {requestPermissionToJoin && !isPermissionDenied ? (
                                <div className={classes.permissionDisclaimer}>
                                    <ErrorOutlineRoundedIcon />
                                    <Typography color="quaternary">{t("prejoin.private_session_description")}</Typography>
                                </div>
                            ) : null}
                            {props.showNetworkIssueMsg && (
                                <div className={classes.unexpectedLeaveMsg}>
                                    <ErrorOutlineRoundedIcon />
                                    <Typography color="quaternary">{t("prejoin.disconnected_network_conditions")}</Typography>
                                </div>
                            )}

                            <PreJoinSubmitButton
                                isSubmitting={isSubmitting}
                                requestPermissionToJoin={requestPermissionToJoin}
                                isPermissionDenied={isPermissionDenied}
                                showNetworkIssueMsg={props.showNetworkIssueMsg || false}
                                onSubmit={() => handleSubmit(values, isValid)}
                                className={classes.btn}
                                disabled={
                                    !devicesReady ||
                                    values["name"].trim().length === 0 ||
                                    (askForGuestEmail && values["email"].trim().length === 0) ||
                                    (isProvideSessionPasscode && values["passcode"].trim().length === 0)
                                }
                                loading={devicesLoading}
                                isOwnerOnboarding={isOwnerOnboarding}
                            />
                        </div>
                        {!isOwnerOnboarding && (
                            <Typography variant="sm" color="quaternary" className={classes.disclaimer}>
                                {t("prejoin.by_clicking_join_you_agree")} <br />
                                <HyperlinkExternal
                                    href={getHelpCenterArticleLink("knowledge-base/terms-privacy/terms-and-conditions")}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("g.terms_and_conditions")}
                                </HyperlinkExternal>{" "}
                                {t("g.and")}{" "}
                                <HyperlinkExternal
                                    href={getHelpCenterArticleLink("knowledge-base/terms-privacy/privacy-policy")}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t("g.privacy_policy")}
                                </HyperlinkExternal>
                                .
                            </Typography>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PreJoinInfoForm;

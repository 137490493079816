import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@ui/cdk/Button";
import Menu from "@ui/cdk/Menu";
import {cls} from "@ui/cdk/util/util";
import Fade from "@ui/core/components/Fade";
import React, {useEffect} from "@workhorse/api/rendering";
import ReactionsToggler from "@workhorse/pages/player/components/conferenceControls/Reactions/ReactionsToggler";
import {useDeviceOrientation} from "@workhorse/providers/DeviceOrientationProvider";
import {useMobile} from "@workhorse/providers/MobileProvider";
import menuItemClasses from "../../../components/artifact-actions-button/styles/ArtifactActionsButton.module.scss";
import menuClasses from "../../../pages/player/components/LocalParticipantControls/styles/LocalParticipantControls.module.scss";
import AgendaTogglerOption from "./AgendaTogglerOption";
import classes from "./styles/DrawerRightActionMenu.module.scss";
import {useAgendaItems, useMacroArtifacts, useSession} from "@workhorse/providers/SessionDataProviders";
import AddToolsInPlayerButton from "@workhorse/pages/player/components/PlayerTools/AddToolsInPlayerButton";
import {useUserInfo} from "@workhorse/providers/User";
import {OnboardingShareButton} from "@workhorse/components/onboarding-tooltips";
import {useHostType} from "@workhorse/providers/HostTypeProvider";
import {HostType} from "@generated/data";
import {useTranslation} from "react-i18next";
import {RecordingToggler} from "@workhorse/pages/player/components/conferenceControls/Recording";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";
import {SessionLivestreamOutputUrlListJsonType} from "@sessions/common/recording";
import useFeatureInfo from "@workhorse/components/subscriptions/useFeatureGuards";
import {LimitedFeature} from "@sessions/common/subscription-limitations/features";

type DrawerRightActionMenuProps = {
    anchorEl: HTMLButtonElement | HTMLLIElement | null;
    onToggleMenu: (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => void;
    onClose?: () => void;
    children?: React.ReactNode[];
    isLobby: boolean;
};

function DrawerRightActionMenu(props: DrawerRightActionMenuProps) {
    const {t} = useTranslation();
    const {anchorEl, onToggleMenu, onClose, children, isLobby} = props;

    const myParticipant = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            id: currentParticipant?.id,
            isOwner: currentParticipant?.isOwner,
            isAssistant: amIAssistant,
        }))
    );
    const macroArtifacts = useMacroArtifacts();
    const agendaItems = useAgendaItems();
    const session = useSession();
    const hostType = useHostType();

    const isMemory = hostType === HostType.Memory;

    const {isGuest} = useUserInfo();

    const isOwner = myParticipant?.isOwner ?? false;
    const isAssistant = myParticipant?.isAssistant ?? false;

    const hasMicroController = isOwner || isAssistant;

    const {featureIsAvailable} = useFeatureInfo({
        feature: LimitedFeature.LIVESTREAM,
    });

    const livestreamUrls = (session.livestreamOutputUrls as SessionLivestreamOutputUrlListJsonType)?.urls;
    const livestreamDisabledError = !featureIsAvailable
        ? t("participant.livestreaming.not_available_for_plan")
        : livestreamUrls?.length === 0 || !livestreamUrls
        ? t("participant.livestreaming.no_urls_configured")
        : null;

    const {
        isMobile,
        ua: {isMobileUA},
        isMobileOrTablet,
    } = useMobile();
    const {isPortrait} = useDeviceOrientation();

    useEffect(() => {
        if (!isPortrait && isMobile) {
            onClose?.();
        }
    }, [isPortrait, isMobile, onClose]);

    const sortedChildren = React.Children.toArray(children).sort((a, b) => {
        //sort these so the dom order is the same as the one in the UI
        //screen readers use the dom order to read the menu items

        const aOrder = (a as any)?.props?.order ?? 0;
        const bOrder = (b as any)?.props?.order ?? 0;

        return bOrder - aOrder;
    });

    return (
        <>
            <OnboardingShareButton>
                <Button
                    className={cls(menuClasses.controlButton, classes.toggler)}
                    variant="plain"
                    onClick={onToggleMenu}
                    size="smallest"
                    aria-label={t("player.footer.aria.tools") ?? "Tools"}
                    aria-pressed={Boolean(anchorEl)}
                >
                    {anchorEl ? <CloseRoundedIcon /> : <BusinessCenterRoundedIcon />}
                </Button>
            </OnboardingShareButton>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onToggleMenu}
                keepMounted
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                classes={{
                    paper: classes.paper,
                    list: classes.list,
                }}
                BackdropProps={{
                    className: classes.backdrop,
                }}
                MenuListProps={{
                    id: "drawer-right-menulist",
                }}
                TransitionComponent={Fade}
                aria-label={t("player.footer.aria.tool_menu") ?? "Tool menu"}
                aria-hidden={!anchorEl}
            >
                {sortedChildren}
                {isMobileUA && !isLobby && !isMemory && (
                    <ReactionsToggler
                        className={cls(menuItemClasses.iconButtonMobileOrTablet, classes.menuItem, classes.orderN1)}
                        onClick={onClose}
                    />
                )}

                {/* 08.04.24 disabled recording option on mobile */}
                {/* {(isOwner || isAssistant) && isMobileUA && !isLobby && !isMemory && !session.childOfBreakoutRooms?.id && (
                    <RecordingToggler
                        className={cls(menuItemClasses.iconButtonMobileOrTablet, classes.menuItem, classes.orderN1)}
                        sessionId={session.id}
                        sessionName={session.name}
                        participantId={myParticipant.id ?? ""}
                        backstage={session.backstage}
                        onClick={onClose}
                        livestreamDisabledError={livestreamDisabledError}
                        isMobile={isMobileOrTablet}
                    />
                )} */}

                {isMobile && !isMemory && (
                    <AgendaTogglerOption
                        onClick={onClose}
                        className={cls(menuItemClasses.iconButtonMobileOrTablet, classes.menuItem, classes.orderN2, classes.menuItemAgenda)}
                    />
                )}
                {(isOwner || (isAssistant && !isGuest)) && !isMemory && (
                    <AddToolsInPlayerButton
                        hasMicroController={hasMicroController}
                        isAdmin={isOwner}
                        isAssistant={isAssistant}
                        onClick={onClose}
                        className={cls(menuItemClasses.iconButtonMobileOrTablet, classes.menuItem, classes.orderN3, classes.menuItemAgenda)}
                        session={session}
                    />
                )}
            </Menu>
        </>
    );
}

export default DrawerRightActionMenu;

import clientEvents from "@api/events/client";
import simpleArtifactsMap from "@generated/artifacts/simple-map";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import Badge from "@ui/core/components/Badge";
import IconButton from "@ui/core/components/IconButton";
import designer from "@workhorse/api/designer";
import React, {forwardRef, useState, useMemo, useEffect} from "@workhorse/api/rendering";
import {useMobile} from "@workhorse/providers/MobileProvider";
import {capitalize} from "@workhorse/util";
import {ReactComponent as AdjustmentsIcon} from "../../assets/media/adjustments.svg";
import {ReactComponent as Trash} from "../../assets/media/trash.svg";
import ConfirmationDialog from "../ConfirmationDialog";
import classes from "./styles/ArtifactActionsButton.module.scss";
import {useTranslation} from "react-i18next";
import {useMyParticipant, useSession} from "@workhorse/providers/SessionDataProviders";
import {ParticipantsMacroAccess, PrivateChatAccess} from "@generated/data";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

export const artifactOrder: {[key: string]: number} = {
    participants: 0,
    "flowos/chat": 1,
    "flowos/polls": 2,
    "flowos/qa": 3,
    "flowos/contextual-notes": 4,
    "flowos/takeaways": 5,
    "flowos/transcript": 6,
    offers: 7,
    timer: 8,
};

const useArtifactTogglerOrder = (artifactId?: string): {order: number; position: "first" | "last" | "none"} => {
    const {hiddenMacros, participantsMacroAccess, groupChatAccess, privateChatAccess} = useSession();

    const {isAssistant, isOwner, isSpeaker} = useParticipantsStore(
        useShallow((state) => ({
            isAssistant: state.amIAssistant,
            isOwner: state.currentParticipant?.isOwner,
            isSpeaker: !!state.currentParticipant?.speakerDetails,
        }))
    );

    const isOrganizer = isOwner || isAssistant;

    const isParticipantsMacroHidden = participantsMacroAccess === ParticipantsMacroAccess.Disabled && !isSpeaker && !isOrganizer;

    const isGroupChatHidden = !groupChatAccess;
    const isPrivateChatHidden = privateChatAccess === PrivateChatAccess.Disabled;

    const isChatHidden = isGroupChatHidden && isPrivateChatHidden;

    const orders = Object.fromEntries(
        Object.entries(artifactOrder).filter(([k, v]) =>
            (k === "participants" && !isParticipantsMacroHidden) ||
            (k === "flowos/chat" && !isChatHidden) ||
            (k === "offers" && isOwner) ||
            (k === "timer" && isOrganizer)
                ? true
                : !hiddenMacros?.includes(k)
        )
    );
    const orderValues = Object.values(orders);

    const order = !artifactId ? 999 : orders[artifactId];

    const position = !artifactId
        ? "none"
        : orderValues[0] === orders[artifactId]
        ? "first"
        : orderValues[orderValues.length - 1] === orders[artifactId]
        ? "last"
        : "none";

    return useMemo(
        () => ({
            order,
            position,
        }),
        [order, position]
    );
};

type ArtifactActionsButtonProps = {
    badgeContent: number;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isActive: boolean;
    activeIcon?: string;
    inactiveIcon?: string;
    MacroIcon?: React.ReactNode;
    placement?: "onBottom" | "onRight";
    tooltipPlacement?: "left";
    buttonDataId?: string;
    disabled?: boolean;
    designerStyle?: boolean;
    artifactId?: string;
    id?: string;
    controllerToggler?: boolean;
    isLobby: boolean;
    inDrawer: boolean;
    className?: string;
    label?: string;
    ariaLabel?: string;
    noLabel?: boolean;
};

const ArtifactActionsButton = forwardRef((props: ArtifactActionsButtonProps, ref: React.MutableRefObject<unknown>) => {
    const {t} = useTranslation();
    const {
        badgeContent,
        onClick,
        isActive,
        placement,
        disabled,
        activeIcon,
        inactiveIcon,
        MacroIcon,
        designerStyle,
        artifactId,
        controllerToggler,
        id,
        isLobby,
        inDrawer,
        className,
        label,
        ariaLabel,
        noLabel,
    } = props;

    const {order, position} = useArtifactTogglerOrder(artifactId);

    const {isMobile, isMobileOrTablet} = useMobile();

    const deleteArtifact = () => {
        if (id) {
            designer.api.artifact.removeMacroArtifact({id});
        }
    };

    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const confirmDelete = () => {
        setDeleteConfirmation(true);
    };
    const cancelDelete = () => {
        setDeleteConfirmation(false);
    };

    // always add an empty string as a fallback to the tooltip title
    // to prevent dom errors
    // TODO: refactor this component to remove the outer tooltip
    // for now there are 2 tooltips one over the other... not cool
    const initialArtifactName = useMemo(() => {
        const id = artifactId?.replace("flowos/", "");

        return id !== "qa"
            ? (artifactId ? t(`macro.${id}.title`) : "") ??
                  simpleArtifactsMap[artifactId || ""]?.displayName ??
                  capitalize(artifactId ?? "")
            : "Q&A";
    }, [artifactId, t]);

    const style = isMobile
        ? {
              order,
          }
        : {
              order,
              marginTop: placement === "onBottom" ? "auto" : "unset",
              borderWidth: placement === "onBottom" ? "0" : "0 0 1px 0",
              marginLeft: placement === "onRight" ? "auto" : "unset",
          };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(e);

        clientEvents.emit("macro-clicked", artifactId);
    };

    useEffect(() => {
        clientEvents.emit("macro-loaded", {id: artifactId});
    }, []);

    return (
        <Badge
            data-id="macro"
            color="secondary"
            badgeContent={isMobile ? undefined : badgeContent}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            showZero={false}
            className={cls(
                "flex flex-center-all",
                classes.root,
                isMobileOrTablet && classes.rootMobileOrTablet,
                isLobby && "in-lobby-btn",
                designerStyle && classes.designerStyleRoot,
                designerStyle && !disabled && classes.designerStyleUsableRoot,
                inDrawer && isMobileOrTablet && classes.inDrawerMobileOrTablet,
                !controllerToggler && isActive && classes.rootActive,
                position === "last" && "last-order",
                position === "first" && "first-order"
            )}
            style={style}
        >
            <div className="active-bg fullw" key="badge-content">
                <IconButton
                    disabled={disabled}
                    color="primary"
                    onClick={handleClick}
                    className={cls(
                        "with-badge flex flex-center-all",
                        classes.iconButton,
                        isMobileOrTablet && classes.iconButtonMobileOrTablet,
                        !controllerToggler ? (isActive ? classes.active : classes.inactive) : classes.ar2R,
                        className
                    )}
                    data-id={props.buttonDataId}
                    data-artifact-id={artifactId}
                    ref={ref}
                    aria-label={`${props.ariaLabel ?? initialArtifactName}`}
                    aria-pressed={isActive}
                >
                    {isMobileOrTablet && initialArtifactName && (
                        <Typography color="nonary" fontWeight="bold" className="mr-4">
                            {initialArtifactName}
                        </Typography>
                    )}

                    {!controllerToggler ? (
                        MacroIcon ? (
                            <>{MacroIcon}</>
                        ) : (
                            <>
                                <img className={cls(classes.activeIcon, classes.icon)} src={activeIcon} alt="chat icon" />
                                <img className={cls(classes.inactiveIcon, classes.icon)} src={inactiveIcon} alt="chat icon" />
                            </>
                        )
                    ) : (
                        <AdjustmentsIcon className={classes.microControllerIcon} />
                    )}

                    {(label || initialArtifactName) && !noLabel && !isMobileOrTablet && (
                        <Typography className={classes.buttonLabel} noWrap color="octonary" fontWeight="bold">
                            {label || initialArtifactName}
                        </Typography>
                    )}
                </IconButton>
            </div>

            {designerStyle && !disabled ? (
                <>
                    <IconButton className={cls(classes.deleteButton)} onClick={confirmDelete}>
                        <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>

                    <ConfirmationDialog
                        key="template-override-confirmation"
                        title="Remove this interaction block?"
                        content="Your session will be adjusted accordingly."
                        cancelButton="Cancel"
                        minWidth
                        Icon={Trash}
                        submitButton="Delete"
                        onClose={cancelDelete}
                        open={deleteConfirmation}
                        submitButtonVariant="destructive-secondary"
                        onConfirm={deleteArtifact}
                        variant="warning"
                    ></ConfirmationDialog>
                </>
            ) : null}
        </Badge>
    );
});

export default ArtifactActionsButton;

import Tooltip from "@ui/cdk/Tooltip";
import Typography from "@ui/cdk/Typography";
import {cls} from "@ui/cdk/util/util";
import {ReactNode, useCallback, useEffect, useRef, useState} from "@workhorse/api/rendering";
import classes from "./style/MacroHeader.module.scss";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {drawerRightActiveComponent, useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import {ArtifactTag} from "@generated/artifacts";
import IconButton from "@ui/core/components/IconButton";
import OnboardingTooltip from "../OnboardingTooltip";
import {getHelpCenterArticleLink, getResourcesBaseLink} from "@workhorse/api/help/article";
import {useSession} from "@workhorse/providers/SessionDataProviders";
import {useMutation} from "@workhorse/dataApi";
import {useReactiveVar} from "@workhorse/api/data";
import MacroStateMenu from "./MacroStateMenu";
import {useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useTranslation} from "react-i18next";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {ParticipantsMacroAccess} from "@generated/data";
import {useParticipantsStore} from "@workhorse/api/conference2/providers/ParticipantsProvider/LocalParticipantsStore";
import {useShallow} from "zustand/react/shallow";

interface MacroHeaderProps {
    floating?: boolean;
    marginLess?: boolean;
    paddingLess?: boolean;
    title: string;
    subtitle?: string | JSX.Element;
    playerRadius?: boolean;
    noBackground?: boolean;
    artifactId: string;
    onToggle?: () => void;
    onboardingInfo?: string;
    onboardingLink?: string;
    className?: string;
    isMemoryMode?: boolean;
    extraIcons?: JSX.Element;
    children?: ReactNode;
    isMobile?: boolean;
    tag?: string | JSX.Element;
    iconComponent?: JSX.Element;
    content?: JSX.Element;
    withBackButton?: boolean;
    onBack?: () => void;
    classes?: {
        title?: string;
    };
    hideMacroStateMenu?: boolean;
}

const maxTextWidth = 280;

const MacroHeader = (props: MacroHeaderProps) => {
    const {t} = useTranslation();
    const {
        playerRadius,
        floating,
        marginLess,
        paddingLess,
        subtitle,
        noBackground,
        onboardingInfo,
        onboardingLink,
        className,
        isMemoryMode,
        isMobile,
        tag = null,
        iconComponent,
        content,
        withBackButton,
        onBack,
        hideMacroStateMenu,
    } = props;

    const titleRef = useRef<HTMLDivElement | null>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const {isActive, setActiveComponent} = useDrawerRightActiveComponent(props.artifactId as ArtifactTag);
    const [rightDrawerState, toggleRightDrawer, toggleDetach] = useDrawerRightToggler();
    const closeDrawerTimeout = useRef<NodeJS.Timeout | null>(null);
    const session = useSession();
    const activeComponent = useReactiveVar(drawerRightActiveComponent);

    const {id, isOwner, isSpeaker, isAssistant} = useParticipantsStore(
        useShallow(({currentParticipant, amIAssistant}) => ({
            id: currentParticipant?.id ?? "",
            isOwner: currentParticipant?.isOwner ?? false,
            isSpeaker: !!currentParticipant?.speakerDetails?.id,
            isAssistant: amIAssistant,
        }))
    );
    const isOrganizer = isOwner || isAssistant;

    const {isLobby: inLobby} = useIsLobby();

    const [commit] = useMutation("PushDrawerStateDocument");

    const sessionId = session.id;

    const onClick = () => {
        toggleRightDrawer();

        closeDrawerTimeout.current = setTimeout(() => {
            setActiveComponent(null);
        }, 500);
    };

    const isParticipantsMacroHidden = session.participantsMacroAccess === ParticipantsMacroAccess.Disabled && !isSpeaker && !isOrganizer;

    const shouldHideSync = isParticipantsMacroHidden && activeComponent === "participants";

    const syncState = useCallback(() => {
        return commit({
            variables: {
                sessionId: session.id,
                rightDrawer: {state: rightDrawerState.state, isDetached: rightDrawerState.isDetached, activeComponent},
            },
        });
    }, [sessionId, rightDrawerState, activeComponent]);

    useEffect(() => {
        if (titleRef && titleRef.current) {
            const div = titleRef.current;
            const currentTextWidth = Math.ceil(div.getBoundingClientRect().width);

            if (currentTextWidth >= maxTextWidth) {
                setShowTooltip(true);
            }
        }
    }, []);

    return (
        <div
            className={cls(
                "flex",
                "flex-col",
                classes.macroHeader,
                rightDrawerState.isDetached && classes.detached,
                floating && classes.floating,
                !marginLess && classes.withMargins,
                !paddingLess && classes.withPadding,
                playerRadius && classes.playerRadius,
                !noBackground && classes.withBackground,
                subtitle && classes.withSubtitle,
                className
            )}
        >
            <Typography
                component="div"
                variant="xl3"
                className={cls("flex fullw flex-items-center", isMemoryMode ? "" : "flex-justify-between")}
            >
                {iconComponent}
                {content ?? (
                    <div className={cls("flex flex-col", classes.titleRoot)}>
                        <div className="flex flex-items-center">
                            {withBackButton && (
                                <IconButton onClick={onBack} className={classes.backArrowButton}>
                                    <ArrowBackIosRoundedIcon />
                                </IconButton>
                            )}
                            <Tooltip title={showTooltip ? props.title : ""}>
                                <div ref={titleRef}>
                                    <Typography noWrap className={cls(classes.title, props.classes?.title)} variant="lg" component="div">
                                        {props.title}
                                    </Typography>
                                </div>
                            </Tooltip>

                            {onboardingInfo && onboardingLink && (
                                <OnboardingTooltip
                                    title={onboardingInfo}
                                    articleLink={
                                        props.title.toLowerCase() === "transcript"
                                            ? getResourcesBaseLink(onboardingLink)
                                            : getHelpCenterArticleLink(onboardingLink)
                                    }
                                    className="ml-4"
                                    placement="bottom-start"
                                    offset="-14px, 0px"
                                    key="macro-header-onboarding-tooltip"
                                />
                            )}

                            {tag}
                        </div>

                        {props.subtitle ? (
                            <Typography noWrap className={classes.subtitle} variant="sm" component="div">
                                {props.subtitle}
                            </Typography>
                        ) : null}
                    </div>
                )}

                {props.extraIcons}

                {!isMemoryMode && !isMobile ? (
                    <div className={cls(classes.actions, props.extraIcons ? "ml-8" : "ml-auto", "rnd-btn")}>
                        {!hideMacroStateMenu && (
                            <MacroStateMenu
                                isOrganizer={isOrganizer}
                                tooltipPlacement="bottom-end"
                                isDetached={rightDrawerState.isDetached}
                                extraIcons={!!props.extraIcons}
                                onDetach={toggleDetach}
                                onSync={syncState}
                                isLobby={inLobby}
                                name={props.title}
                                shouldHideSync={shouldHideSync}
                            />
                        )}
                        <IconButton
                            className={cls(classes.button)}
                            onClick={props.onToggle ?? onClick}
                            aria-label={t("macro.g.close_panel") ?? "Close panel"}
                        >
                            <CloseRoundedIcon className={classes.icon}></CloseRoundedIcon>
                        </IconButton>
                    </div>
                ) : null}
            </Typography>
            {props.children}
        </div>
    );
};

export default MacroHeader;

import {
    SaveContextualNoteDocument,
    SendContextualNoteDocument,
    SetNotionDefaultParentPageDocument,
    UpdateOneGuestDocument,
} from "@generated/data";
import apollo from "@workhorse/api/apollo";
import React, {forwardRef, useRef} from "@workhorse/api/rendering";
import toast from "@workhorse/api/toast";
import {readRemoteUser, updateCachedRemoteUser, updateRemoteUser} from "@workhorse/api/user";
import ArtifactActionsButton from "@workhorse/components/artifact-actions-button";
import {writeQuery} from "@workhorse/dataApi";
import {MacroArtifactProps} from "@workhorse/declarations";
import {useDrawerRightActiveComponent} from "@workhorse/providers/inject";
import {readCurrentParticipant, useIsLobby} from "@workhorse/providers/SessionDataProviders";
import {useDrawerRightToggler} from "@workhorse/providers/state";
import {ReactComponent as TogglerNotes} from "../../../frontend/assets/media/macro-toggler-notes.svg";

export type SendNotesStateType = {
    email: boolean;
    evernote: boolean;
    notion: boolean;
};

export type ShareNotesStateType = {
    email: boolean;
    evernote: boolean;
    notion: boolean;
};

type ContextualNotesTogglerProps = MacroArtifactProps & {
    inDrawer: boolean;
    isMemoryMode: boolean;
};

const saveContextualNote = async (noteValue, sessionId: string) => {
    const note = {...noteValue};

    return await apollo.client
        .mutate({
            mutation: SaveContextualNoteDocument,
            variables: {
                noteJson: note,
                session: {
                    id: sessionId,
                },
            },
            fetchPolicy: "no-cache",
        })
        .then(() => {
            const data = readCurrentParticipant({sessionId});

            if (!data?.id) {
                return;
            }

            writeQuery("LocalCurrentParticipantDocument", {
                variables: {
                    id: data.id,
                },
                data: {
                    __typename: "Query",
                    currentParticipant: {
                        ...data,
                        contextualNoteJson: note,
                    },
                },
            });
        })
        .catch((error) => {
            toast("Contextual note cannot be saved", {
                type: "error",
            });
            console.error(error);
        });
};

const ContextualNotesToggler = forwardRef((props: ContextualNotesTogglerProps, ref: React.MutableRefObject<unknown>) => {
    const {isLobby} = useIsLobby();
    const {artifact, mobileState, inDrawer, isMemoryMode} = props;
    const {isMobile} = mobileState;

    const [rightDrawerState, toggleRightDrawer] = useDrawerRightToggler();
    const {isActive, setActiveComponent} = useDrawerRightActiveComponent(artifact.artifactId);
    const closeDrawerTimeout = useRef<NodeJS.Timeout | null>(null);

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (closeDrawerTimeout.current) {
            clearTimeout(closeDrawerTimeout.current);
        }
        if (!rightDrawerState.isFullyOpen || !isActive) {
            setActiveComponent(artifact.artifactId);
        } else if (isActive && !isMemoryMode) {
            setActiveComponent(null);
        }
        const toggleDrawerCond =
            ((!isActive || isMobile) && !rightDrawerState.isFullyOpen) || (isActive && rightDrawerState.isFullyOpen && !isMemoryMode);

        if (toggleDrawerCond) {
            toggleRightDrawer();
        }
    };

    return (
        <ArtifactActionsButton
            badgeContent={0}
            onClick={onClick}
            isActive={isActive}
            MacroIcon={<TogglerNotes />}
            buttonDataId="player-button-notes"
            artifactId={artifact.artifactId}
            isLobby={isLobby}
            inDrawer={inDrawer}
            ref={ref}
        />
    );
});

const selectNotionDefault = (parentPageId: string) => {
    return apollo.client.mutate({
        mutation: SetNotionDefaultParentPageDocument,
        variables: {
            parentPageId,
        },
    });
};

const sendContextualNote = (sessionId: string, sendEmail?: boolean, sendToEvernote?: boolean, sendToNotion?: boolean) => {
    return apollo.client.mutate({
        mutation: SendContextualNoteDocument,
        variables: {
            sessionId,
            sendEmail,
            sendToEvernote,
            sendToNotion,
        },
    });
};

const changeSendNotes = (isGuest: boolean, target: keyof SendNotesStateType, checked: boolean) => {
    const remoteUserData = readRemoteUser();

    if (!remoteUserData?.getRemoteUser?.user?.id) {
        return;
    }

    const shouldSendEmail = target === "email" ? checked : remoteUserData.getRemoteUser.user.notesFlags?.["shouldSendEmail"] ?? false;
    const shouldSendToEvernote =
        target === "evernote" ? checked : remoteUserData.getRemoteUser.user.notesFlags?.["shouldSendToEvernote"] ?? false;
    const shouldSendToNotion =
        target === "notion" ? checked : remoteUserData.getRemoteUser.user.notesFlags?.["shouldSendToNotion"] ?? false;

    return isGuest
        ? apollo.client
              .mutate({
                  mutation: UpdateOneGuestDocument,
                  variables: {
                      data: {
                          notesFlags: {
                              shouldSendEmail,
                              shouldSendToEvernote,
                              shouldSendToNotion,
                          },
                      },
                  },
              })
              .then((res) => {
                  if (res.data?.updateOneGuest) {
                      if (!remoteUserData?.getRemoteUser?.user?.id) {
                          return;
                      }

                      updateCachedRemoteUser({
                          user: {
                              notesFlags: {
                                  shouldSendEmail,
                                  shouldSendToEvernote,
                                  shouldSendToNotion,
                              },
                          },
                      });
                  }
              })
              .catch(() => {
                  toast("Something went wrong with checking the option.", {type: "error"});
              })
        : updateRemoteUser({
              notesFlags: {
                  shouldSendEmail,
                  shouldSendToEvernote,
                  shouldSendToNotion,
              },
          })
              .then((res) => {
                  console.log("success updateOneUser");
              })
              .catch(() => {
                  toast("Something went wrong with checking the option.", {type: "error"});
              });
};

export default {
    ContextualNotesToggler,
    saveContextualNote,
    selectNotionDefault,
    sendContextualNote,
    changeSendNotes,
};

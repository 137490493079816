import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import PickerIcon from "@material-ui/icons/Colorize";
import ColorPickerIcon from "@material-ui/icons/FormatColorFill";
import PaletteIcon from "@material-ui/icons/Palette";
import OutlinedInput from "@ui/cdk/OutlinedInput";
import Tabs, {Tab} from "@ui/cdk/Tabs";
import Typography from "@ui/cdk/Typography";
import Popover from "@ui/core/components/Popover";
import {useEffect, useState} from "@workhorse/api/rendering";
import {ChromePicker, ColorResult, RGBColor} from "react-color";
import {useEyeDrop} from "react-eyedrop";
import {extractRGBObject, getRGBColor, hexToRgb, rgbToHex, compareColors, rgbTohsl, hslTorgb} from "../../utils";
import styles from "./style/colorPicker.module.scss";
import {cls, capitalize} from "@ui/cdk/util/util";
import Button from "@ui/cdk/Button";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={0} mt={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

type ColorPickerProps = {
    color: RGBColor;
    colorName: string;
    onChangeColor: (color: RGBColor, colorName: string) => void;
    logo: string;
    isBranding?: boolean;
    hasConfirmButton?: boolean;
    readOnly?: boolean;
    limitBrightness?: boolean;
    predefinedColors?: RGBColor[];
    classes?: {
        fakeSelect?: string;
        paper?: string;
    };
};

const getColorDiff = (color: RGBColor, newColor: RGBColor) => {
    for (let key of Object.keys(color)) {
        if (color[key] !== newColor[key]) {
            return true;
        }
    }
    return false;
};

const premadeColors: RGBColor[] = [
    {r: 239, g: 68, b: 68, a: 1},
    {r: 249, g: 115, b: 22, a: 1},
    {r: 250, g: 204, b: 21, a: 1},
    {r: 74, g: 222, b: 128, a: 1},
    {r: 45, g: 212, b: 191, a: 1},
    {r: 31, g: 138, b: 244, a: 1},
    {r: 99, g: 102, b: 241, a: 1},
];

const ColorPicker = (props: ColorPickerProps) => {
    const {color, colorName, onChangeColor, logo, isBranding, hasConfirmButton, readOnly, limitBrightness, predefinedColors} = props;
    const [colors, pickColor, cancelPickColor] = useEyeDrop({
        once: false,
        pickRadius: 1,
        cursorActive: "auto",
    });
    const [value, setValue] = useState(0);
    const [rgb, setRGB] = useState({
        r: Math.round(color.r),
        g: Math.round(color.g),
        b: Math.round(color.b),
    });
    const [hex, setHEX] = useState(rgbToHex(color));

    const hsl = rgbTohsl(rgb);

    useEffect(() => {
        if (value && colors && colors.rgb) {
            const rgbColor = extractRGBObject(colors.rgb);
            const roundedRgbColor = {
                r: Math.round(rgbColor.r),
                g: Math.round(rgbColor.g),
                b: Math.round(rgbColor.b),
            };

            const hexColor = rgbToHex(roundedRgbColor);

            setRGB(roundedRgbColor);
            setHEX(hexColor.toUpperCase());
        }
    }, [colors, value]);

    const ColorChangeHandler = (color: ColorResult) => {
        const roundedRgbColor = {
            r: Math.round(color.rgb.r),
            g: Math.round(color.rgb.g),
            b: Math.round(color.rgb.b),
        };

        const hexColor = rgbToHex(roundedRgbColor);

        setRGB(roundedRgbColor);
        setHEX(hexColor.toUpperCase());
    };

    const isCurrentColorPremade = () => {
        const defaultColors = predefinedColors || premadeColors;

        for (const premadeColor of defaultColors) {
            if (compareColors(rgb, premadeColor) || compareColors(color, premadeColor)) {
                return true;
            }
        }
        return false;
    };

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        cancelPickColor();
        setValue(0);

        const timeout = setTimeout(() => {
            if (getColorDiff(rgb, color)) {
                const hsl = rgbTohsl(rgb);

                //if the color is too light, we make it a bit darker and a bit more saturated
                const rgbColor = limitBrightness && hsl.l > 180 ? hslTorgb({...hsl, s: Math.max(-1, hsl.s - 0.05), l: 180}) : rgb;

                const roundedRgbColor = {
                    r: Math.round(rgbColor.r),
                    g: Math.round(rgbColor.g),
                    b: Math.round(rgbColor.b),
                };

                const hexColor = rgbToHex(roundedRgbColor);

                setRGB(roundedRgbColor);
                setHEX(hexColor.toUpperCase());

                onChangeColor(roundedRgbColor, colorName);
            }
            clearTimeout(timeout);
        }, 400);
    };

    const open = Boolean(anchorEl);

    const handleClickTab = (event) => {
        event.stopPropagation();
    };

    const handleChange = (event: React.MouseEvent, newValue: number) => {
        event.stopPropagation();
        setValue(newValue);
        if (newValue === 1) {
            pickColor();
        } else {
            cancelPickColor();
        }
    };

    const updateHEX = (colorKey: string, colorValue: string) => {
        if (colorValue && colorValue.length) {
            const color = {...rgb};
            color[colorKey] = Math.round(Math.min(Math.max(0, +colorValue), 255));
            setRGB(color);
            setHEX(rgbToHex(color));
        }
    };

    const updateRGB = (color: string) => {
        if (color && color.indexOf("#") === 0) {
            setHEX(color);
            const rgbColor = hexToRgb(color);
            if (rgbColor) {
                setRGB(rgbColor);
            }
        }
    };

    return (
        <div className={styles.root}>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: -10,
                    horizontal: 0,
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                classes={{
                    paper: props.classes?.paper,
                }}
            >
                <AppBar position="static" className={styles.appBarTabsWrapper}>
                    <Tabs
                        classes={{
                            indicator: styles.indicator,
                        }}
                        value={value}
                        onChange={handleChange}
                        onClick={handleClickTab}
                        aria-label="simple tabs example"
                        className={styles.tabs}
                        variant="fullWidth"
                        indicatorColor="primary"
                    >
                        <Tab label="Color picker" icon={<PaletteIcon />} {...a11yProps(0)} className={styles.tab} />
                        <Tab label="Pick from logo" icon={<PickerIcon />} {...a11yProps(1)} className={styles.tab} />
                    </Tabs>
                    <TabPanel className={styles.tabContent} value={value} index={0}>
                        <ChromePicker
                            disableAlpha
                            className={styles.chromePicker}
                            color={rgb}
                            onChange={ColorChangeHandler}
                            onChangeComplete={ColorChangeHandler}
                            styles={{
                                default: {
                                    hue: {
                                        height: "16px",
                                    },
                                },
                                disableAlpha: {
                                    color: {
                                        width: "32px",
                                    },
                                    swatch: {
                                        width: "18px",
                                        height: "18px",
                                    },
                                },
                            }}
                        ></ChromePicker>

                        <div className="flex flex-align-center">
                            <div className={styles.colorInputWrapper}>
                                <Typography>Color</Typography>
                                <div className={styles.pickedColor} style={{backgroundColor: hex}}></div>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>Hex</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={hex}
                                    fullWidth
                                    styled
                                    margin="none"
                                    onChange={(e) => updateRGB(e.target.value)}
                                ></OutlinedInput>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>R</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={rgb.r}
                                    fullWidth
                                    styled
                                    margin="none"
                                    type="number"
                                    inputProps={{min: 0, max: 255}}
                                    onChange={(e) => updateHEX("r", e.target.value)}
                                ></OutlinedInput>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>G</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={rgb.g}
                                    fullWidth
                                    styled
                                    margin="none"
                                    type="number"
                                    inputProps={{min: 0, max: 255}}
                                    onChange={(e) => updateHEX("g", e.target.value)}
                                ></OutlinedInput>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>B</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={rgb.b}
                                    fullWidth
                                    styled
                                    margin="none"
                                    type="number"
                                    inputProps={{min: 0, max: 255}}
                                    onChange={(e) => updateHEX("b", e.target.value)}
                                ></OutlinedInput>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className={styles.tabContent} value={value} index={1}>
                        <div className={cls("flex flex-center-all", styles.imgMaxHeight)}>
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="flex flex-align-center mt-16" onClick={handleClickTab}>
                            <div className={styles.colorInputWrapper}>
                                <Typography>Color</Typography>
                                <div className={styles.pickedColor} style={{backgroundColor: hex}}></div>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>Hex</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={hex}
                                    fullWidth
                                    styled
                                    margin="none"
                                    onChange={(e) => updateRGB(e.target.value)}
                                ></OutlinedInput>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>R</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={rgb.r}
                                    fullWidth
                                    styled
                                    margin="none"
                                    type="number"
                                    inputProps={{min: 0, max: 255}}
                                    onChange={(e) => updateHEX("r", e.target.value)}
                                ></OutlinedInput>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>G</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={rgb.g}
                                    fullWidth
                                    styled
                                    margin="none"
                                    type="number"
                                    inputProps={{min: 0, max: 255}}
                                    onChange={(e) => updateHEX("g", e.target.value)}
                                ></OutlinedInput>
                            </div>
                            <div className={styles.colorInputWrapper}>
                                <Typography>B</Typography>
                                <OutlinedInput
                                    className={styles.colorInput}
                                    value={rgb.b}
                                    fullWidth
                                    styled
                                    margin="none"
                                    type="number"
                                    inputProps={{min: 0, max: 255}}
                                    onChange={(e) => updateHEX("b", e.target.value)}
                                ></OutlinedInput>
                            </div>
                        </div>
                    </TabPanel>
                    {hasConfirmButton && (
                        <Button onClick={handleClose} className={styles.submitButton}>
                            Done
                        </Button>
                    )}
                </AppBar>
            </Popover>
            {!isBranding ? (
                <div
                    className={cls(styles.fakeSelect, open && styles.openFakeSelect, props.classes?.fakeSelect)}
                    onClick={readOnly ? undefined : handleClick}
                >
                    <div className={styles.colorToggler} style={{backgroundColor: getRGBColor(color)}}></div>
                    <Typography>
                        {capitalize(colorName)} - {rgbToHex(color)}
                    </Typography>
                    <ColorPickerIcon className={cls(styles.icon, open && styles.openIcon)} />
                </div>
            ) : (
                <div
                    className={cls(
                        "flex flex-justify-center flex-items-center",
                        styles.brandingColorPickerIcon,
                        isCurrentColorPremade() ? "" : styles.active,
                        isCurrentColorPremade() ? "" : hsl.l > 180 && styles.dark
                    )}
                    style={{border: `2px solid ${!isCurrentColorPremade() ? getRGBColor(rgb) : "transparent"}`}}
                    onClick={handleClick}
                >
                    <div
                        className={cls(
                            "flex flex-justify-center flex-items-center",
                            isCurrentColorPremade() ? styles.innerCircle : styles.innerCircleActive,
                            isCurrentColorPremade() ? "" : hsl.l > 180 && styles.dark
                        )}
                        style={{
                            backgroundColor: !isCurrentColorPremade() ? getRGBColor(rgb) : "transparent",
                            borderColor: !isCurrentColorPremade() ? getRGBColor(rgb) : "#94a3b8",
                        }}
                    >
                        <PickerIcon />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
